import React, {useCallback, useMemo, useState} from "react";
import {Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {useDebouncedCallback} from "use-debounce";
import {TargetingTextField} from "../../../components/targetingFilter/inputs/TargetingTextField";
import {lang} from "../../../lang/lang";
import {Domain, useDomainsLazyQuery} from "../../../graphql/graphql.types";
import {SetupDomainOption} from "./SetupDomainOption";
import {plural} from "../../../lang/plural";

const handleOptionLabel = (domain: Domain) => domain.domain;

const useStyles = makeStyles({
    inputRoot: {
        backgroundColor: '#fff',
        paddingTop: '2px !important',
    },
});

type Props = {
    availableDomains: number;
    disabled: boolean;
    onChange: (domains: Domain[]) => void;
    subscribedDomains: string[];
    values: Domain[];
};

export const SetupDomainSearch = ({availableDomains, disabled, onChange, subscribedDomains, values}: Props) => {
    const classes = useStyles();
    const [getDomains, {data, loading}] = useDomainsLazyQuery({
        fetchPolicy: 'cache-first',
    });
    const options = useMemo(() => {
        return data?.domains.domains.filter(({domain}) => !subscribedDomains.includes(domain)) ?? [];
    }, [data, subscribedDomains]);
    const fetchDomains = useCallback(async (nameFilter: string) => {
        return getDomains({
            variables: {
                nameFilter,
                pagination: {page: 1, perPage: 50},
            },
        });
    }, []);
    const [debouncedFetchDomains] = useDebouncedCallback(fetchDomains, 1000, {leading: true});

    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const handleOpen = useCallback(() => {
        setOpen(true);
        debouncedFetchDomains('');
    }, [debouncedFetchDomains, setOpen]);

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = useCallback((e, nextInputValue) => {
        setInputValue(nextInputValue);
        debouncedFetchDomains(nextInputValue);
    }, [debouncedFetchDomains, setInputValue]);
    const handleChange = useCallback((event, values: Domain[]) => {
        onChange(values);
    }, [onChange]);

    const noOptionsText = useMemo(() => {
        if (loading) {
            return lang.dashboard.setup.loading;
        }

        return inputValue ? lang.dashboard.setup.notFound : lang.dashboard.setup.typeDomains;
    }, [inputValue, loading]);
    const handleOptionSelected = useCallback((option: Domain, value: Domain) => {
        return option.domain === value.domain;
    }, []);

    const hasError = availableDomains !== 0 && values.length > availableDomains;

    return (
        <Autocomplete<Domain, true, true, false>
            autoHighlight
            disabled={disabled}
            disableClearable
            disableCloseOnSelect
            disablePortal
            getOptionLabel={handleOptionLabel}
            getOptionSelected={handleOptionSelected}
            inputValue={inputValue}
            multiple
            noOptionsText={noOptionsText}
            onClose={handleClose}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            open={open}
            openOnFocus={false}
            options={options}
            popupIcon={null}
            renderInput={(params) => (
                <TargetingTextField
                    {...params}
                    autoFocus
                    error={hasError}
                    helperText={hasError ? plural(lang.dashboard.setup.domainsLimitReached, {domains: availableDomains}) : undefined}
                    InputProps={{
                        ...params.InputProps,
                        classes: {root: classes.inputRoot},
                    }}
                    loading={loading}
                    placeholder={lang.dashboard.setup.typeDomains}
                />
            )}
            renderOption={(option, {inputValue}) => (
                <SetupDomainOption inputValue={inputValue} option={option} />
            )}
            renderTags={(values, getTagProps) => values.map((value, index) => (
                <Chip key={index} {...getTagProps({index})} label={value.domain} size="small" />
            ))}
            value={values}
        />
    );
};
