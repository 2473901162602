import React, {PropsWithChildren} from 'react';
import {Box, Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {LocaleDropdown} from '../../lang/components/LocaleDropdown';
import TecplusLogo from '../../assets/img/tecplus-blue.png';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        boxSizing: 'border-box',
        display: 'flex',
        height: '100%',
    },
    content: {
        flexGrow: 1,
        padding: `${theme.spacing(5)}px ${theme.spacing(8)}px`,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    toolbarOffset: theme.mixins.toolbar,
}));

export const AuthLayout = ({children}: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <main className={classes.content}>
                <div className={classes.header}>
                    <img src={TecplusLogo} alt="Tecplus" />
                    <LocaleDropdown />
                </div>
                <Container maxWidth="xs">
                    <div className={classes.toolbarOffset} />
                    {children}
                </Container>
            </main>
        </Box>
    );
};
