import {createContext} from 'react';

type TechnologySubscriptionContextType = {
    loading: boolean;
    technologyUuids: string[];
    onReplace: (technologyUuids: string[]) => Promise<void>;
    onSubscribe: (technologyUuid: string) => Promise<void>;
    onUnsubscribe: (technologyUuid: string) => Promise<void>;
};

export const TechnologySubscriptionContext = createContext<TechnologySubscriptionContextType>({
    loading: false,
    technologyUuids: [],
    onReplace: async () => {},
    onSubscribe: async () => {},
    onUnsubscribe: async () => {},
});
