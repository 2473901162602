import React from 'react';
import {Checkbox, TableCell} from '@material-ui/core';

type Props = {
    checked: boolean;
    indeterminate?: boolean;
    onSelect: () => void;
};

export const TableCheckbox = ({checked, indeterminate = false, onSelect}: Props) => {
    return (
        <TableCell padding="checkbox">
            <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                onChange={onSelect}
            />
        </TableCell>
    );
};
