import React, {Suspense, memo} from 'react';
import {RouteProps, Switch, RouteComponentProps, useLocation, Route} from 'react-router-dom';
import {RouteConfig} from './RouteConfig';
import {AuthRoute} from './AuthRoute';
import {PublicRoute} from './PublicRoute';
import {RolesRoute} from './RolesRoute';
import {lang} from '../lang/lang';

type Props = RouteProps & {
    routeConfig: RouteConfig;
};

export const NestedRoute = memo(({routeConfig}: Props) => {
    const location = useLocation();

    const route = (
        <Route
            exact={routeConfig.exact}
            path={routeConfig.path}
            render={(props: RouteComponentProps) => {
                const Component = routeConfig.component;

                return (
                    <Suspense fallback={lang.layout.loading}>
                        <Component {...props}>
                            {routeConfig.routes ? (
                                <Switch location={location}>
                                    {routeConfig.routes?.map((nestedRouteConfig) => (
                                        <NestedRoute
                                            key={nestedRouteConfig.path}
                                            path={nestedRouteConfig.path}
                                            routeConfig={nestedRouteConfig}
                                        />
                                    ))}
                                </Switch>
                            ) : null}
                        </Component>
                    </Suspense>
                );
            }}
        />
    );

    if (routeConfig.public) {
        return (
            <PublicRoute
                currentPath={location.pathname}
                path={routeConfig.path}
            >
                {route}
            </PublicRoute>
        );
    }

    if (!routeConfig.roles) {
        return <AuthRoute>{route}</AuthRoute>;
    }

    return (
        <RolesRoute accessibleTo={routeConfig.roles}>
            <AuthRoute>{route}</AuthRoute>
        </RolesRoute>
    );
});
