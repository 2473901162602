import React from 'react';
import {IconButton, SvgIcon, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Paths} from '../../../router/paths';
import DomainsIcon from '../../../assets/icons/domains.svg';
import OpenInNewTabIcon from '../../../assets/icons/open-in-new-tab.svg';
import {Link} from '../../../components/navigation/Link';
import {lang} from '../../../lang/lang';

const useStyles = makeStyles((theme) => ({
    favIcon: {
        marginRight: theme.spacing(1),
        verticalAlign: 'middle',
        '& svg': {
            fill: theme.palette.primary.light,
        },
    },
    openInNewTab: {
        marginLeft: theme.spacing(0.5),
        '& svg': {
            fill: theme.palette.primary.main,
            opacity: 0.7,
        },
        '&:hover svg': {
            fill: theme.palette.secondary.dark,
            opacity: 1,
        },
    },
}));

export const DomainRenderer = ({item: {domain}}: {item: {domain: string}}) => {
    const classes = useStyles();

    return (
        <>
            <SvgIcon className={classes.favIcon} fontSize="small">
                <DomainsIcon />
            </SvgIcon>
            <Link to={[Paths.DOMAIN_TECHNOLOGIES, {domain}]}>
                {domain}
            </Link>
            <Tooltip
                arrow
                className={classes.openInNewTab}
                title={lang.domain.link.openInNewTab}
                placement='bottom-end'
            >
                <IconButton component="a" target="_blank" href={`https://${domain}`} size="small">
                    <OpenInNewTabIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};
