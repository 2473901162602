import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {SnackbarProvider as NotistackSnackbarProvider} from 'notistack';

const useStyles = makeStyles((theme) => ({
    snackbar: {
        boxShadow: 'none !important',
        color: `${theme.palette.text.primary} !important`,
        fontSize: '1rem !important',
    },
    variantError: {
        backgroundColor: `${theme.palette.error.light} !important`,
    },
    variantSuccess: {
        backgroundColor: `${theme.palette.success.light} !important`,
    },
}));

export const SnackbarProvider = ({children}: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <NotistackSnackbarProvider
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            autoHideDuration={10000}
            className={classes.snackbar}
            classes={{
                variantError: classes.variantError,
                variantSuccess: classes.variantSuccess,
            }}
            iconVariant={{
                error: null,
                success: null,
                warning: null,
            }}
            maxSnack={3}
        >
            {children}
        </NotistackSnackbarProvider>
    );
};
