import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {RouteConfig} from '../../router/RouteConfig';
import {AuthLayout} from '../../components/layout/AuthLayout';
import {lang} from '../../lang/lang';

export const authRoutes = (): RouteConfig => ({
    path: 'auth',
    component: AuthLayout,
    title: lang.auth.title,
    public: true,
    routes: [
        {
            path: 'signIn',
            component: lazy(() => import('./signIn/SignInPage').then(defaultExport('SignInPage'))),
            public: true,
            title: lang.auth.signIn.title,
        },
        {
            path: 'signUp',
            component: lazy(() => import('./confirmEmail/ConfirmEmailPage').then(defaultExport('ConfirmEmailPage'))),
            public: true,
            title: lang.auth.signUp.title,
        },
        {
            path: 'createPassword',
            component: lazy(() => import('./createPassword/CreatePasswordPage').then(defaultExport('CreatePasswordPage'))),
            public: true,
            title: lang.auth.password.createTitle,
        },
        {
            path: 'resetPassword',
            component: lazy(() => import('./resetPassword/ResetPasswordPage').then(defaultExport('ResetPasswordPage'))),
            public: true,
            title: lang.auth.password.resetTitle,
        },
        {
            path: 'resetPasswordRequest',
            component: lazy(() => import('./resetPasswordRequest/ResetPasswordRequestPage').then(defaultExport('ResetPasswordRequestPage'))),
            public: true,
            title: lang.auth.password.resetTitle,
        },
    ],
});
