import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button} from '@material-ui/core';
import {Technology} from '../../../graphql/graphql.types';
import {plural} from '../../../lang/plural';
import {lang} from '../../../lang/lang';
import {TableDialog} from '../../../components/tableDialog/TableDialog';

type Props = {
    editMode: boolean;
    hasSelection: boolean;
    onAdd: (technologies: Technology[]) => void;
    onManage: () => void;
    onRemove: () => void;
    technologiesCount: number;
    unsubscribedTechnologies: Technology[];
};

const useStyles = makeStyles({
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    headerText: {
        flex: 1,
    },
});

export const TechnologySubscriptionsHeader = ({
    editMode,
    hasSelection,
    onAdd,
    onManage,
    onRemove,
    technologiesCount,
    unsubscribedTechnologies,
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.headerContainer}>
            <span className={classes.headerText}>
                {plural(lang.technologySubscriptions.header.technologiesInTracking, {count: technologiesCount})}
            </span>
            {editMode ? (
                <>
                    {hasSelection ? (
                        <Box mr={1}>
                            <Button color="secondary" onClick={onRemove}>
                                {lang.technologySubscriptions.header.remove}
                            </Button>
                        </Box>
                    ) : null}
                    {unsubscribedTechnologies.length > 0 ? (
                        <TableDialog<Technology>
                            addButtonText={lang.technologySubscriptions.add.addToMyList}
                            chooseText={lang.technologySubscriptions.add.choose}
                            dialogTitleText={lang.technologySubscriptions.add.button}
                            items={unsubscribedTechnologies}
                            onSelect={onAdd}
                            tableTitleText={lang.technologySubscriptions.table.technology}
                        />
                    ) : null}
                </>
            ) : (
                <Button color="secondary" onClick={onManage} variant="outlined">
                    {lang.technologySubscriptions.header.manage}
                </Button>
            )}
        </div>
    );
}
