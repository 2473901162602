import React, {MouseEvent, useCallback, useState} from 'react';
import {Button, Menu, MenuItem, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterType} from './types';
import AddIcon from '../../assets/icons/add.svg';
import {stripes} from './styles';
import {FilterDropdownMenuItem} from './FilterDropdownMenuItem';
import {lang} from '../../lang/lang';

type Props = {
    filters: TargetingFilterType[];
    onAdd: (filter: TargetingFilterType) => void;
};

const useStyles = makeStyles((theme) => ({
    addButton: {
        height: 40,
        '&::after': {
            ...stripes(theme),
            zIndex: 1,
            left: theme.spacing(-1),
            marginLeft: -1,
        },
    },
}));

export const AddFilterButton = ({filters, onAdd}: Props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleMenuOpen = useCallback((e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget), [setAnchorEl]);
    const handleMenuClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

    const handleAdd = useCallback((e: MouseEvent<HTMLLIElement>) => {
        const value = e.currentTarget.getAttribute('value')
        const filter = filters.find(({name}) => name === value);

        if (filter) {
            onAdd(filter);
        }

        handleMenuClose();
    }, [filters, handleMenuClose, onAdd]);

    return (
        <>
            <Button
                aria-controls="add-targeting-filter-menu"
                aria-haspopup="true"
                className={classes.addButton}
                color="secondary"
                onClick={handleMenuOpen}
                variant="outlined"
            >
                <SvgIcon>
                    <AddIcon />
                </SvgIcon>
                {lang.targeting.filter.add}
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="add-targeting-filter-menu"
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {filters.map((filter) => (
                    <MenuItem key={filter.name} onClick={handleAdd} value={filter.name}>
                        <FilterDropdownMenuItem filter={filter} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
