import React, {PropsWithChildren, useContext} from 'react';
import {Box, Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Header} from './header/Header';
import {Drawer} from './drawer/Drawer';
import {AuthContext} from '../../auth/AuthContext';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbarOffset: theme.mixins.toolbar,
}));

export const Layout = ({children}: PropsWithChildren<{}>) => {
    const classes = useStyles();
    const {token, onResetToken} = useContext(AuthContext);

    return (
        <Box className={classes.root}>
            <Header onLogout={onResetToken} />
            {token ? <Drawer /> : null}
            <main className={classes.content}>
                <Container>
                    <div className={classes.toolbarOffset} />
                    {children}
                </Container>
            </main>
        </Box>
    );
};
