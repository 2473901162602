import {RouteConfig} from '../../router/RouteConfig';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {RouteChildren} from '../../router/RouteChildren';
import {lang} from '../../lang/lang';

export const errorRoutes = (): RouteConfig => ({
    path: 'error',
    title: lang.layout.errorPageTitle,
    component: RouteChildren,
    routes: [
        {
            path: 'notActivated',
            component: lazy(() => import('./notActivated/NotActivatedPage').then(defaultExport('NotActivatedPage'))),
            title: lang.auth.notActivated.pageTitle,
        },
    ],
});
