import React, {ContextType, PropsWithChildren, useCallback, useContext, useMemo} from 'react';
import {TechnologySubscriptionContext} from './TechnologySubscriptionContext';
import {
    useSubscribeToTechnologyMutation,
    useTechnologySubscriptionsQuery,
    useUnsubscribeFromTechnologyMutation,
    useReplaceTechnologySubscriptionsMutation
} from '../../../graphql/graphql.types';
import {AuthContext} from '../../../auth/AuthContext';
import {useSnackbar} from '../../snackbar/useSnackbar';
import {lang} from '../../../lang/lang';

export const TechnologySubscriptionProvider = ({children}: PropsWithChildren<{}>) => {
    const {token} = useContext(AuthContext);
    const {data, loading: technologySubscriptionsLoading, refetch} = useTechnologySubscriptionsQuery({
        skip: !token,
    });
    const technologyUuids = data?.technologySubscriptions || [];
    const {successNotification} = useSnackbar();

    const [replaceTechnonlogySubscriptions, {loading: replaceTechnologySubscriptionsLoading}] = useReplaceTechnologySubscriptionsMutation();
    const handleReplace = useCallback(async (technologyUuids: string[]) => {
        await replaceTechnonlogySubscriptions({
            variables: {technologyIds: technologyUuids},
        });
        await refetch();
        successNotification(lang.technologySubscriptions.notification.successUpdate);
    }, [replaceTechnonlogySubscriptions, refetch]);

    const [subscribeToTechnology, {loading: subscribeToTechnologyLoading}] = useSubscribeToTechnologyMutation();
    const handleSubscribe = useCallback(async (technologyUuid: string) => {
        await subscribeToTechnology({
            variables: {technologyIds: [technologyUuid]},
        });
        await refetch();
        successNotification(lang.technologySubscriptions.notification.successSubscribe);
    }, [subscribeToTechnology, refetch]);

    const [unsubscribeFromTechnology, {loading: unsubscribeFromTechnologyLoading}] = useUnsubscribeFromTechnologyMutation();
    const handleUnsubscribe = useCallback(async (technologyUuid: string) => {
        await unsubscribeFromTechnology({
            variables: {technologyIds: [technologyUuid]},
        });
        await refetch();
        successNotification(lang.technologySubscriptions.notification.successUnsubscribe);
    }, [unsubscribeFromTechnology, refetch]);

    const loading = technologySubscriptionsLoading ||
        replaceTechnologySubscriptionsLoading ||
        subscribeToTechnologyLoading ||
        unsubscribeFromTechnologyLoading;

    const value: ContextType<typeof TechnologySubscriptionContext> = useMemo(() => ({
        loading,
        onReplace: handleReplace,
        onSubscribe: handleSubscribe,
        onUnsubscribe: handleUnsubscribe,
        technologyUuids,
    }), [handleReplace, handleUnsubscribe, loading, technologyUuids]);

    return (
        <TechnologySubscriptionContext.Provider value={value}>
            {children}
        </TechnologySubscriptionContext.Provider>
    );
};
