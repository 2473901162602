import {Theme} from '@material-ui/core';

export const stripes = (theme: Theme) => ({
    content: '""',
    position: 'absolute',
    top: 'calc(50% - 1px)',
    width: theme.spacing(1),
    height: 2,
    backgroundColor: theme.palette.primary.light,
});
