export default {
    "title": "ドメイン",
    "details": {
        "title": "ドメイン",
        "domainTechnologies": "ドメインテクノロジー",
        "domainHistory": "ドメイン履歴"
    },
    "layout": {
        "technologiesTab": "テクノロジー",
        "historyTab": "履歴"
    },
    "link": {
        "openInNewTab": "新規タブで開く"
    },
    "table": {
        "domain": "ドメイン",
        "country": "国名",
        "technologyCount": "テクノロジー数",
    }
};
