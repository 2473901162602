import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {MenuList, Paper} from '@material-ui/core';
import {CustomerAvatar} from '../CustomerAvatar';
import {Customer} from '../../../graphql/graphql.types';
import LogoutIcon from '../../../assets/icons/logout.svg';
import SettingsIcon from '../../../assets/icons/settings.svg';
import {CustomerMenuItem} from './CustomerMenuItem';
import {lang} from '../../../lang/lang';

type Props = {
    customer: Customer | null;
    email: string | null;
    onLogout: () => void;
    onSettings: () => void;
};

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: 'flex',
    },
    avatarWrapper: {
        boxSizing: 'border-box',
        padding: theme.spacing(3.5),
        paddingBottom: 0,
        width: '100%',
    },
    avatarSeparator: {
        borderBottom: `1px solid ${theme.palette.grey['200']}`,
        marginTop: theme.spacing(2.5),
        width: '100%',
    },
    headerMenu: {
        boxSizing: 'border-box',
        width: 320,
    },
    headerMenuList: {
        paddingBottom: theme.spacing(3),
    },
    profileText: {
        lineHeight: 1.5,
        overflow: 'hidden',
        paddingLeft: theme.spacing(2.5),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export const CustomerMenu = ({customer, email, onLogout, onSettings}: Props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.headerMenu}>
            <div className={classes.avatarWrapper}>
                <div className={classes.avatarContainer}>
                    <CustomerAvatar customer={customer} size="large" variant="rounded" />
                    {customer ? (
                        <div className={classes.profileText}>
                            {customer.contactInfo.name.firstName} {customer.contactInfo.name.lastName}
                            <br />
                            {email}
                        </div>
                    ) : null}
                </div>
                <div className={classes.avatarSeparator} />
            </div>
            <MenuList className={classes.headerMenuList}>
                <CustomerMenuItem icon={<SettingsIcon />} onClick={onSettings}>
                    {lang.customer.menu.settings}
                </CustomerMenuItem>
                <CustomerMenuItem icon={<LogoutIcon />} onClick={onLogout}>
                    {lang.customer.menu.logOut}
                </CustomerMenuItem>
            </MenuList>
        </Paper>
    );
};
