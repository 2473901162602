import merge from 'lodash.merge';
import enUs from './en-US';

const enUsClone = JSON.parse(JSON.stringify(enUs));

export const langLoader = async (locale: string): Promise<void> => {
    if (locale === 'ja-JP') {
        merge(enUs, enUsClone, (await import ('./ja-JP')).default);
    } else {
        merge(enUs, enUsClone);
    }
};
