export default {
    title: 'Admin Panel',
    users: {
        title: 'Users',
        list: {
            searchPlaceholder: 'Search by name or email',
            createUser: 'Create user',
            table: {
                firstName: 'First name',
                lastName: 'Last name',
                email: 'Email',
                status: 'Status',
                statusActive: 'Active',
                statusPending: 'Pending',
                statusBlocked: 'Blocked',
            },
        },
        create: {
            title: 'New user',
            profile: {
                title: 'Profile details',
                generalInformation: 'General information',
                mandatoryFields: '*mandatory fields',
                firstName: 'First Name',
                lastName: 'Last Name',
                businessRole: 'Business Role',
                companyName: 'Company Name',
                userRole: 'User role',
                roleSales: 'Sales',
                roleAdmin: 'Admin',
                roleClient: 'Client',
                contactInformation: 'Contact information',
                email: 'Email',
                companyPhone: 'Company Phone',
            },
            access: {
                title: 'Permissions and access',
                permissions: 'Permissions',
                active: 'ACTIVE',
                inactive: 'INACTIVE',
                alertsSubscription: 'Alerts subscription',
                feedFeature: 'Feed feature',
                csvExport: 'Data export in CSV',
                categories: {
                    title: 'Access to technology categories',
                    add: 'Add categories',
                    open: 'Add category',
                    select: 'Select categories in order to give an access to a user',
                    dialogTitle: 'Technology categories',
                    tableTitle: 'Category',
                    noCategories: 'No categories granted yet.',
                    categoriesCount: '{count} categories',
                    remove: 'Remove',
                },
            },
            success: 'User create successfully',
            error: 'Failed to create user',
            createUser: 'Create user',
            cancel: 'Cancel',
        },
    },
};
