import React, {useCallback, useMemo} from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseIso from 'date-fns/parseISO';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {TextFieldProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {DateTargetingFilter, DateTargetingFilterValue} from '../types';
import {stripes} from '../styles';

const DATE_FORMAT = 'dd.MM.yyyy';

type Props = {
    filter: DateTargetingFilter;
    onChange: (value: DateTargetingFilterValue) => void;
    value: DateTargetingFilterValue;
};

const useStyles = makeStyles((theme) => ({
    adornedEnd: {
        paddingRight: 0,
    },
    container: {
        display: 'flex',
    },
    input: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    picker: {
        flex: 1,
        maxWidth: 150,
    },
    root: {
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        flex: 1,
        paddingBottom: 0,
        '&::before': {
            content: 'initial',
        },
        '&::after': {
            content: 'initial',
        },
    },
    notchedOutline: {
        borderColor: theme.palette.primary.light,
    },
    toDate: {
        marginLeft: theme.spacing(1),
    },
    and: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&::before': {
            ...stripes(theme),
            left: theme.spacing(-1),
        },
        '&::after': {
            ...stripes(theme),
            right: theme.spacing(-1),
        },
    },
}));

export const DateFilterValue = ({filter, onChange, value}: Props) => {
    const classes = useStyles();
    const inputProps: TextFieldProps['InputProps'] = {
        classes: {
            adornedEnd: classes.adornedEnd,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
            root: classes.root,
        },
    };

    const fromDate = value.value[0];
    const toDate = value.value[1];
    const fromValue = useMemo(() => fromDate ? parseIso(fromDate) : null, [fromDate]);
    const toValue = useMemo(() => toDate ? parseIso(toDate) : null, [toDate]);

    const handleFromChange = useCallback((date: MaterialUiPickersDate | null) => {
        if (!date || isNaN((date as unknown) as number)) {
            return;
        }

        try {
            onChange({
                title: filter.title,
                value: [date.toISOString() || '', toValue?.toISOString() || ''],
            });
        } catch {}
    }, [toDate, onChange]);
    const handleToChange = useCallback((date: MaterialUiPickersDate | null) => {
        if (!date || isNaN((date as unknown) as number)) {
            return;
        }

        onChange({
            title: filter.title,
            value: [fromValue?.toISOString() || '', date.toISOString() || ''],
        });
    }, [fromDate, onChange]);

    const renderLabel = useCallback((date: MaterialUiPickersDate) => {
        return date ? format(date, DATE_FORMAT) : '';
    }, []);

    return (
        <div className={classes.container}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    className={classes.picker}
                    disableFuture
                    disableToolbar
                    format={DATE_FORMAT}
                    InputProps={inputProps}
                    inputVariant="outlined"
                    labelFunc={renderLabel}
                    onChange={handleFromChange}
                    placeholder="dd.mm.yyyy"
                    variant="inline"
                    value={fromValue}
                />
                <div className={classes.and}>and</div>
                <KeyboardDatePicker
                    autoOk
                    className={clsx(classes.picker, classes.toDate)}
                    disableFuture
                    disableToolbar
                    format={DATE_FORMAT}
                    InputProps={inputProps}
                    inputVariant="outlined"
                    labelFunc={renderLabel}
                    minDate={fromValue}
                    onChange={handleToChange}
                    placeholder="dd.mm.yyyy"
                    variant="inline"
                    value={toValue}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};
