export default {
    settings: {
        notification: {
            tokenSuccessfullyCreated: 'API token created',
            tokenCannotBeCreated: 'API token failed to be created',
            tokenSuccessfullyRemoved: 'API token removed',
            tokenCannotBeRemoved: 'API token failed to be removed',
        },
        table: {
            name: 'Name',
            expiresAt: 'Expires At',
            createdAt: 'Created At',
            actions: 'Actions',
            remove: 'Remove',
            never: 'Never',
            removeDialog: {
                title: 'Remove API Token',
                text: 'Token "{name}" will be removed. Token cannot be used after removal',
                removeButton: 'Remove',
                cancelButton: 'Cancel',
            },
            add: 'Create',
            addDialog: {
                title: 'Create API Token',
                name: 'Name',
                expiresAt: 'Token expiration date',
                createButton: 'Create',
                cancelButton: 'Cancel',
                closeButton: 'Close',
                goToPlayground: 'Go to Playground',
                titleCreated: 'API Token created',
                textCreated: 'Make sure you save the API token - you won\'t be able to access it again.',
                expiresAtHint: 'Leave blank to make token non-expiring',
            },
        },
    },
}
