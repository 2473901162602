import React from 'react';
import {Grid} from '@material-ui/core';
import {TextInputField} from '../formik/TextInputField';
import {lang} from '../../lang/lang';
import {PhoneInputField} from '../formik/PhoneInputField';

type Props = {
    fullWidth?: boolean;
};

export const CustomerProfileFormFields = ({fullWidth = false}: Props) => {
    return (
        <Grid container direction="column" spacing={4} md={fullWidth ? 12 : 6} xs={12}>
            <Grid item>
                <TextInputField
                    autoFocus
                    fullWidth
                    label={lang.customer.profile.firstName}
                    name="contactInfo.firstName"
                />
            </Grid>
            <Grid item>
                <TextInputField
                    fullWidth
                    label={lang.customer.profile.lastName}
                    name="contactInfo.lastName"
                />
            </Grid>
            <Grid item>
                <TextInputField
                    fullWidth
                    label={lang.customer.profile.companyName}
                    name="employmentInfo.companyName"
                />
            </Grid>
            <Grid item>
                <PhoneInputField
                    fullWidth
                    label={lang.customer.profile.companyPhone}
                    name="employmentInfo.companyPhone"
                />
            </Grid>
            <Grid item>
                <TextInputField
                    fullWidth
                    label={lang.customer.profile.role}
                    name="employmentInfo.role"
                />
            </Grid>
        </Grid>
    );
};
