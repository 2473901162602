import {Button, makeStyles, SvgIcon} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import AddIcon from "../../../../assets/icons/add.svg";
import {lang} from "../../../../lang/lang";
import {ApiTokensCreateDialog} from "./ApiTokensCreateDialog";

type Props = {
    loading: boolean;
    onCreate: (name: string, expiresAt: Date | null) => Promise<string | undefined>;
};

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export const ApiTokensCreateButton = ({loading, onCreate}: Props) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const handleOpenDialog = useCallback(() => setShowDialog(true), []);
    const handleCloseDialog = useCallback(() => setShowDialog(false), []);

    return (
        <>
            <div className={classes.wrapper}>
                <Button color="secondary" onClick={handleOpenDialog} variant="outlined">
                    <SvgIcon>
                        <AddIcon />
                    </SvgIcon>
                    {lang.api.settings.table.add}
                </Button>
            </div>
            {showDialog && (
                <ApiTokensCreateDialog
                    loading={loading}
                    onClose={handleCloseDialog}
                    onCreate={onCreate}
                />
            )}
        </>
    )
};
