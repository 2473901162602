import React, {useCallback} from 'react';
import {IconButton, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterType, TargetingFilterState} from '../types';
import {FilterDropdown} from '../FilterDropdown';
import {stripes} from '../styles';
import {FilterEquation} from './FilterEquation';
import RemoveIcon from '../../../assets/icons/remove.svg';
import {lang} from '../../../lang/lang';

type Props = {
    availableFilters: TargetingFilterType[];
    canRemove: boolean;
    filterState: TargetingFilterState;
    index: number;
    onChange: (filterIndex: number, value: TargetingFilterType | TargetingFilterState) => void;
    onRemove: (filterIndex: number) => void;
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(1),
        position: 'relative',
        '&::after': {
            ...stripes(theme),
            top: 20,
            left: theme.spacing(-1),
        },
        '&:hover': {
            '& $removeButton': {
                opacity: ({canRemove}: Pick<Props, 'canRemove'>) => canRemove ? 1 : 0,
            },
        },
    },
    removeButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    removeButton: () => ({
        marginTop: theme.spacing(2.5),
        marginLeft: theme.spacing(1),
        opacity: 0,
        transition: theme.transitions.create('opacity'),
        transform: 'translateY(-50%)',
    }),
}));

export const FilterExpression = ({
    availableFilters,
    canRemove,
    filterState,
    index,
    onChange,
    onRemove,
}: Props) => {
    const classes = useStyles({canRemove});
    const handleFilter = useCallback((filter: TargetingFilterType) => {
        onChange(index, filter);
    }, [filterState, index, onChange]);

    const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);

    return (
        <div className={classes.container}>
            <FilterDropdown
                filters={availableFilters}
                onChange={handleFilter}
                value={filterState.filter}
            />
            <FilterEquation
                filterState={filterState}
                index={index}
                onChange={onChange}
            />
            <div className={classes.removeButtonWrapper}>
                <IconButton
                    aria-label={lang.targeting.filter.remove}
                    className={classes.removeButton}
                    disabled={!canRemove}
                    onClick={handleRemove}
                    size="small"
                >
                    <SvgIcon fontSize="inherit">
                        <RemoveIcon />
                    </SvgIcon>
                </IconButton>
            </div>
        </div>
    );
};
