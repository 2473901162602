import React, {useCallback} from 'react';
import {OptionsObject, useSnackbar as useNotistack} from 'notistack';
import {Button} from '@material-ui/core';

type Options = Omit<OptionsObject, 'variant'>

export const useSnackbar = () => {
    const {closeSnackbar, enqueueSnackbar} = useNotistack();

    const notification = useCallback((message, options: OptionsObject) => {
        enqueueSnackbar(message, {
            ...options,
            action: options.persist ? (key) => (
                <Button onClick={() => closeSnackbar(key)}>
                    Dismiss
                </Button>
            ) : null,
        });
    }, [closeSnackbar, enqueueSnackbar]);

    const errorNotification = useCallback((message: string, options?: Options) => {
        notification(message, {persist: true, variant: 'error', ...options});
    }, [notification]);
    const successNotification = useCallback((message: string, options?: Options) => {
        notification(message, {persist: true, variant: 'success', ...options});
    }, [notification]);

    return {errorNotification, successNotification};
};
