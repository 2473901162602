import React, {ReactNode, useCallback} from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import {useField} from 'formik';

export type Option = {
    label: ReactNode;
    value: string | number;
};

type Props = Omit<SelectProps, 'error' | 'helperText' | 'label' | 'name' | 'onChange'> & {
    label: ReactNode;
    name: string;
    options: Option[];
    required?: boolean;
};

export const SelectInputField = ({fullWidth, label, name, options, required, variant = 'outlined', ...selectProps}: Props) => {
    const [{onBlur, value}, {error, touched}, {setValue, setTouched}] = useField(name);
    const handleChange = useCallback(({target}) => {
        setTouched(true);
        setValue(target.value);
    }, [setValue, setTouched]);
    const selectLabel = required ? <>{label}*</> : label;

    return (
        <FormControl fullWidth={fullWidth} variant={variant}>
            <InputLabel>{selectLabel}</InputLabel>
            <Select
                {...selectProps}
                error={touched && Boolean(error)}
                label={selectLabel}
                onBlur={onBlur}
                onChange={handleChange}
                value={value}
            >
                {options.map((option, index) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {touched && error ? (
                <FormHelperText>{error}</FormHelperText>
            ) : null}
        </FormControl>
    );
};
