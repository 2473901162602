import React, {useContext, useEffect} from 'react';
import {RouteProps, useHistory} from 'react-router-dom';
import {AuthContext} from '../auth/AuthContext';
import {Paths} from './paths';

export const AuthRoute = (props: RouteProps) => {
    const {loaded, token} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (loaded && !token) {
            history.push(Paths.SIGN_IN);
        }
    }, [history, loaded, token]);

    return <>{props.children}</>;
};
