import React, {PropsWithChildren, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useSubscribedDomainsQuery} from "../../graphql/graphql.types";
import {Paths} from "../../router/paths";
import {DashboardLoader} from "./DashboardLoader";
import {lang} from "../../lang/lang";
import {PageTitle} from "../../components/layout/PageTitle";
import {plural} from "../../lang/plural";
import {Link} from "../../components/navigation/Link";

type Props = PropsWithChildren<{}>;

export const DashboardPage = ({children}: Props) => {
    const history = useHistory();
    const location = useLocation();
    const {data, loading, refetch} = useSubscribedDomainsQuery();
    const availableDomains = data?.domainSubscriptions?.available;

    useEffect(() => {
        if (availableDomains === undefined || availableDomains === null) {
            return;
        }

        if (location.pathname === Paths.DASHBOARD_SETUP && availableDomains === 0) {
            history.push(Paths.DASHBOARD);
        }
    }, [data, history, location.pathname]);
    useEffect(() => {
        refetch();
    }, [location.pathname]);
    console.log(location.pathname, Paths.DASHBOARD, availableDomains);

    if (loading) {
        return (
            <>


            </>
        );
    }

    return (
        <>
            <PageTitle>
                {location.pathname === Paths.DASHBOARD_SETUP ? lang.dashboard.setup.title : lang.dashboard.title}
            </PageTitle>
            {loading ? (
                <DashboardLoader />
            ) : (
                <>
                    {location.pathname === Paths.DASHBOARD && availableDomains && availableDomains > 0 ? (
                        <p>
                            {plural(lang.dashboard.main.addDomains, {domains: availableDomains})}
                            {' '}
                            <Link to={Paths.DASHBOARD_SETUP}>{lang.dashboard.main.setup}</Link>
                        </p>
                    ) : null}
                    {children}
                </>
            )}
        </>
    );
};
