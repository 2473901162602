import React, {useCallback} from 'react';
import {useField} from 'formik';
import PhoneInput from 'material-ui-phone-number';
import {TextFieldProps} from '@material-ui/core';

type Props = Omit<TextFieldProps, 'error' | 'helperText' | 'label' | 'name' | 'onChange'> & {
    label: string;
    name: string;
    required?: boolean;
}

const PREFERRED_COUNTRIES = ['jp', 'us'];

export const PhoneInputField = ({label, name, required = false, variant = 'outlined', ...textFieldProps}: Props) => {
    const [{onBlur, value},, {setValue, setTouched}] = useField(name);
    const handleChange = useCallback((nextValue) => {
        setTouched(true);
        setValue(nextValue);
    }, [setTouched, setValue]);

    return (
        <PhoneInput
            {...textFieldProps}
            defaultCountry="jp"
            disableAreaCodes
            label={required ? `${label}*` : label}
            onBlur={onBlur}
            onChange={handleChange}
            preferredCountries={PREFERRED_COUNTRIES}
            value={value}
            variant={variant}
        />
    );
};
