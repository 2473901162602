import {styled, Typography} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import React, {useMemo} from 'react';
import format from 'date-fns/format';

type Props = {
    foundAt: number;
};

const FoundAtTypography = styled(Typography)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
}));

export const FoundAt = ({foundAt}: Props) => {
    const foundAtFormatted = useMemo(() => {
        return format(new Date(foundAt), 'do MMMM yyyy');
    }, [foundAt]);

    return (
        <FoundAtTypography>
            <TimerIcon fontSize='small' /> {foundAtFormatted}
        </FoundAtTypography>
    )
};
