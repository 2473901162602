import React, {useCallback} from 'react';
import {GroupTargetingFilterState, SingleTargetingFilterState} from '../types';
import {SingleFilterEquation} from './SingleFilterEquation';
import {makeStyles} from '@material-ui/core/styles';
import {stripes} from '../styles';

type Props = {
    filterValue: GroupTargetingFilterState;
    index: number;
    onChange: (filterIndex: number, value: GroupTargetingFilterState) => void;
};

const useStyles = makeStyles((theme) => ({
    equations: {
        flex: 1,
        position: 'relative',
        '&::before': {
            ...stripes(theme),
            top: 21,
            left: -5,
            height: `calc(100% - 40px)`,
            width: 2,
        },
    },
    equation: {
        marginTop: theme.spacing(0.5),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

export const MultipleFilterEquation = ({filterValue, index, onChange}: Props) => {
    const classes = useStyles();

    const handleChange = useCallback((stateIndex: number, state: SingleTargetingFilterState) => {
        const nextState = [...filterValue.state];
        nextState[stateIndex] = state;

        onChange(index, {...filterValue, state: nextState});
    }, [filterValue, index, onChange]);

    return (
        <div className={classes.equations}>
            {filterValue.state.map((singleState, index) => (
                <div className={classes.equation}>
                    <SingleFilterEquation
                        key={index}
                        filterState={singleState}
                        index={index}
                        multiple
                        onChange={handleChange}
                    />
                </div>
            ))}
        </div>
    );
};
