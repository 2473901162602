import React from 'react';
import {AuthMessage} from '../../../auth/AuthMessage';
import {lang} from '../../../lang/lang';

export const NotActivatedPage = () => {
    return (
        <AuthMessage title={lang.auth.notActivated.title}>
            {lang.auth.notActivated.description}
            <a href="mailto:support@tecplus.jp">{lang.auth.notActivated.support}</a>.
        </AuthMessage>
    );
};
