import React, {useCallback} from 'react';
import {Button} from '@material-ui/core';
import {useResetPasswordRequestMutation} from '../../../../graphql/graphql.types';
import {useSnackbar} from '../../../../components/snackbar/useSnackbar';
import {lang} from '../../../../lang/lang';

type Props = {
    email: string;
};

export const ForgotPassword = ({email}: Props) => {
    const {errorNotification, successNotification} = useSnackbar();
    const [requestPasswordReset, {loading}] = useResetPasswordRequestMutation();
    const handleReset = useCallback(async () => {
        try {
            await requestPasswordReset({variables: {email}});

            successNotification(lang.auth.password.notification.resetRequested);
        } catch {
            errorNotification(lang.auth.password.notification.resetRequestFailed);
        }
    }, [errorNotification, requestPasswordReset, successNotification]);

    return (
        <div>
            {lang.auth.password.forgot.cantRemember}
            <Button color="secondary" disabled={loading} onClick={handleReset}>
                {lang.auth.password.forgot.resetPassword}
            </Button>
        </div>
    );
};
