export default {
    drawer: {
        title: 'Feed',
    },
    empty: {
        title: 'No notifications right now',
        description: 'When any company adds or drops a technology you’ve subscribed, we’ll show the notifications here.',
    },
    list: {
        thisWeek: 'This week',
        oneWeekAgo: '1 week ago',
        severalWeeksAgo: '{count} weeks ago',
    },
}
