import React, {PropsWithChildren, useContext, useEffect, useMemo} from 'react';
import {AuthContext} from './AuthContext';
import {useRolesLazyQuery} from '../graphql/graphql.types';
import {RolesContext} from './RolesContext';

export const RolesProvider = ({children}: PropsWithChildren<{}>) => {
    const {loaded: authLoaded, token} = useContext(AuthContext);

    const [fetchRoles, {data, loading}] = useRolesLazyQuery();
    const loaded = Boolean((authLoaded && !token) || (data && !loading));

    useEffect(() => {
        if (authLoaded && token) {
            fetchRoles();
        }
    }, [authLoaded, fetchRoles, token]);

    const value = useMemo(() => ({
        loaded,
        roles: data && !loading ? data.roles : [],
    }), [data, loading, loaded]);

    return (
        <RolesContext.Provider value={value}>
            {children}
        </RolesContext.Provider>
    );
};
