import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {NavigationTab} from './types';
import {NavigationTabs} from './NavigationTabs';

type Props = {
    tabs: NavigationTab[];
};

export const RouterNavigationTabs = ({tabs}: Props) => {
    const {pathname} = useLocation();
    const history = useHistory();

    return <NavigationTabs onChange={history.push} path={pathname} tabs={tabs} />;
};
