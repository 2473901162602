import React, {PropsWithChildren, useEffect} from 'react';
import {RouteProps, useHistory, useLocation} from 'react-router-dom';
import {Box, Paper} from '@material-ui/core';
import {Paths} from '../../router/paths';

export const SettingsPage = ({children}: PropsWithChildren<RouteProps>) => {
    const history = useHistory();
    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname === Paths.SETTINGS) {
            history.push(Paths.SETTINGS_PROFILE);
        }
    }, [history, pathname]);

    return (
        <Paper>
            <Box p={5}>
                {children}
            </Box>
        </Paper>
    );
};
