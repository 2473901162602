export default {
    title: 'DX Report',
    main: {
        addDomains: 'You can add {domains} domains.',
        setup: 'Setup',
        expandHistory: 'Show whole history',
        expandTechnologies: 'Show all technologies',
        technologiesInUse: 'Technologies',
    },
    setup: {
        title: 'Choose domains for DX Report',
        typeDomains: 'Type domain name to start searching',
        loading: 'Loading...',
        notFound: 'No domains found',
        technologiesCount: '{technologies} technologies',
        subscribedTo: 'Already subscribed to:',
        selectDomains: 'Select {domains} domains to follow',
        domainsLimitReached: 'You can\'t select more than {domains} domains',
        subscribe: 'Subscribe',
        successfullySubscribed: 'Successfully subscribed to {domains} domains',
        failedToSubscribe: 'Failed to subscribe',
    },
};
