import {lang} from '../../../lang/lang';
import {plural} from '../../../lang/plural';

export const formatWeek = (week: number) => {
    if (week === 0) {
        return lang.feed.list.thisWeek;
    }

    if (week === 1) {
        return lang.feed.list.oneWeekAgo;
    }

    return plural(lang.feed.list.severalWeeksAgo, {count: week});
};
