import React, {useCallback} from "react";
import {useApiTokensQuery, useCreateApiTokenMutation, useRemoveApiTokenMutation} from "../../../../graphql/graphql.types";
import {ApiTokensTable} from "./ApiTokensTable";
import {useSnackbar} from "../../../../components/snackbar/useSnackbar";
import {lang} from "../../../../lang/lang";

export const ApiTokensPage = () => {
    const {errorNotification, successNotification} = useSnackbar();
    const {data, loading, refetch} = useApiTokensQuery();
    const [createApiToken, {loading: createLoading}] = useCreateApiTokenMutation();
    const handleCreate = useCallback(async (name: string, expiresAt: Date | null) => {
        try {
            const result = await createApiToken({
                variables: {name, expiresAt: expiresAt?.toISOString()},
            });

            successNotification(lang.api.settings.notification.tokenSuccessfullyCreated);

            refetch();

            return result.data?.createApiToken;
        } catch {
            errorNotification(lang.api.settings.notification.tokenCannotBeCreated);

            return undefined;
        }
    }, [createApiToken, refetch, successNotification, errorNotification]);
    const [removeApiToken, {loading: removeLoading}] = useRemoveApiTokenMutation();
    const handleRemove = useCallback(async (tokenUuid: string) => {
        try {
            await removeApiToken({
                variables: {tokenUuid},
            });

            successNotification(lang.api.settings.notification.tokenSuccessfullyRemoved);

            await refetch();
        } catch {
            errorNotification(lang.api.settings.notification.tokenCannotBeRemoved);
        }
    }, [removeApiToken, refetch, successNotification, errorNotification]);

    return (
        <ApiTokensTable
            apiTokens={data?.apiTokens || []}
            loading={loading || createLoading || removeLoading}
            onCreate={handleCreate}
            onRemove={handleRemove}
        />
    );
};
