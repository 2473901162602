import React, {useCallback} from 'react';
import {MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterType} from './types';
import {TargetingSelect} from './inputs/TargetingSelect';
import {FilterDropdownMenuItem} from './FilterDropdownMenuItem';

type Props = {
    filters: TargetingFilterType[];
    onChange: (filter: TargetingFilterType) => void;
    value: TargetingFilterType;
};

const useStyles = makeStyles((theme) => ({
    listItemIcon: {
        minWidth: theme.spacing(3.5),
    },
    select: {
        minWidth: 250,
    },
    selectRoot: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(0.5) - 1,
        paddingBottom: theme.spacing(0.5),
    },
}));

export const FilterDropdown = ({filters, onChange, value}: Props) => {
    const classes = useStyles();
    const handleChange = useCallback((value: string) => {
        const filter = filters.find(({name}) => name === value) || null;

        if (filter) {
            onChange(filter);
        }
    }, [onChange]);

    return (
        <TargetingSelect
            className={classes.select}
            classes={{root: classes.selectRoot}}
            onChange={handleChange}
            value={value.name}
        >
            {filters.map((filter) => (
                <MenuItem key={filter.name} value={filter.name}>
                    <FilterDropdownMenuItem filter={filter} />
                </MenuItem>
            ))}
        </TargetingSelect>
    );
};
