import React, {useCallback, useState} from 'react';
import {useResetPasswordRequestMutation} from '../../../graphql/graphql.types';
import {AuthMessage} from '../../../auth/AuthMessage';
import {EmailForm} from '../components/EmailForm';
import {lang} from '../../../lang/lang';

export const ResetPasswordRequestPage = () => {
    const [passwordResetCompleted, setPasswordResetCompleted] = useState(false);
    const [requestPasswordReset] = useResetPasswordRequestMutation();
    const handleSubmit = useCallback(async ({email}: {email: string}) => {
        await requestPasswordReset({variables: {email}});

        setPasswordResetCompleted(true);
    }, [requestPasswordReset, setPasswordResetCompleted]);

    if (passwordResetCompleted) {
        return (
            <AuthMessage title={lang.auth.password.resetRequest.checkYourInbox}>
                {lang.auth.password.resetRequest.checkYourEmail}
            </AuthMessage>
        );
    }

    return (
        <EmailForm
            buttonText={lang.auth.password.resetRequest.resetButton}
            onSubmit={handleSubmit}
            title={lang.auth.password.resetRequest.formTitle}
        />
    );
};
