import {makeStyles} from '@material-ui/core/styles';
import {Divider, Tab, Tabs} from '@material-ui/core';
import React, {useCallback} from 'react';
import {NavigationTab} from './types';

type Props = {
    onChange: (path: string) => void;
    path: string;
    tabs: NavigationTab[];
};

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: -1,
        marginBottom: theme.spacing(4),
    },
}));

export const NavigationTabs = ({onChange, path, tabs}: Props) => {
    const classes = useStyles();
    const handleChange = useCallback((_, nextPath: string) => onChange(nextPath), []);

    return (
        <>
            <Tabs onChange={handleChange} textColor="secondary" value={path}>
                {tabs.map(({label, path}) => (
                    <Tab key={path} label={label} value={path} />
                ))}
            </Tabs>
            <Divider className={classes.divider} />
        </>
    );
};
