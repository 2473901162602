export default {
    "notification": {
        "successUpdate": "テクノロジーの登録が正しく更新されました",
        "successSubscribe": "テクノロジーへの登録が正しく行われました",
        "successUnsubscribe": "テクノロジーからの削除が正しく行われました"
    },
    "table": {
        "technology": "テクノロジー"
    },
    "add": {
        "addMore": "更に追加",
        "button": "登録リストに追加",
        "choose": "トラッキングしたいひとつ、または複数のテクノロジーを選ぶ",
        "cancel": "キャンセル",
        "addToMyList": "自分のリストに追加"
    },
    "header": {
        "technologiesInTracking": "{count}件のテクノロジーをトラッキング中。毎週、テクアラートをメールでお知らせします",
        "remove": "削除",
        "manage": "管理"
    },
    "footer": {
        "cancel": "キャンセル",
        "save": "保存"
    }
};
