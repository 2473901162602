import React, {useCallback} from 'react';
import {unstable_batchedUpdates as batchedUpdates} from 'react-dom';
import {SortDirection, TableCell, TableSortLabel} from '@material-ui/core';
import {TableConfig} from './types';
import {Order} from '../../graphql/graphql.types';

type Props<T> = {
    config: TableConfig<T>;
    onOrder: (order: Order) => void;
    onField: (field: string) => void;
    sortable: boolean;
    sorting: {field: string; order: Order};
};

export function TableHeaderItem<T>({config, onOrder, onField, sortable, sorting}: Props<T>) {
    const isActive = config.name === sorting.field;
    const handleSorting = useCallback(() => {
        const isAsc = sorting.field === config.name && sorting.order === Order.ASC;

        batchedUpdates(() => {
            onField(config.name);
            onOrder(isAsc ? Order.DESC : Order.ASC);
        });
    }, [config, onOrder, onField, sorting]);

    return (
        <TableCell
            align={config.align}
            sortDirection={isActive ? sorting.order.toLowerCase() as SortDirection : false}
        >
            {sortable ? (
                <TableSortLabel
                    active={isActive}
                    direction={isActive && sorting.order === Order.DESC ? 'desc' : 'asc'}
                    onClick={handleSorting}
                >
                    {config.title}
                </TableSortLabel>
            ) : config.title}
        </TableCell>
    );
};
