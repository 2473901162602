export default {
    title: 'Auth',
    signIn: {
        link: 'Sign In',
        title: 'Sign In',
        forgotPasswordLink: 'Forgot password?',
        signInButton: 'Sign In',
        or: 'or',
        signUpLink: 'Sign Up',
    },
    signUp: {
        link: 'Sign Up',
        or: 'or',
        signInLink: 'Sign In',
        title: 'Sign Up',
    },
    email: 'E-Mail',
    password: {
        label: 'Password',
        repeat: 'Repeat password',
        current: 'Current password',
        new: 'New password',
        save: 'Save password',
        notification: {
            created: 'Password created',
            creationFailed: 'Password creation failed',
            changed: 'Password changed',
            changeFailed: 'Failed to change password',
            resetRequested: 'Password reset link sent. Check your email',
            resetRequestFailed: 'Failed to create reset password link',
            reset: 'Password changed',
            resetFailed: 'Password reset failed',
        },
        tokenFailure: {
            createTitle: 'Cannot create password without a token',
            resetTitle: 'Cannot reset password without a token',
            description: 'Missing or invalid token'
        },
        validation: {
            equal: 'Passwords must be equal',
        },
        resetTitle: 'Reset Password',
        formTitle: 'Sign Up',
        resetRequest: {
            checkYourInbox: 'Check your inbox',
            checkYourEmail: 'Check your E-mail to continue password reset',
            resetButton: 'Reset Password',
            formTitle: 'Reset Password',
        },
        createTitle: 'Create Password',
        accordion: {
            change: 'Change',
            hide: 'Hide',
        },
        forgot: {
            cantRemember: 'Can\'t remember your current password?',
            resetPassword: 'Reset your password',
        },
    },
    confirmEmail: {
        checkYourInbox: 'Check your inbox',
        checkYourEmail: 'Check your E-mail to continue your account creation',
        confirmButton: 'Confirm E-Mail',
        formTitle: 'Sign Up'
    },
    notActivated: {
        pageTitle: 'Account is not activated',
        title: 'Not activated',
        description: 'Please wait for account activation or contact ',
        support: 'support',
    },
};
