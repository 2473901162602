import {Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingExportButton} from './TargetingExportButton';
import {plural} from '../../lang/plural';
import {lang} from '../../lang/lang';

type Props = {
    count: number;
    loading: boolean;
    onExport: () => Promise<void>;
    showExport: boolean;
};

const useStyles = makeStyles(() => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export const TargetingTitle = ({count, showExport, loading, onExport}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.titleContainer}>
            <Typography variant="subtitle1">
                {count > 0 ? plural(lang.targeting.table.resultsFound, {count}) : ''}
            </Typography>
            {showExport ? <TargetingExportButton disabled={loading} onExport={onExport} /> : null}
        </div>
    );
};
