import * as yup from 'yup';
import {lang} from '../../lang/lang';

export const customerValidationSchema = () => yup.object().shape({
    contactInfo: yup.object().shape({
        firstName: yup.string()
            .label(lang.customer.profile.firstName)
            .required()
            .min(1)
            .max(128),
        lastName: yup.string()
            .label(lang.customer.profile.lastName)
            .required()
            .min(1)
            .max(128),
    }),
    employmentInfo: yup.object().shape({
        companyName: yup.string().label(lang.customer.profile.companyName).required(),
        companyPhone: yup.string().label(lang.customer.profile.companyPhone).required(),
        role: yup.string().label(lang.customer.profile.role),
    }),
});
