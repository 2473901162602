import React, {ContextType, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {unstable_batchedUpdates as batchedUpdates} from 'react-dom';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import {AuthContext} from './AuthContext';
import {Paths} from '../router/paths';

type Props = {
    children: ReactNode;
};

export const AuthProvider = ({children}: Props) => {
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [token, setToken] = useState('');

    const handleSetToken = useCallback((token: string) => {
        setToken(token);
        Cookies.set('token', token);
        history.push(Paths.ROOT);
        setLoaded(true);
    }, [history, setLoaded, setToken]);

    const handleResetToken = useCallback(() => {
        setToken('');
        Cookies.remove('token');
        setLoaded(true);
    }, [setLoaded, setToken]);

    const value = useMemo((): ContextType<typeof AuthContext> => ({
        loaded,
        token,
        onResetToken: handleResetToken,
        onSetToken: handleSetToken,
    }), [loaded, token, handleResetToken, handleSetToken]);

    useEffect(() => {
        const cookieToken = Cookies.get('token') || '';

        batchedUpdates(() => {
            setLoaded(true);

            if (cookieToken) {
                setToken(cookieToken);
            }
        });
    }, [setLoaded, setToken]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
