export default {
    notification: {
        successUpdate: 'Successfully updated technology subscriptions',
        successSubscribe: 'Successfully subscribed to technology',
        successUnsubscribe: 'Successfully unsubscribed from technology',
    },
    table: {
        technology: 'Technology',
    },
    add: {
        button: 'Add to subscription list',
        choose: 'Choose one or several technologies for tracking',
        cancel: 'Cancel',
        addToMyList: 'Add to my list',
    },
    header: {
        technologiesInTracking: '{count} technologies in tracking. You receive tech alerts on your email weekly.',
        remove: 'Remove',
        manage: 'Manage',
    },
    footer: {
        cancel: 'Cancel',
        save: 'Save',
    },
};
