import React, {useMemo} from 'react';
import diffrenceInWeeks from 'date-fns/differenceInWeeks';
import {TechnologyFeed} from '../../../graphql/graphql.types';
import {TechnologyFeedList} from './TechnologyFeedList';

type Props = {
    feeds: TechnologyFeed[];
};

const now = new Date();

export const TechnologyFeedItems = ({feeds}: Props) => {
    const feedsByWeeks = useMemo(() => {
        return feeds.reduce((carry, feed) => {
            const weekDiff = diffrenceInWeeks(now, new Date(feed.date));

            if (!carry[weekDiff]) {
                carry[weekDiff] = [feed];
            } else {
                carry[weekDiff].push(feed);
            }

            return carry;
        }, [] as TechnologyFeed[][]);
    }, [feeds]);

    return (
        <div>
            {feedsByWeeks.filter((feedsByWeek) => feedsByWeek.length > 0).map((feedsByWeek, week) => (
                <TechnologyFeedList feeds={feedsByWeek} week={week} />
            ))}
        </div>
    );
};
