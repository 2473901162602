import {useParams, useHistory, useLocation, generatePath} from 'react-router-dom';
import React, {PropsWithChildren, useCallback, useState} from 'react';
import {Tabs, Tab} from '@material-ui/core';
import {PageTitle} from '../../../components/layout/PageTitle';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';

export const DomainLayout = ({children}: PropsWithChildren<{}>) => {
    const history = useHistory();
    const {domain} = useParams<{domain: string}>();
    const {pathname} = useLocation();
    const [value, setValue] = useState(pathname);
    const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
        history.push(newValue);
        setValue(newValue);
    }, [setValue]);

    return (
        <>
            <PageTitle backTo={Paths.DOMAINS}>{domain}</PageTitle>
            <Tabs
                onChange={handleChange}
                value={value}
                variant='fullWidth'
            >
                <Tab
                    value={generatePath(Paths.DOMAIN_TECHNOLOGIES, {domain: domain})}
                    label={lang.domain.layout.technologiesTab}
                />
                <Tab
                    value={generatePath(Paths.DOMAIN_HISTORY, {domain: domain})}
                    label={lang.domain.layout.historyTab}
                />
            </Tabs>
            {children}
        </>
    )
};
