import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '../../assets/icons/add.svg';
import {Table} from '../table/Table';
import {lang} from '../../lang/lang';
import {TableConfig} from '../table/types';

type Item = {id: string};

type Props<T extends  Item> = {
    addButtonText: ReactNode;
    calculateKey?: (item: T) => string;
    chooseText: ReactNode;
    dialogTitleText: ReactNode;
    onSelect: (items: T[]) => void;
    openButtonText?: ReactNode;
    items: T[];
    tableTitleText: ReactNode;
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

export function TableDialog<T extends Item>({
    addButtonText,
    calculateKey = (item: Item) => item.id,
    chooseText,
    dialogTitleText,
    items,
    onSelect,
    openButtonText = lang.layout.tableDialog.addMore,
    tableTitleText,
}: Props<T>) {
    const classes = useStyles();

    const headerConfig: TableConfig<T>[] = useMemo(() => [
        {
            name: 'name',
            title: tableTitleText,
        },
    ], [tableTitleText]);

    const [selectedItems, setSelectedItems] = useState<T[]>([]);

    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);
        setSelectedItems([]);
    }, [setOpen]);

    const handleSelect = useCallback(() => {
        onSelect(selectedItems);
        handleClose();
    }, [handleClose, onSelect, selectedItems]);

    return (
        <>
            <Button color="secondary" onClick={handleOpen} variant="outlined">
                <SvgIcon>
                    <AddIcon />
                </SvgIcon>
                {openButtonText}
            </Button>
            <Dialog
                aria-labelledby="add-more-dialog-title"
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <DialogTitle id="add-more-dialog-title">
                    {dialogTitleText}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {chooseText}
                    <Table<T>
                        calculateKey={calculateKey}
                        configs={headerConfig}
                        items={items}
                        loading={false}
                        onSelect={setSelectedItems}
                        selectedItems={selectedItems}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        {lang.layout.tableDialog.cancel}
                    </Button>
                    <Button
                        color="secondary"
                        disabled={selectedItems.length === 0}
                        onClick={handleSelect}
                        variant="contained"
                    >
                        {addButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
