export default {
    menu: {
        settings: 'Settings',
        logOut: 'Log Out',
    },
    profile: {
        generalInformation: 'General information',
        firstName: 'First Name',
        lastName: 'Last Name',
        companyName: 'Company Name',
        companyPhone: 'Company Phone',
        role: 'Role',
        save: 'Save',
        create: {
            title: 'Create Profile',
        },
        notification: {
            failedToCreate: 'Failed to create customer profile',
            profileUpdated: 'Profile updated',
            profileUpdateFailed: 'Failed to update profile',
        },
    },
    email: {
        emailAddressTitle: 'Email Address',
        yourEmailIs: 'Your email address is {email}',
    },
    language: 'Language',
}
