import React, {useCallback, useMemo, useState} from "react";
import {Button, Collapse, Step, StepLabel, Stepper} from "@material-ui/core";
import {DomainHistory, HistoryAction} from "../../graphql/graphql.types";
import {AddIcon} from "../history/AddIcon";
import {DropIcon} from "../history/DropIcon";
import {FoundAt} from "../history/FoundAt";
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';
import {lang} from "../../lang/lang";

type StepProps = {
    history: DomainHistory;
};

const StepperStep = ({history}: StepProps) => {
    return (
        <Step>
            <StepLabel icon={history.action === HistoryAction.ADDED ? <AddIcon fontSize="small" /> : <DropIcon fontSize="small" />}>
                {history.technologyName}
            </StepLabel>
            <FoundAt foundAt={history.foundAt} />
        </Step>
    );
};

type Props = {
    histories: DomainHistory[];
    showFirst?: number;
};

export const DomainHistoryStepper = ({histories, showFirst}: Props) => {
    const [expand, setExpand] = useState(false);
    const handleExpand = useCallback(() => setExpand(true), []);
    const [visibleEntries, hiddenEntries] = useMemo(() => {
        if (!showFirst) {
            return [histories, []];
        }

        return [histories.slice(0, showFirst), histories.slice(showFirst, histories.length)];
    }, [histories, showFirst]);

    return (
        <>
            <Stepper orientation='vertical'>
                {visibleEntries.map((history) => (
                    <StepperStep key={history.foundAt} history={history} />
                ))}
                <Collapse in={expand}>
                    {hiddenEntries.map((history) => (
                        <StepperStep key={history.foundAt} history={history} />
                    ))}
                </Collapse>
            </Stepper>
            {visibleEntries.length !== histories.length && !expand ? (
                <Button onClick={handleExpand}>
                    <ArrowDownIcon />
                    {lang.dashboard.main.expandHistory}
                </Button>
            ) : null}
        </>
    );
};
