import React, {MouseEvent, useCallback, useState} from 'react';
import {Popover} from '@material-ui/core';
import {CustomerAvatar} from '../CustomerAvatar';
import {CustomerMenu} from './CustomerMenu';
import {Customer} from '../../../graphql/graphql.types';

type Props = {
    customer: Customer | null;
    email: string | null;
    onLogout: () => void;
    onSettings: () => void;
};

export const CustomerMenuButton = ({customer, email, onLogout, onSettings}: Props) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const id = anchorElement ? 'header-menu' : undefined;

    const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    }, [setAnchorElement]);
    const handleClose = useCallback(() => setAnchorElement(null), [setAnchorElement]);

    const handleLogout = useCallback(() => {
        onLogout();
        handleClose();
    }, [handleClose, onLogout]);
    const handleSettings = useCallback(() => {
        onSettings();
        handleClose();
    }, [handleClose, onSettings]);

    return (
        <>
            <CustomerAvatar
                aria-describedby={id}
                customer={customer}
                onClick={handleOpen}
            />
            <Popover
                anchorEl={anchorElement}
                id={id}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <CustomerMenu
                    customer={customer}
                    email={email}
                    onLogout={handleLogout}
                    onSettings={handleSettings}
                />
            </Popover>
        </>
    );
};
