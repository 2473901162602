import {RouteConfig} from "../../router/RouteConfig";
import {lang} from "../../lang/lang";
import {lazy} from "../../shared/lazy";
import {defaultExport} from "../../shared/defaultExport";
import {UserRole} from "../../graphql/graphql.types";

export const dashboardRoutes = (): RouteConfig => ({
    path: 'dashboard',
    component: lazy(() => import('./DashboardPage').then(defaultExport('DashboardPage'))),
    title: lang.dashboard.title,
    roles: [UserRole.DASHBOARD],
    routes: [
        {
            path: 'setup',
            component: lazy(() => import('./setup/Setup').then(defaultExport('Setup'))),
            title: lang.dashboard.setup.title,
        },
        {
            path: '.',
            component: lazy(() => import('./Dashboard').then(defaultExport('Dashboard'))),
            title: lang.dashboard.title,
        },
    ],
});
