import React from 'react';
import {Form, Formik} from 'formik';
import {Avatar, Button, Container, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {signInValidationSchema} from './signInValidationSchema';
import {TextInputField} from '../../../components/formik/TextInputField';
import {SignInFormValues} from './types';
import {Link} from '../../../components/navigation/Link';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';

type Props = {
    onSubmit: (values: SignInFormValues) => Promise<void>;
};

const initialValues: SignInFormValues = {email: '', password: ''};

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    resetPasswordContainer: {
        margin: `${theme.spacing(1)}px 0`,
        textAlign: 'left',
        width: '100%',
    },
    signInButton: {
        marginTop: theme.spacing(1),
    },
}));

export const SignInForm = ({onSubmit}: Props) => {
    const classes = useStyles();

    return (
        <Formik<SignInFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={signInValidationSchema}
        >
            {({isSubmitting}) => (
                <Form>
                    <Container className={classes.container} component="main" maxWidth="xs">
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h4">
                            {lang.auth.signIn.title}
                        </Typography>
                        <TextInputField
                            autoComplete="email"
                            autoFocus
                            fullWidth
                            label={lang.auth.email}
                            margin="normal"
                            name="email"
                            type="email"
                        />
                        <TextInputField
                            fullWidth
                            label={lang.auth.password.label}
                            margin="normal"
                            name="password"
                            type="password"
                        />
                        <div className={classes.resetPasswordContainer}>
                            <Link to={Paths.RESET_PASSWORD_REQUEST}>{lang.auth.signIn.forgotPasswordLink}</Link>
                        </div>
                        <Button
                            className={classes.signInButton}
                            color="secondary"
                            disabled={isSubmitting}
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            {lang.auth.signIn.signInButton}
                        </Button>
                        <Typography color="textSecondary">{lang.auth.signIn.or}</Typography>
                        <Link to={Paths.SIGN_UP}>{lang.auth.signIn.signUpLink}</Link>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};
