import React, {useCallback, useEffect, useMemo, useState} from 'react';
import format from 'date-fns/format';
import {TargetingFilter} from '../../components/targetingFilter/TargetingFilter';
import {targetingFilters as targetingFiltersCreator} from './targetingFilters';
import {TargetingFilterState} from '../../components/targetingFilter/types';
import {useFiltersHydrator} from './useFiltersHydrator';
import {Order, TargetingSortType, TargetItem, useTargetingQuery} from '../../graphql/graphql.types';
import {PaginatedTable} from '../../components/table/PaginatedTable';
import {TargetingFilterDescription} from '../../components/targetingFilter/TargetingFilterDescription';
import {useSorting} from '../../components/useSorting';
import {TableConfig} from '../../components/table/types';
import {usePagination} from '../../components/usePagination';
import {DomainRenderer} from '../domain/list/DomainRenderer';
import {hasTechnologies} from './hasTechnologies';
import {TargetingTitle} from './TargetingTitle';
import {PageTitle} from '../../components/layout/PageTitle';
import {ApiConfig} from '../../config/api.config';
import {saveFile} from '../../shared/saveFile';
import {useRequest} from '../../shared/api/useRequest';
import {lang} from '../../lang/lang';

const DATE_FORMAT = 'dd-MM-yyyy';

const CALCULATE_TARGET_ITEM_KEY = (targetItem: TargetItem) => targetItem.domain;

const PER_PAGE = 50;

export const TargetingPage = () => {
    const targetingFilters = useMemo(targetingFiltersCreator, []);
    const initialValue = useMemo(() =>[
        {filter: targetingFilters[0], operator: 'ONE_OF', value: []} as TargetingFilterState
    ], []);

    const headerConfig: TableConfig<TargetItem>[] = useMemo(() => [
        {
            name: TargetingSortType.DOMAIN as string,
            sortable: true,
            title: lang.targeting.table.domain,
            render: DomainRenderer,
        },
        {
            align: 'right',
            name: TargetingSortType.COUNT_TECHS as string,
            sortable: true,
            title: lang.targeting.table.technologyCount,
        },
    ], []);

    const [filterValues, setFilterValues] = useState<TargetingFilterState[]>([]);
    const preparedFilterValues = useFiltersHydrator(filterValues);

    const pagination = usePagination(1, PER_PAGE);
    const {sorting, setField, setOrder} = useSorting(TargetingSortType.COUNT_TECHS, Order.DESC);

    const {data, fetchMore, loading} = useTargetingQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filters: preparedFilterValues,
            pagination: {page: 1, perPage: PER_PAGE},
            sorting: {
                field: sorting.field as TargetingSortType,
                by: sorting.order,
            },
        },
    });
    useEffect(() => {
        if (pagination.page === 1) {
            return;
        }

        fetchMore({
            updateQuery: (previousQueryResult, {fetchMoreResult}) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                return {
                    ...previousQueryResult,
                    targeting: {
                        ...previousQueryResult.targeting,
                        items: [
                            ...previousQueryResult.targeting.items,
                            ...fetchMoreResult.targeting.items,
                        ],
                    }
                };
            },
            variables: {
                pagination: {
                    page: pagination.page,
                    perPage: pagination.perPage,
                },
            },
        });
    }, [pagination.page, pagination.perPage]);

    const api = useRequest();
    const handleExport = useCallback(async () => {
        const blob = await api.post(`${ApiConfig.apiEndpoint}/targeting/download/csv`, {
            json: {
                filters: preparedFilterValues,
            },
        }).blob();

        const fileName = `targeting_${format(new Date(), DATE_FORMAT)}.csv`;
        saveFile(fileName, blob);
    }, [api, preparedFilterValues]);

    const targetingItems = data?.targeting?.items || [];
    const targetingItemsCount = data?.targeting?.totalCount || 0;

    return (
        <>
            <PageTitle>{lang.targeting.title}</PageTitle>
            <TargetingFilter
                canApply={hasTechnologies}
                filters={targetingFilters}
                initialValue={initialValue}
                onChange={setFilterValues}
            />
            <TargetingFilterDescription filterValues={filterValues} />
            <PaginatedTable<TargetItem>
                calculateKey={CALCULATE_TARGET_ITEM_KEY}
                configs={headerConfig}
                fetchMoreMode
                fetchedAll={targetingItems.length >= 1000}
                items={targetingItems}
                loading={loading}
                onField={setField}
                onOrder={setOrder}
                pagination={pagination}
                sortable
                sorting={sorting}
                title={(
                    <TargetingTitle
                        count={targetingItemsCount}
                        loading={loading}
                        onExport={handleExport}
                        showExport={hasTechnologies(filterValues)}
                    />
                )}
            />
        </>
    );
};
