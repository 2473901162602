import React from 'react';
import {Box, Divider, Typography} from '@material-ui/core';
import {plural} from '../../../../lang/plural';
import {lang} from '../../../../lang/lang';

type Props = {
    email: string;
};

export const CustomerEmail = ({email}: Props) => {
    if (!email) {
        return null;
    }

    return (
        <>
            <Box my={3}>
                <Divider />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">{lang.customer.email.emailAddressTitle}</Typography>
            </Box>
            {plural(lang.customer.email.yourEmailIs, {email})}
        </>
    );
};
