import React, {ChangeEvent, ReactNode, useCallback, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {
    Box,
    Grid,
    LinearProgress,
    Table as MuiTable,
    TableBody,
    TextField
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TableHeader} from './TableHeader';
import {TableConfig} from './types';
import {Order} from '../../graphql/graphql.types';
import {TableItem} from './TableItem';
import {lang} from '../../lang/lang';

type CommonProps<T> = {
    calculateKey: (item: T) => string;
    configs: TableConfig<T>[];
    headerFilter?: ReactNode;
    items: T[];
    loading: boolean;
    onFilter?: (filter: string) => void;
    title?: ReactNode;
};

type SelectProps<T> = {
    onSelect?: never;
    selectedItems?: never;
} | {
    onSelect: (items: T[]) => void;
    selectedItems: T[];
}

type SortProps = {
    onField?: never;
    onOrder?: never;
    sortable?: never;
    sorting?: never;
} | {
    onField: (field: string) => void;
    onOrder: (order: Order) => void;
    sortable: true;
    sorting: {field: string; order: Order};
};

export type TableProps<T> = CommonProps<T> & SelectProps<T> & SortProps;

const useStyles = makeStyles((theme) => ({
    progressBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    tableBox: {
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.grey['200']}`,
        '& > table > tbody > tr:last-child > td': {
            borderBottom: 'none',
        },
        position: 'relative',
        '& tr:hover td': {
            background: theme.palette.background.default,
        },
    },
}));

export function Table<T>({
    calculateKey,
    configs,
    headerFilter,
    items,
    loading,
    onFilter,
    onField = () => {},
    onOrder = () => {},
    onSelect,
    selectedItems,
    sorting = {field: '', order: Order.ASC},
    sortable,
    title
}: TableProps<T>) {
    const classes = useStyles();
    const [filter, setFilter] = useState('');
    const [handleDebouncedFilter] = useDebouncedCallback((filter) => onFilter?.(filter), 300);

    const handleFilterChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setFilter(value);
        handleDebouncedFilter(value);
    }, [setFilter]);

    const selectedItemsLength = selectedItems?.length || 0;
    const handleSelect = useCallback((item: T) => {
        if (!selectedItems || !onSelect) {
            return;
        }

        if (selectedItems.includes(item)) {
            onSelect(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            onSelect([...selectedItems, item]);
        }
    }, [items, onSelect, selectedItems]);
    const handleSelectAll = useCallback(() => {
        onSelect?.(selectedItems?.length === items.length ? [] : [...items]);
    }, [items, onSelect, selectedItems]);

    return (
        <>
            <Box mt={0.5} pb={0.5}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    {title ? <Grid item xs={12}>{title}</Grid> : null}
                    {headerFilter}
                    {onFilter && (
                        <Grid item>
                            <TextField
                                onChange={handleFilterChange}
                                value={filter}
                                label={lang.table.filter.label}
                                size="small"
                                variant="outlined"
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className={classes.tableBox}>
                {loading && <LinearProgress className={classes.progressBar} color="secondary" />}
                <MuiTable>
                    <TableHeader
                        allChecked={selectedItemsLength > 0 && items.length === selectedItemsLength}
                        configs={configs}
                        onField={onField}
                        onOrder={onOrder}
                        onSelectAll={onSelect ? handleSelectAll : undefined}
                        someChecked={selectedItemsLength > 0 && selectedItemsLength < items.length}
                        sorting={sorting}
                        sortable={sortable || false}
                    />
                    <TableBody>
                        {items.map((item) => (
                            <TableItem<T>
                                key={calculateKey(item)}
                                checked={Boolean(selectedItems?.includes(item))}
                                configs={configs}
                                item={item}
                                onSelect={onSelect ? handleSelect : undefined}
                            />
                        ))}
                    </TableBody>
                </MuiTable>
            </Box>
        </>
    );
};
