import * as yup from 'yup';
import {lang} from '../../../lang/lang';

export const signInValidationSchema = () => yup.object().shape({
    email: yup.string()
        .label(lang.auth.email)
        .required()
        .email(),
    password: yup.string()
        .label(lang.auth.password.label)
        .required(),
});
