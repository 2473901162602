import React, {useCallback, useContext, useMemo, useState} from 'react';
import clsx from 'clsx';
import {Divider, Drawer as MaterialDrawer, List} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg';
import {DRAWER_WIDTH} from '../constants';
import {DrawerItem} from './DrawerItem';
import {DrawerHide} from './DrawerHide';
import {Paths} from '../../../router/paths';
import {UserRole} from '../../../graphql/graphql.types';
import {useDrawerLocation} from './useDrawerLocation';
import {lang} from '../../../lang/lang';
import {RolesContext} from '../../../auth/RolesContext';

const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    list: {
        padding: theme.spacing(1),
    },
    mainList: {
        flex: 1,
        paddingTop: theme.spacing(2.5),
    },
    adminList: {
        padding: `0 ${theme.spacing(1)}px`,
    },
    bottomList: {
        padding: 0,
    },
    toolbarOffset: theme.mixins.toolbar,
}));

export const Drawer = () => {
    const {roles: userRoles} = useContext(RolesContext);

    const {drawerConfiguration, isDrawerLocation, lastDrawerLocation} = useDrawerLocation();
    const drawerClientItems = useMemo(() => {
        return drawerConfiguration.items.filter(({roles}) => {
            return roles.includes(UserRole.CLIENT) || roles.includes(UserRole.DASHBOARD) || roles.includes(UserRole.API);
        });
    }, [drawerConfiguration]);
    const drawerAdminAndSaleItems = useMemo(() => {
        return drawerConfiguration.items.filter(({roles}) => {
            return !roles.includes(UserRole.CLIENT) &&
                !roles.includes(UserRole.API) &&
                !roles.includes(UserRole.DASHBOARD) &&
                roles.every((role) => userRoles.includes(role));
        });
    }, [drawerConfiguration, userRoles]);

    const [open, setOpen] = useState(() => !Number(window.localStorage.drawerHidden));
    const handleDrawer = useCallback(() => {
        window.localStorage.drawerHidden = Number(open);
        setOpen(!open);
    }, [setOpen, open]);

    const classes = useStyles();
    const drawerClasses = clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
    });

    return (
        <MaterialDrawer
            className={clsx(classes.drawer, drawerClasses)}
            variant="permanent"
            classes={{paper: drawerClasses}}
        >
            <div className={classes.toolbarOffset} />
            <List className={clsx(classes.list, classes.mainList)}>
                {drawerConfiguration.showBackButton || !isDrawerLocation ? (
                    <DrawerItem icon={<ArrowLeftIcon />} opened={open} to={lastDrawerLocation || Paths.TARGETING}>
                        {lang.layout.drawer.goBack}
                    </DrawerItem>
                ) : null}
                {isDrawerLocation ? drawerClientItems.map((item) => (
                    <DrawerItem key={item.to} icon={item.icon} opened={open} to={item.to}>
                        {item.title}
                    </DrawerItem>
                )) : null}
            </List>
            {isDrawerLocation ? (
                <List className={classes.adminList}>
                    {drawerAdminAndSaleItems.map((item) => (
                        <DrawerItem key={item.to} icon={item.icon} opened={open} to={item.to}>
                            {item.title}
                        </DrawerItem>
                    ))}
                </List>
            ) : null}
            <Divider/>
            <List className={classes.bottomList}>
                <DrawerHide opened={open} onToggle={handleDrawer} />
            </List>
        </MaterialDrawer>
    );
};
