import admin from './translations/auth/ja-JP';
import auth from './translations/auth/ja-JP';
import customer from './translations/customer/ja-JP';
import domain from './translations/domain/ja-JP';
import layout from './translations/layout/ja-JP';
import feed from './translations/feed/ja-JP';
import settings from './translations/settings/ja-JP';
import table from './translations/table/ja-JP';
import technology from './translations/technology/ja-JP';
import targeting from './translations/targeting/ja-JP';
import technologySubscriptions from './translations/technologySubscriptions/ja-JP';

export default {
    admin,
    auth,
    customer,
    domain,
    layout,
    feed,
    settings,
    table,
    technology,
    targeting,
    technologySubscriptions,
};
