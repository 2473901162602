import {ChangePasswordForm, ChangePasswordFormValues} from './ChangePasswordForm';
import React, {useCallback} from 'react';
import {useChangePasswordMutation} from '../../../../graphql/graphql.types';
import {useSnackbar} from '../../../../components/snackbar/useSnackbar';
import {lang} from '../../../../lang/lang';

export const ChangePasswordContainer = () => {
    const {errorNotification, successNotification} = useSnackbar();
    const [changePassword, {loading}] = useChangePasswordMutation();
    const handleSubmit = useCallback(async (values: ChangePasswordFormValues) => {
        try {
            await changePassword({variables: values});

            successNotification(lang.auth.password.notification.changed);
        } catch {
            errorNotification(lang.auth.password.notification.changeFailed);
        }
    }, [changePassword, errorNotification, successNotification]);

    return (
        <ChangePasswordForm disabled={loading} onSubmit={handleSubmit} />
    );
};
