import React from 'react';
import {ListItem} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

type Props = {
    onToggle: () => void;
    opened: boolean;
};

const useStyles = makeStyles((theme) => ({
    listItem: {
        justifyContent: 'flex-end',
        height: 45,
        textAlign: 'right',
    },
    icon: {
        transition: theme.transitions.create('transform'),
    },
    iconOpened: {
        transform: 'rotate(180deg)',
    },
}));

export const DrawerHide = ({onToggle, opened}: Props) => {
    const classes = useStyles();

    return (
        <ListItem button dense className={classes.listItem} onClick={onToggle}>
            <ChevronRightIcon
                className={clsx(classes.icon, {
                    [classes.iconOpened]: opened
                })}
            />
        </ListItem>
    );
};
