import React, {ChangeEvent, ReactNode, useCallback} from 'react';
import {InputAdornment, TextField, TextFieldProps} from '@material-ui/core';
import {useDebouncedCallback} from 'use-debounce';
import SearchIcon from '../../assets/icons/search.svg';

type Props = Omit<TextFieldProps, 'label' | 'onChange'> & {
    debounceTime?: number;
    placeholder: ReactNode;
    onSearch: (search: string) => void;
};

export const SearchField = ({debounceTime = 0, onSearch, variant, ...props}: Props) => {
    const [handleDebouncedSearch] = useDebouncedCallback(onSearch, debounceTime);
    const handleChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        handleDebouncedSearch(value);
    }, [handleDebouncedSearch]);

    return (
        <TextField
            {...props}
            onChange={handleChange}
            InputProps={{
                ...props.InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            variant={variant}
        />
    );
};
