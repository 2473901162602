import {Divider, Grid, styled} from "@material-ui/core";
import {DomainHistoryStepper} from "../../../components/domain/DomainHistoryStepper";
import React from "react";
import {Category, DashboardDomainHistoryItem, Technology} from "../../../graphql/graphql.types";
import {DashboardItemTechnology} from "./DashboardItemTechnology";
import {lang} from "../../../lang/lang";

const TechnologiesCount = styled('strong')(({theme}) => ({
    color: theme.palette.success.dark,
}));

type Props = {
    history: DashboardDomainHistoryItem;
    technologies: Map<string, Technology[]>
};

const EMPTY_CATEGORY: Category = {
    id: '-',
    name: '-',
};

export const DashboardItemHistory = ({history, technologies}: Props) => {
    return (
        <Grid container>
            <Grid item xs={4}>
                <strong>{history.category?.name ?? '-'}</strong>
                <br />
                {lang.dashboard.main.technologiesInUse}{' '}
                <TechnologiesCount>{technologies.get(history.category?.id ?? EMPTY_CATEGORY.name)?.length ?? 0}</TechnologiesCount>
            </Grid>
            <Grid item xs={8}>
                {technologies.get(history.category?.id ?? EMPTY_CATEGORY.id)?.length ? (
                    <>
                        <DashboardItemTechnology
                            category={history.category ?? EMPTY_CATEGORY}
                            technologies={technologies.get(history.category?.id ?? EMPTY_CATEGORY.name) ?? []}
                        />
                        <Divider />
                    </>
                ) : null}
                <DomainHistoryStepper
                    histories={history.history}
                    showFirst={3}
                />
            </Grid>
        </Grid>
    );
};
