import React, {useMemo} from 'react';
import format from 'date-fns/format';
import {
    SingleTargetingFilterState,
    TargetingFilterState,
    TargetingFilterVariant,
    TargetingFilterOperator,
    SingleTargetingFilterValue, GroupTargetingFilterState
} from './types';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {lang} from '../../lang/lang';
import {plural} from '../../lang/plural';

type Props = {
    filterValues: TargetingFilterState[];
};

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.primary.light,
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const DATE_FORMAT = 'dd.MM.yyyy';

const mapValues = (value: SingleTargetingFilterValue) => {
    if (Array.isArray(value)) {
        return value.map((suggestion) => suggestion.title).join(', ');
    }

    if (Array.isArray(value.value) && value.value[0] && value.value[1]) {
        return plural(
            lang.targeting.filter.operator.betweenTwoValues,
            {
                first: format(new Date(value.value[0]), DATE_FORMAT),
                second: format(new Date(value.value[1]), DATE_FORMAT),
            },
        );
    }

    return value.value;
};

const mapDescription = (filterValue: SingleTargetingFilterState) => {
    const OPERATORS_MAP: {[key in TargetingFilterOperator]: string} = {
        '=': lang.targeting.filter.operator.equals,
        '!=': lang.targeting.filter.operator.notEquals,
        '>': lang.targeting.filter.operator.greaterThen,
        '>=': lang.targeting.filter.operator.greaterThenOrEquals,
        '<': lang.targeting.filter.operator.lesserThen,
        '<=': lang.targeting.filter.operator.lesserThenOrEquals,
        'ONE_OF': lang.targeting.filter.operator.oneOf,
        'ALL_OF': lang.targeting.filter.operator.allOf,
        'BETWEEN': lang.targeting.filter.operator.between,
        'EXCLUDE_ALL': lang.targeting.filter.operator.excludeAll,
    };

    const value = mapValues(filterValue.value);

    return value ? `${filterValue.filter.title} ${OPERATORS_MAP[filterValue.operator]} ${value}` : '';
};

export const TargetingFilterDescription = ({filterValues}: Props) => {
    const classes = useStyles();

    const descriptions = useMemo(() => {
        return filterValues.map((filterValue) => {
            if (filterValue.filter.type === TargetingFilterVariant.GROUP) {
                return [
                    `${filterValue.filter.title}: `,
                    (filterValue as GroupTargetingFilterState).state.map(mapDescription).filter(Boolean).join(', '),
                ].join(' ');
            }

            return mapDescription(filterValue as SingleTargetingFilterState);
        }).filter(Boolean);
    }, [filterValues]);

    return descriptions.length ? (
        <Paper className={classes.paper}>
            {lang.targeting.filter.searchingFor}
            <ul>
                {descriptions.map((description, index) => (
                    <li key={index}>{description}</li>
                ))}
            </ul>
        </Paper>
    ) : null;
};
