import React, {PropsWithChildren, useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';
import {ErrorHandler} from '@apollo/client/link/error';
import {createApolloClient} from './apollo';
import {GraphQLEvent} from './GraphQLEvent';
import {GraphQLErrorMessages} from './GraphQLErrorMessages';
import {AuthContext} from '../auth/AuthContext';
import {useSnackbar} from '../components/snackbar/useSnackbar';
import {Paths} from '../router/paths';

export const GraphQLProvider = ({children}: PropsWithChildren<{}>) => {
    const {onResetToken} = useContext(AuthContext);
    const history = useHistory();
    const {errorNotification} = useSnackbar();
    const dispatch = useCallback((graphQLEvent: GraphQLEvent, payload: unknown) => {
        switch (graphQLEvent) {
            case GraphQLEvent.ERROR:
                errorNotification(payload as string);
                break;
            case GraphQLEvent.REDIRECT:
                history.push(payload as string);
                break;
        }
    }, [history]);

    const errorHandler: ErrorHandler = useCallback(({graphQLErrors}) => {
        graphQLErrors?.forEach((error) => {
            const code = ('code' in error ? error['code'] : null) || error.extensions?.code as string;

            if (code === 'INVALID_TOKEN' || code === 'UNAUTHENTICATED') {
                onResetToken();
                dispatch(GraphQLEvent.REDIRECT, Paths.SIGN_IN);
            } else if (code === 'IDENTITY_PENDING_STATUS') {
                dispatch(GraphQLEvent.REDIRECT, Paths.ERROR_NOT_ACTIVATED);
            } else if (code === 'CUSTOMER_DATA_NOT_FILLED') {
                dispatch(GraphQLEvent.REDIRECT, Paths.PROFILE_CREATE);
            } else if (code && GraphQLErrorMessages[code as keyof typeof GraphQLErrorMessages]) {
                dispatch(GraphQLEvent.ERROR, GraphQLErrorMessages[code as keyof typeof GraphQLErrorMessages]);
            }
        });
    }, [dispatch, onResetToken]);

    const client = useMemo(() => createApolloClient(errorHandler), [errorHandler]);

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
};
