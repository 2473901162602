import React, {PropsWithChildren, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../auth/AuthContext';
import {Paths} from './paths';

type Props = {
    currentPath: string;
    path: string;
};

export const PublicRoute = ({children, currentPath, path}: PropsWithChildren<Props>) => {
    const {loaded, token} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (currentPath === path && loaded && token) {
            history.push(Paths.ROOT);
        }
    }, [history, loaded, token]);

    return <>{children}</>;
};
