import React, {PropsWithChildren, useContext} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import TecplusBigLogo from '../../assets/img/tecplus-big.png';
import {AuthContext} from "../../auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    background: {
        height: '100vh',
        width: '100wh',
        background: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    loader: {
        marginTop: theme.spacing(2),
    },
}));

export const LoaderProvider = ({children}: PropsWithChildren<{}>) => {
    const classes = useStyles();
    const {loaded} = useContext(AuthContext);

    return loaded ? (
        <>{children}</>
    ) : (
        <div className={classes.background}>
            <div className={classes.container}>
                <img src={TecplusBigLogo} alt="Tecplus" />
                <CircularProgress color="primary" className={classes.loader} />
            </div>
        </div>
    );
};
