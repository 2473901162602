import React, {ChangeEvent, useCallback, useMemo} from 'react';
import {Box, IconButton, MenuItem, Select} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';
import {makeStyles} from '@material-ui/core/styles';
import {lang} from '../../lang/lang';
import {plural} from '../../lang/plural';

type Props = {
    onChangePage: (page: number) => void;
    onChangePerPage: (perPage: number) => void;
    page: number;
    pages: number;
    perPage: number;
    rowsPerPageVariants: number[];
    total: number;
};

const useStyles = makeStyles((theme) => ({
    pagination: {
        backgroundColor: '#FFF',
        border: `1px solid ${theme.palette.primary.light}`,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        padding: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
    },
    resultsPerPageContainer: {
        flex: 1,
        textAlign: 'center',
    },
    resultsPerPage: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 72,
    },
    resultsPerPageOutlined: {
        padding: theme.spacing(1.2),
    },
}));

export const Paginator = ({onChangePage, onChangePerPage, page, pages, perPage, rowsPerPageVariants, total}: Props) => {
    const classes = useStyles();
    const handleFirstPage = useCallback(() => {
        onChangePage(1);
    }, [onChangePage]);
    const handlePrevPage = useCallback(() => {
        onChangePage(Math.max(1, page - 1));
    }, [onChangePage, page]);
    const handleNextPage = useCallback(() => {
        onChangePage(Math.min(pages, page + 1));
    }, [onChangePage, page, pages]);
    const handleLastPage = useCallback(() => {
        onChangePage(pages);
    }, [onChangePage, pages]);
    const handleChangePerPage = useCallback(({target: {value}}: ChangeEvent<{value: unknown}>) => {
        onChangePerPage(Number(value as string));
    }, [onChangePerPage]);

    const firstItem = useMemo(() => (page - 1) * perPage + 1, [page, perPage]);
    const lastItem = useMemo(() => {
        return page * perPage > total ? total : page * perPage;
    }, [page, perPage, total]);

    return (
        <Box className={classes.pagination}>
            <IconButton
                aria-label={lang.table.pagination.firstPage}
                color="inherit"
                disabled={page === 1}
                onClick={handleFirstPage}
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                aria-label={lang.table.pagination.previousPage}
                color="inherit"
                disabled={page === 1}
                onClick={handlePrevPage}
            >
                <PrevPageIcon />
            </IconButton>
            <IconButton
                aria-label={lang.table.pagination.nextPage}
                color="inherit"
                disabled={page === pages}
                onClick={handleNextPage}
            >
                <NextPageIcon />
            </IconButton>
            <IconButton
                aria-label={lang.table.pagination.lastPage}
                color="inherit"
                disabled={page === pages}
                onClick={handleLastPage}
            >
                <LastPageIcon />
            </IconButton>
            <div className={classes.resultsPerPageContainer}>
                {lang.table.pagination.resultsPerPage}
                <Select
                    className={classes.resultsPerPage}
                    classes={{outlined: classes.resultsPerPageOutlined}}
                    onChange={handleChangePerPage}
                    variant="outlined"
                    value={perPage}
                >
                    {rowsPerPageVariants.map((rowsPerPageVariant) => (
                        <MenuItem key={rowsPerPageVariant} value={rowsPerPageVariant}>{rowsPerPageVariant}</MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                {plural(lang.table.pagination.results, {first: firstItem, last: lastItem, total})}
            </div>
        </Box>
    );
};
