export default {
    details: {
        technology: 'Technology {name}',
        title: 'Technology',
        domainsTab: 'Domains',
        historyTab: 'History',
        domains: {
            title: 'Technology domains',
        },
        history: {
            title: 'Technology history',
        },
    },
    list: {
        title: 'Technologies',
        categories: 'Categories',
        name: 'Name',
    },
    table: {
        technology: 'Technology',
        category: 'Category',
        domainsCount: 'Domains Count',
        history: 'History',
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
    },
};
