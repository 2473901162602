import React, {MouseEvent, useMemo} from 'react';
import {Avatar, AvatarProps, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Customer} from '../../graphql/graphql.types';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: '#BB3619',
    },
    avatarSmall: {
        width: 40,
        height: 40,
        fontSize: '1rem',
    },
    avatarLarge: {
        width: 74,
        height: 74,
        fontSize: '2.85rem',
    },
});

type Props = {
    customer: Customer | null;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    size?: 'small' | 'large';
} & Pick<AvatarProps, 'variant'>;

export const CustomerAvatar = ({customer, onClick, size = 'small', variant}: Props) => {
    const classes = useStyles();
    const isLarge = size === 'large';
    const initials = useMemo(() => {
        if (!customer) {
            return '';
        }

        return `${customer.contactInfo.name.firstName[0]}${customer.contactInfo.name.lastName[0]}`;
    }, [customer]);

    const avatar = (
        <Avatar
            className={clsx(classes.avatar, {
                [classes.avatarSmall]: !isLarge,
                [classes.avatarLarge]: isLarge,
            })}
            variant={variant}
        >
            {initials}
        </Avatar>
    );

    return onClick ? (
        <IconButton onClick={onClick}>
            {avatar}
        </IconButton>
    ) : avatar;
};
