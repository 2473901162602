import admin from './translations/admin/en-US';
import api from './translations/api/en-US';
import auth from './translations/auth/en-US';
import customer from './translations/customer/en-US';
import dashboard from './translations/dashboard/en-US';
import domain from './translations/domain/en-US';
import feed from './translations/feed/en-US';
import layout from './translations/layout/en-US';
import settings from './translations/settings/en-US';
import table from './translations/table/en-US';
import targeting from './translations/targeting/en-US';
import technology from './translations/technology/en-US';
import technologySubscriptions from './translations/technologySubscriptions/en-US';

export default {
    admin,
    api,
    auth,
    customer,
    dashboard,
    domain,
    layout,
    feed,
    settings,
    table,
    technology,
    targeting,
    technologySubscriptions,
};
