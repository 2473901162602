import {RouteConfig} from '../../router/RouteConfig';
import {RouteChildren} from '../../router/RouteChildren';
import {lang} from '../../lang/lang';
import {userRoutes} from './user/user.routes';
import {UserRole} from '../../graphql/graphql.types';

export const adminRoutes = (): RouteConfig => ({
    path: 'admin',
    component: RouteChildren,
    title: lang.admin.title,
    roles: [UserRole.ADMIN],
    routes: [
        userRoutes(),
    ],
});
