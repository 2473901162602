import React, {ChangeEvent, useCallback, useContext} from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import {LocaleContext} from '../context/LocaleContext';

type Props = Omit<SelectProps, 'onChange' | 'value'>;

export const LocaleDropdown = (props: Props) => {
    const {changeLocale, locale} = useContext(LocaleContext);

    const handleChange = useCallback((e: ChangeEvent<{value: unknown}>) => {
        changeLocale(e.target.value as string);
    }, [changeLocale]);

    return (
        <FormControl fullWidth={props.fullWidth} variant={props.variant}>
            {props.label ? (
                <InputLabel id="locale-dropdown-label">{props.label}</InputLabel>
            ) : null}
            <Select
                {...props}
                id="locale-dropdown"
                labelId="locale-dropdown-label"
                onChange={handleChange}
                value={locale}
            >
                <MenuItem value="en-US">English</MenuItem>
                <MenuItem value="ja-JP">日本語</MenuItem>
            </Select>
        </FormControl>
    );
};
