import React, {useMemo} from 'react';

import {PaginatedTable} from '../../../components/table/PaginatedTable';
import {DomainActionsRenderer} from './DomainActionsRenderer';
import {Domain} from '../../../graphql/graphql.types';
import {Pagination} from '../../../components/usePagination';
import {TableConfig} from '../../../components/table/types';
import {CountryRenderer} from '../../../components/table/renderers/CountryRenderer';
import {DomainRenderer} from './DomainRenderer';
import {lang} from '../../../lang/lang';

type Props = {
    domains: Domain[];
    loading: boolean;
    onFilter?: (nameFilter: string) => void;
    pagination: Pagination;
};

const CALCULATE_DOMAIN_KEY = (domain: Domain) => domain.domain;

export const DomainsTable = ({domains, loading, onFilter, pagination}: Props) => {
    const headerConfig: TableConfig<Domain>[] = useMemo(() => [
        {
            name: 'domain',
            title: lang.domain.table.domain,
            render: DomainRenderer,
        },
        {
            name: 'country',
            title: lang.domain.table.country,
            render: CountryRenderer<Domain>((item) => item.country),
        },
        {
            name: 'technologiesCount',
            title: lang.domain.table.technologyCount,
        },
        {
            name: 'actions',
            title: '',
            render: DomainActionsRenderer,
        },
    ], []);

    return (
        <PaginatedTable<Domain>
            calculateKey={CALCULATE_DOMAIN_KEY}
            configs={headerConfig}
            items={domains}
            loading={loading}
            onFilter={onFilter}
            pagination={pagination}
        />
    );
};
