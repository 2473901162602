import React, {useMemo} from 'react';
import {Technology} from '../../../graphql/graphql.types';
import {Table} from '../../../components/table/Table';
import {TableConfig} from '../../../components/table/types';
import {Link} from '../../../components/navigation/Link';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';

type Props = {
    editMode: boolean;
    loading: boolean;
    onSelect: (technologies: Technology[]) => void;
    selectedTechnologies: Technology[];
    technologyUuids: string[];
    technologies: Technology[];
};

const CALCULATE_TECHNOLOGY_KEY = (technology: Technology) => technology.id;

export const TechnologySubscriptionsTable = ({
    editMode,
    loading,
    onSelect,
    selectedTechnologies,
    technologyUuids,
    technologies,
}: Props) => {
    const headerConfig: TableConfig<Technology>[] = useMemo(() => [
        {
            name: 'name',
            title: lang.technologySubscriptions.table.technology,
            render: ({item}) => <Link to={[Paths.TECHNOLOGY_DOMAINS, {technologyId: item.id}]}>{item.name}</Link>
        },
    ], []);

    const subscribedTechnologies = useMemo(() => {
        return technologies.filter((technology) => technologyUuids.includes(technology.id)) || [];
    }, [technologies, technologyUuids]);

    return (
        <Table<Technology>
            calculateKey={CALCULATE_TECHNOLOGY_KEY}
            configs={headerConfig}
            items={subscribedTechnologies}
            loading={loading}
            {...(editMode ? {onSelect, selectedItems: selectedTechnologies} : {})}
        />
    )
};
