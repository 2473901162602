import React, {memo} from 'react';
import {generatePath, Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import {Link as MaterialLink, LinkProps as MaterialLinkProps} from '@material-ui/core';
import {Paths} from '../../router/paths';

type Props = MaterialLinkProps & Omit<RouterLinkProps, 'to'> & {
    to: Paths | [Paths, {[key: string]: string}];
};

export const Link = memo(({children, to, ...rest}: Props) => {
    const path = Array.isArray(to) ? generatePath(to[0], to[1]) : to;

    return <MaterialLink component={RouterLink} to={path} {...rest}>{children}</MaterialLink>
});
