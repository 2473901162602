import React, {useCallback, useContext} from 'react';
import {AuthContext} from '../../../auth/AuthContext';
import {SignInForm} from './SignInForm';
import {useSignInMutation} from '../../../graphql/graphql.types';
import {SignInFormValues} from './types';

export const SignInPage = () => {
    const {onSetToken} = useContext(AuthContext);
    const [signIn] = useSignInMutation();
    const handleSubmit = useCallback(async (values: SignInFormValues) => {
        const {data} = await signIn({variables: values});

        onSetToken(data?.auth.signIn.token || '');
    }, [onSetToken, signIn]);

    return (
        <SignInForm onSubmit={handleSubmit} />
    );
};
