import React, {useCallback, useEffect, useMemo} from 'react';
import {PaginatedTable} from '../../../components/table/PaginatedTable';
import {PageTitle} from '../../../components/layout/PageTitle';
import {lang} from '../../../lang/lang';
import {usePagination} from '../../../components/usePagination';
import {TableConfig} from '../../../components/table/types';
import {formatStatus} from './formatters';
import {UsersListHeader} from './UsersListHeader';
import {User, useUsersQuery} from "../../../graphql/graphql.types";

export const UsersPage = () => {
    const headerConfig: TableConfig<User>[] = useMemo(() => [
        {
            name: 'firstName',
            title: lang.admin.users.list.table.firstName,
        },
        {
            name: 'lastName',
            title: lang.admin.users.list.table.lastName,
        },
        {
            name: 'email',
            title: lang.admin.users.list.table.email,
        },
        {
            name: 'status',
            title: lang.admin.users.list.table.status,
            render: ({item}) => (<>{formatStatus(item.status)}</>),
        },
    ], []);

    const pagination = usePagination(1, 10);

    const {data, fetchMore, loading} = useUsersQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            pagination: {
                page: 1,
                perPage: 10,
            },
        },
    });
    useEffect(() => {
        if (pagination.page === 1) {
            return;
        }

        fetchMore({
            variables: {
                pagination: {
                    page: pagination.page,
                    perPage: 10,
                },
            },
            updateQuery: (previousQueryResult, {fetchMoreResult}) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                return {
                    ...previousQueryResult,
                    ...fetchMoreResult,
                    users: {
                        ...previousQueryResult.users,
                        ...fetchMoreResult.users,
                        items: [
                            ...previousQueryResult.users.items,
                            ...fetchMoreResult.users.items,
                        ],
                    },
                };
            },
        })
    }, [pagination.page]);
    const users = data?.users?.items || [];
    const handleCalculateKey = useCallback((user: User) => user.id, []);

    return (
        <>
            <PageTitle>{lang.admin.users.title}</PageTitle>
            <UsersListHeader onSearch={() => {}} />
            <PaginatedTable<User>
                calculateKey={handleCalculateKey}
                configs={headerConfig}
                fetchedAll={!data?.users?.pagination?.hasNextPage || false}
                fetchMoreMode
                items={users}
                loading={loading}
                pagination={pagination}
            />
        </>
    );
};
