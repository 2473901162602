import React from 'react';
import {Location} from 'history';
import PersonIcon from '@material-ui/icons/Person';
import ApiIcon from '@material-ui/icons/SettingsRemote';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';
import DashboardIcon from '../../../assets/icons/dashboard.svg';
import TargetingIcon from '../../../assets/icons/targeting.svg';
import DomainsIcon from '../../../assets/icons/domains.svg';
import TechnologiesIcon from '../../../assets/icons/technologies.svg';
import TechnologyAddedIcon from '../../../assets/icons/technology-added.svg';
import UsersIcon from '../../../assets/icons/users.svg';
import {UserRole} from '../../../graphql/graphql.types';
import {DrawerConfiguration} from './types';

export const createDrawerItems = (location: Location): DrawerConfiguration => {
    if (location.pathname.startsWith('/settings')) {
        return {
            items: [
                {
                    title: lang.layout.drawer.settings.profile,
                    to: Paths.SETTINGS_PROFILE,
                    icon: <PersonIcon />,
                    roles: [UserRole.CLIENT],
                },
                {
                    title: lang.layout.drawer.settings.technologySubscriptions,
                    to: Paths.SETTINGS_TECHNOLOGY_SUBSCRIPTIONS,
                    icon: <TechnologyAddedIcon />,
                    roles: [UserRole.CLIENT],
                },
                {
                    title: lang.layout.drawer.settings.api,
                    to: Paths.SETTINGS_API,
                    icon: <ApiIcon />,
                    roles: [UserRole.API],
                },
            ],
            showBackButton: true,
        };
    }

    return {
        items: [
            {
                title: lang.layout.drawer.dashboard,
                to: Paths.DASHBOARD,
                icon: <DashboardIcon />,
                roles: [UserRole.DASHBOARD],
            },
            {
                title: lang.layout.drawer.targeting,
                to: Paths.TARGETING,
                icon: <TargetingIcon />,
                roles: [UserRole.CLIENT],
            },
            {
                title: lang.layout.drawer.domains,
                to: Paths.DOMAINS,
                icon: <DomainsIcon />,
                roles: [UserRole.CLIENT],
            },
            {
                title: lang.layout.drawer.technologies,
                to: Paths.TECHNOLOGIES,
                icon: <TechnologiesIcon />,
                roles: [UserRole.CLIENT],
            },
            {
                title: lang.layout.drawer.users,
                to: Paths.USERS,
                icon: <UsersIcon />,
                roles: [UserRole.ADMIN],
            },
        ],
        showBackButton: false,
    };
};
