export default {
    title: 'Settings',
    api: {
        title: 'API',
        tokensTitle: 'API Tokens',
        playgroundTitle: 'Playground',
    },
    profileDetails: {
        title: 'Profile details',
    },
    myTechnologies: {
        title: 'My technologies',
    },
};
