import React from 'react';
import {TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Order} from '../../graphql/graphql.types';
import {TableConfig} from './types';
import {TableHeaderItem} from './TableHeaderItem';
import {TableCheckbox} from './TableCheckbox';

type Props<T> = {
    allChecked?: boolean;
    configs: TableConfig<T>[];
    onField: (field: string) => void;
    onOrder: (order: Order) => void;
    onSelectAll?: () => void;
    someChecked?: boolean;
    sortable: boolean;
    sorting: {field: string; order: Order};
};

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.grey['200'],
        '& > tr > th': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

export function TableHeader<T>({
    allChecked = false,
    configs,
    onOrder,
    onField,
    onSelectAll,
    someChecked = false,
    sortable,
    sorting,
}: Props<T>) {
    const classes = useStyles();

    return (
        <TableHead className={classes.header}>
            <TableRow>
                {onSelectAll ? (
                    <TableCheckbox
                        checked={allChecked}
                        indeterminate={someChecked}
                        onSelect={onSelectAll}
                    />
                ) : null}
                {configs.map((config) => (
                    <TableHeaderItem
                        key={config.name}
                        config={config}
                        onOrder={onOrder}
                        onField={onField}
                        sortable={sortable}
                        sorting={sorting}
                    />
                ))}
            </TableRow>
        </TableHead>
    )
};
