import {Category, Technology} from "../../../graphql/graphql.types";
import React, {useCallback, useMemo, useState} from "react";
import {Box, Button, Collapse, Grid, styled} from "@material-ui/core";
import {lang} from "../../../lang/lang";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import TechnologyIcon from "../../../assets/icons/technology.svg";

const DashboardTechnologyIcon = styled(TechnologyIcon)(() => ({
    verticalAlign: 'middle',
}));

type Props = {
    category: Category;
    technologies: Technology[];
};

export const DashboardItemTechnology = ({category, technologies}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpand = useCallback(() => setExpanded(true), []);
    const [visibleTechnologyPairs, hiddenTechnologyPairs] = useMemo(() => {
        const technologyPairs: [Technology, Technology][] = [];

        for (let i = 0; i < technologies.length; i += 2) {
            technologyPairs.push([technologies[i], technologies[i + 1]]);
        }

        return [technologyPairs.slice(0, 3), technologyPairs.slice(3, technologyPairs.length)];
    }, [technologies]);

    return (
        <Box p={1}>
            {visibleTechnologyPairs.map(([first, second]) => (
                <Grid key={`${first.id}${second?.id ?? ''}`} container>
                    <Grid item xs={6}>
                        <DashboardTechnologyIcon />
                        {' '}{first.name}
                    </Grid>
                    {second?.name && (
                        <Grid item xs={6}>
                            <DashboardTechnologyIcon />
                            {' '}{second.name}
                        </Grid>
                    )}
                </Grid>
            ))}
            <Collapse in={expanded}>
                {hiddenTechnologyPairs.map(([first, second]) => (
                    <Grid key={`${first.id}${second?.id ?? ''}`} container>
                        <Grid item xs={6}>
                            <DashboardTechnologyIcon />
                            {' '}{first.name}
                        </Grid>
                        {second?.name && (
                            <Grid item xs={6}>
                                <DashboardTechnologyIcon />
                                {' '}{second.name}
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Collapse>
            {hiddenTechnologyPairs.length > 0 && !expanded ? (
                <Button onClick={handleExpand}>
                    <ArrowDownIcon />
                    {lang.dashboard.main.expandTechnologies}
                </Button>
            ) : null}
        </Box>
    );
};
