import React, {PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {LocaleContext} from './LocaleContext';
import {langLoader} from '../langLoader';

export const LocaleProvider = ({children}: PropsWithChildren<{}>) => {
    const [locale, setLocale] = useState(localStorage.locale || 'en-US');

    const changeLocale = useCallback(async (nextLocale: string) => {
        if (nextLocale === locale) {
            return;
        }

        await langLoader(nextLocale);
        setLocale(nextLocale);
        localStorage.locale = nextLocale;
    }, [locale]);

    const value = useMemo(() => ({
        changeLocale,
        locale,
    }), [changeLocale, locale]);

    return (
        <LocaleContext.Provider key={locale} value={value}>
            {children}
        </LocaleContext.Provider>
    );
};
