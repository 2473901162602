import React from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress, Paper} from '@material-ui/core';
import {useDomainHistoryQuery} from '../../../graphql/graphql.types';
import {DomainHistoryStepper} from "../../../components/domain/DomainHistoryStepper";

export const DomainHistory = () => {
    const {domain = ''} = useParams();
    const {data, loading} = useDomainHistoryQuery({
        variables: {domain},
    });

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Paper>
            <DomainHistoryStepper histories={data?.domainHistory.history ?? []} />
        </Paper>
    );
};
