import {RouteConfig} from '../../router/RouteConfig';
import {RouteChildren} from '../../router/RouteChildren';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {lang} from '../../lang/lang';

export const domainRoutes = (): RouteConfig => ({
    path: 'domains',
    component: RouteChildren,
    title: lang.domain.title,
    routes: [
        {
            path: ':domain',
            component: lazy(() => import('./details/DomainLayout').then(defaultExport('DomainLayout'))),
            title: lang.domain.details.title,
            routes: [
                {
                    path: 'technologies',
                    component: lazy(() => import('./details/TechnologiesOfDomain').then(defaultExport('TechnologiesOfDomain'))),
                    title: lang.domain.details.domainTechnologies,
                },
                {
                    path: 'history',
                    component: lazy(() => import('./details/DomainHistory').then(defaultExport('DomainHistory'))),
                    title: lang.domain.details.domainHistory,
                },
            ],
        },
        {
            path: '.',
            component: lazy(() => import('./list/Domains').then(defaultExport('Domains'))),
            title: lang.domain.title,
        },
    ],
});
