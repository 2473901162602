import {RouteConfig} from '../../router/RouteConfig';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {AuthLayout} from '../../components/layout/AuthLayout';
import {lang} from '../../lang/lang';

export const customerProfileRoutes = (): RouteConfig => ({
    path: 'profile',
    component: AuthLayout,
    title: lang.customer.profile.create.title,
    routes: [
        {
            path: 'create',
            component: lazy(() => import('./CreateCustomerProfilePage').then(defaultExport('CreateCustomerProfilePage'))),
            title: lang.customer.profile.create.title,
        },
    ],
});
