import React, {useCallback} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import * as yup from 'yup';
import {Box, Button, Grid} from '@material-ui/core';
import {TextInputField} from '../../../../components/formik/TextInputField';
import {lang} from '../../../../lang/lang';

export type ChangePasswordFormValues = {
    oldPassword: string;
    newPassword: string;
};

type Props = {
    disabled: boolean;
    onSubmit: (values: ChangePasswordFormValues) => Promise<void>;
};

const INITIAL_VALUES: ChangePasswordFormValues = {
    oldPassword: '',
    newPassword: '',
};

const VALIDATION_SCHEMA = () => yup.object().shape({
    oldPassword: yup.string().label(lang.auth.password.current),
    newPassword: yup.string().min(6).max(128).label(lang.auth.password.new),
});

export const ChangePasswordForm = ({disabled, onSubmit}: Props) => {
    const handleSubmit = useCallback(async (values: ChangePasswordFormValues, helpers: FormikHelpers<ChangePasswordFormValues>) => {
        await onSubmit(values);
        helpers.resetForm();
    }, [onSubmit]);

    return (
        <Formik<ChangePasswordFormValues>
            initialValues={INITIAL_VALUES}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
        >
            {({dirty, isValid}) => (
                <Form>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <TextInputField
                                fullWidth
                                label={lang.auth.password.current}
                                name="oldPassword"
                                type="password"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextInputField
                                fullWidth
                                label={lang.auth.password.new}
                                name="newPassword"
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Box mb={3} mt={4}>
                        <Button
                            color="secondary"
                            disabled={disabled || !dirty || !isValid}
                            type="submit"
                            variant="contained"
                        >
                            {lang.auth.password.save}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
