export default {
    title: 'Domains',
    details: {
        title: 'Domain',
        domainTechnologies: 'Domain Technologies',
        domainHistory: 'Domain History',
    },
    layout: {
        technologiesTab: 'Technologies',
        historyTab: 'History',
    },
    link: {
        openInNewTab: 'Open in New Tab',
    },
    table: {
        domain: 'Domain',
        country: 'Country',
        technologyCount: 'Technology Count',
    },
};
