import React from 'react';
import {Form, Formik} from 'formik';
import {Button, Grid} from '@material-ui/core';
import {customerValidationSchema} from '../../pages/customerProfile/customerValidationSchema';
import {lang} from '../../lang/lang';
import {CustomerProfileFormFields} from './CustomerProfileFormFields';

export type CustomerProfileFormValues = {
    contactInfo: {
        firstName: string;
        lastName: string;
        countryIso: string;
    };
    employmentInfo: {
        companyName: string;
        companyPhone: string;
        role: string;
    };
};

const INITIAL_VALUES: CustomerProfileFormValues = {
    contactInfo: {
        firstName: '',
        lastName: '',
        countryIso: 'JP',
    },
    employmentInfo: {
        companyName: '',
        companyPhone: '',
        role: '',
    },
};

type Props = {
    disabled: boolean;
    fullWidth?: boolean;
    initialValues?: CustomerProfileFormValues | null;
    onSubmit: (values: CustomerProfileFormValues) => Promise<void>;
};

export const CustomerProfileForm = ({disabled, fullWidth = false, initialValues, onSubmit}: Props) => {
    return (
        <Formik<CustomerProfileFormValues>
            enableReinitialize
            initialValues={initialValues || INITIAL_VALUES}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={customerValidationSchema}
        >
            {({dirty}) => (
                <Form>
                    <CustomerProfileFormFields fullWidth={fullWidth} />
                    <Grid container direction="column" spacing={4} md={fullWidth ? 12 : 6} xs={12}>
                        <Grid item>
                            <Button
                                color="secondary"
                                disabled={!dirty || disabled}
                                type="submit"
                                variant="contained"
                            >
                                {lang.customer.profile.save}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
};
