import React, {useMemo} from 'react';
import {hot} from 'react-hot-loader';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';

import './assets/scss/App.scss';
import {AuthProvider} from './auth/AuthProvider';
import {NestedRoute} from './router/NestedRoute';
import {createRoutes} from './router/createRoutes';
import {GraphQLProvider} from './graphql/GraphQLProvider';
import {theme} from './components/layout/theme';
import {CustomerProvider} from './components/customer/CustomerProvider';
import {SnackbarProvider} from './components/snackbar/SnackbarProvider';
import {TechnologySubscriptionProvider} from './components/history/subscription/TechnologySubscriptionProvider';
import {LocaleProvider} from './lang/context/LocaleProvider';
import {RolesProvider} from './auth/RolesProvider';
import {LoaderProvider} from "./components/layout/LoaderProvider";

const App = () => {
    const routes = useMemo(createRoutes, []);

    return (
        <LocaleProvider>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <Router>
                        <AuthProvider>
                            <GraphQLProvider>
                                <RolesProvider>
                                    <LoaderProvider>
                                        <CustomerProvider>
                                            <TechnologySubscriptionProvider>
                                                {routes.map((routeConfig, index) => (
                                                    <NestedRoute key={index} routeConfig={routeConfig} />
                                                ))}
                                            </TechnologySubscriptionProvider>
                                        </CustomerProvider>
                                    </LoaderProvider>
                                </RolesProvider>
                            </GraphQLProvider>
                        </AuthProvider>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </LocaleProvider>
    );
};

declare let module: object;

export default hot(module)(App);
