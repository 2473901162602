import React, {useCallback, useState} from 'react';
import {Box, Button, Grid, Paper} from "@material-ui/core";
import {lang} from "../../../lang/lang";
import {SetupDomainSearch} from "./SetupDomainSearch";
import {Domain, useSubscribedDomainsQuery, useSubscribeToDomainsMutation} from "../../../graphql/graphql.types";
import {plural} from "../../../lang/plural";
import {useSnackbar} from "../../../components/snackbar/useSnackbar";
import {useHistory} from "react-router-dom";
import {Paths} from "../../../router/paths";

export const Setup = () => {
    const {successNotification, errorNotification} = useSnackbar();
    const history = useHistory();
    const {data, loading, refetch} = useSubscribedDomainsQuery();
    const availableDomains = data?.domainSubscriptions?.available ?? 0;
    const subscribedDomains = data?.domainSubscriptions?.domains ?? [];

    const [domains, setDomains] = useState<Domain[]>([]);
    const hasError = domains.length > availableDomains;

    const [subscribeToDomains, {loading: subscribeToDomainsLoading}] = useSubscribeToDomainsMutation();
    const handleSubmit = useCallback(async () => {
        try {
            const domainNames = domains.map(({domain}) => domain);

            await subscribeToDomains({
                variables: {domains: domainNames},
            });
            setDomains([]);
            successNotification(plural(lang.dashboard.setup.successfullySubscribed, {domains: domainNames.length}));
            await refetch();
            history.push(Paths.DASHBOARD);
        } catch {
            errorNotification(lang.dashboard.setup.failedToSubscribe);
        }
    }, [domains, history, refetch, successNotification]);

    return (
        <Paper>
            <Box p={5}>
                {subscribedDomains.length !== 0 ? (
                    <>
                        {lang.dashboard.setup.subscribedTo}
                        <br/>
                        <ul>
                            {subscribedDomains.map((domain) => (
                                <li key={domain}>{domain}</li>
                            ))}
                        </ul>
                    </>
                ) : null}
                {!loading && <p>{plural(lang.dashboard.setup.selectDomains, {domains: availableDomains})}</p>}
                <SetupDomainSearch
                    availableDomains={availableDomains}
                    disabled={loading}
                    onChange={setDomains}
                    subscribedDomains={subscribedDomains}
                    values={domains}
                />
                <Box mt={4}>
                    <Grid container justify="center">
                        <Grid item>
                            <Button
                                color="secondary"
                                disabled={loading || subscribeToDomainsLoading || hasError}
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                {lang.dashboard.setup.subscribe}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
};
