import React, {useCallback, useContext, useMemo} from 'react';
import {Box, Typography} from '@material-ui/core';
import {CustomerProfileForm, CustomerProfileFormValues} from '../../../components/customer/CustomerProfileForm';
import {CustomerContext} from '../../../components/customer/CustomerContext';
import {useUpdateCustomerMutation} from '../../../graphql/graphql.types';
import { useSnackbar } from '../../../components/snackbar/useSnackbar';
import {CustomerEmail} from './components/CustomerEmail';
import {ChangePasswordAccordion} from './components/ChangePasswordAccordion';
import {AuthContext} from '../../../auth/AuthContext';
import {jwtTokenPayload} from '../../../auth/jwtTokenPayload';
import {lang} from '../../../lang/lang';
import {CustomerLocale} from './components/CustomerLocale';

export const CustomerProfilePage = () => {
    const {token} = useContext(AuthContext);
    const email = useMemo(() => jwtTokenPayload(token)?.email as string, [token]);
    const {customer} = useContext(CustomerContext);
    const initialValues: CustomerProfileFormValues | null = useMemo(() => {
        if (!customer) {
            return null;
        }

        return {
            contactInfo: {
                firstName: customer.contactInfo.name.firstName,
                lastName: customer.contactInfo.name.lastName,
                countryIso: customer.contactInfo.country.code,
            },
            employmentInfo: {
                companyName: customer.employmentInfo.companyName,
                companyPhone: customer.employmentInfo.companyPhone,
                role: customer.employmentInfo.role,
            },
        };
    }, [customer]);

    const {errorNotification, successNotification} = useSnackbar();
    const [update, {loading}] = useUpdateCustomerMutation();
    const handleSubmit = useCallback(async (values: CustomerProfileFormValues) => {
        try {
            await update({variables: values});

            successNotification(lang.customer.profile.notification.profileUpdated);
        } catch {
            errorNotification(lang.customer.profile.notification.profileUpdateFailed);
        }
    }, [errorNotification, successNotification, update]);

    return (
        <>
            <Box mb={3}>
                <Typography variant="h6">{lang.customer.profile.generalInformation}</Typography>
            </Box>
            <CustomerProfileForm
                disabled={loading}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
            <CustomerEmail email={email} />
            <ChangePasswordAccordion email={email} />
            <CustomerLocale />
        </>
    );
};
