import React, {PropsWithChildren, ReactElement} from 'react';
import {ListItemIcon, MenuItem, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

type Props = PropsWithChildren<{
    icon: ReactElement;
    onClick: () => void;
}>;

const useStyles = makeStyles((theme) => ({
    item: {
        paddingLeft: theme.spacing(3.5),
    },
    icon: {
        minWidth: 32,
    },
}));

export const CustomerMenuItem = ({children, icon, onClick}: Props) => {
    const classes = useStyles();

    return (
        <MenuItem className={classes.item} onClick={onClick}>
            <ListItemIcon className={classes.icon}>
                {icon}
            </ListItemIcon>
            <Typography variant="inherit">{children}</Typography>
        </MenuItem>
    );
};
