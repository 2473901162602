import * as React from "react";
import {render} from "react-dom";

import App from "./App";
import {langLoader} from './lang/langLoader';

(async () => {
    await langLoader(localStorage.locale || 'en-US');

    render(<App />, document.querySelector('#root'));
})();
