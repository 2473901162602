import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {useCategoriesQuery, useTechnologyDomainQuery} from '../../../graphql/graphql.types';
import {usePagination} from '../../../components/usePagination';
import {TechnologiesTable} from '../../technology/list/TechnologiesTable';

export const TechnologiesOfDomain = () => {
    const {domain} = useParams<{domain: string}>();
    const pagination = usePagination();
    const {data: categoriesResponse} = useCategoriesQuery();
    const {data, loading} = useTechnologyDomainQuery({
        variables: {
            domain,
            pagination: {
                page: pagination.page,
                perPage: pagination.perPage,
            },
        },
    });
    useEffect(() => pagination.setPaginatedResponse(data?.technologiesOfDomain), [data?.technologiesOfDomain]);

    return (
        <TechnologiesTable
            categories={categoriesResponse?.categories?.categories || []}
            loading={loading}
            pagination={pagination}
            technologies={data?.technologiesOfDomain?.technologies || []}
        />
    );
};
