import * as yup from 'yup';
import {lang} from '../../../../lang/lang';

export const createUserValidationSchema = () => yup.object().shape({
    email: yup.string().required().email().label(lang.auth.email),
    firstName: yup.string()
        .label(lang.customer.profile.firstName)
        .required()
        .min(1)
        .max(128),
    lastName: yup.string()
        .label(lang.customer.profile.lastName)
        .required()
        .min(1)
        .max(128),
    companyName: yup.string().label(lang.customer.profile.companyName),
    companyPhone: yup.string().label(lang.customer.profile.companyPhone).required(),
    businessRole: yup.string().label(lang.customer.profile.role),
});
