// THIS IS A GENERATED FILE, DO NOT EDIT IT!
/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  createdAt: Scalars['Date'];
  expiresAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  uuid: Scalars['ID'];
};

export type AuthMutations = {
  __typename?: 'AuthMutations';
  changePassword: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPasswordRequest: Scalars['Boolean'];
  signIn: TokenResponse;
  signUp: Scalars['Boolean'];
};


export type AuthMutationsChangePasswordArgs = {
  request: ChangePasswordRequest;
};


export type AuthMutationsConfirmEmailArgs = {
  email: Scalars['String'];
};


export type AuthMutationsResetPasswordArgs = {
  request: ResetPasswordRequest;
};


export type AuthMutationsResetPasswordRequestArgs = {
  email: Scalars['String'];
};


export type AuthMutationsSignInArgs = {
  request: SignInRequest;
};


export type AuthMutationsSignUpArgs = {
  request: SignUpRequest;
};

export type CategoriesResponse = {
  __typename?: 'CategoriesResponse';
  categories: Array<Category>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChangePasswordRequest = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateUserRequest = {
  businessRole?: InputMaybe<Scalars['String']>;
  categoryIds: Array<Scalars['String']>;
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isAlertsEnabled: Scalars['Boolean'];
  isAllCategoriesEnabled: Scalars['Boolean'];
  isFeedsEnabled: Scalars['Boolean'];
  isTargetingCSVExportEnabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  userRole: UserRole;
};

export type Customer = {
  __typename?: 'Customer';
  contactInfo: CustomerContactInfo;
  employmentInfo: CustomerEmploymentInfo;
};

export type CustomerContactInfo = {
  __typename?: 'CustomerContactInfo';
  country: Country;
  name: PersonName;
};

export type CustomerContactInfoInput = {
  countryIso: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CustomerEmploymentInfo = {
  __typename?: 'CustomerEmploymentInfo';
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  role: Scalars['String'];
};

export type CustomerEmploymentInfoInput = {
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
};

export type CustomerInput = {
  contactInfo: CustomerContactInfoInput;
  employmentInfo: CustomerEmploymentInfoInput;
};

export type CustomerMutations = {
  __typename?: 'CustomerMutations';
  create: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type CustomerMutationsCreateArgs = {
  input: CustomerInput;
};


export type CustomerMutationsUpdateArgs = {
  input: CustomerInput;
};

export type DashboardDomainHistory = {
  __typename?: 'DashboardDomainHistory';
  categories: Array<DashboardDomainHistoryItem>;
  domain: Scalars['String'];
};

export type DashboardDomainHistoryItem = {
  __typename?: 'DashboardDomainHistoryItem';
  category?: Maybe<Category>;
  history: Array<DomainHistory>;
};

export type Domain = {
  __typename?: 'Domain';
  alexaRank?: Maybe<Scalars['Int']>;
  country: Country;
  domain: Scalars['String'];
  technologiesCount?: Maybe<Scalars['Int']>;
};

export type DomainHistory = {
  __typename?: 'DomainHistory';
  action: HistoryAction;
  foundAt: Scalars['Date'];
  technologyName: Scalars['String'];
  technologyUuid: Scalars['String'];
};

export type DomainHistoryResponse = {
  __typename?: 'DomainHistoryResponse';
  domain: Scalars['String'];
  history: Array<DomainHistory>;
};

export type DomainSubscriptionsResponse = {
  __typename?: 'DomainSubscriptionsResponse';
  available: Scalars['Int'];
  domains: Array<Scalars['String']>;
};

export type DomainsOfTechnologyRequest = {
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
  technologyId: Scalars['ID'];
};

export type DomainsOfTechnologyResponse = PaginatedResponse & {
  __typename?: 'DomainsOfTechnologyResponse';
  domains: Array<Domain>;
  pagination: Pagination;
  technology: Technology;
};

export type DomainsRequest = {
  nameFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
};

export type DomainsResponse = PaginatedResponse & {
  __typename?: 'DomainsResponse';
  domains: Array<Domain>;
  pagination: Pagination;
};

export enum HistoryAction {
  ADDED = 'ADDED',
  DISCOVERED = 'DISCOVERED',
  DROPPED = 'DROPPED'
}

export type InfinitePaginatedResponse = {
  pagination: InfinitePagination;
};

export type InfinitePagination = {
  __typename?: 'InfinitePagination';
  hasNextPage: Scalars['Boolean'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type ListTechnologiesItem = {
  __typename?: 'ListTechnologiesItem';
  category?: Maybe<Category>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  auth: AuthMutations;
  createApiToken: Scalars['String'];
  customer: CustomerMutations;
  removeApiToken: Scalars['Boolean'];
  replaceTechnologySubscriptions: Scalars['Boolean'];
  subscribeToDomains: Scalars['Boolean'];
  subscribeToTechnology: Scalars['Boolean'];
  unsubscribeFromDomains: Scalars['Boolean'];
  unsubscribeFromTechnology: Scalars['Boolean'];
  user: UserMutations;
};


export type MutationCreateApiTokenArgs = {
  expiresAt?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
};


export type MutationRemoveApiTokenArgs = {
  tokenUuid: Scalars['ID'];
};


export type MutationReplaceTechnologySubscriptionsArgs = {
  technologyIds: Array<Scalars['String']>;
};


export type MutationSubscribeToDomainsArgs = {
  domains: Array<Scalars['String']>;
};


export type MutationSubscribeToTechnologyArgs = {
  technologyIds: Array<Scalars['String']>;
};


export type MutationUnsubscribeFromDomainsArgs = {
  domains: Array<Scalars['String']>;
};


export type MutationUnsubscribeFromTechnologyArgs = {
  technologyIds: Array<Scalars['String']>;
};

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type PaginatedResponse = {
  pagination: Pagination;
};

export type Pagination = {
  __typename?: 'Pagination';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pages: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PaginationRequest = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PersonName = {
  __typename?: 'PersonName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  apiTokens: Array<ApiToken>;
  categories: CategoriesResponse;
  customer: Customer;
  dashboardDomainsHistory: Array<DashboardDomainHistory>;
  domainHistory: DomainHistoryResponse;
  domainSubscriptions?: Maybe<DomainSubscriptionsResponse>;
  domains: DomainsResponse;
  domainsOfTechnology: DomainsOfTechnologyResponse;
  listTechnologies: Array<ListTechnologiesItem>;
  roles: Array<UserRole>;
  targeting: TargetResponse;
  technologies: TechnologiesResponse;
  technologiesOfDashboardDomains: Array<TechnologiesOfDashboardDomainsResponse>;
  technologiesOfDomain: TechnologiesOfDomainResponse;
  technologyFeeds: TechnologyFeedsResponse;
  technologyHistory: TechnologyHistoryResponse;
  technologySubscriptions: Array<Scalars['String']>;
  users: UsersResponse;
};


export type QueryDomainHistoryArgs = {
  domain: Scalars['String'];
};


export type QueryDomainsArgs = {
  request: DomainsRequest;
};


export type QueryDomainsOfTechnologyArgs = {
  request: DomainsOfTechnologyRequest;
};


export type QueryTargetingArgs = {
  request: TargetRequest;
};


export type QueryTechnologiesArgs = {
  request: TechnologiesRequest;
};


export type QueryTechnologiesOfDomainArgs = {
  request: TechnologiesOfDomainRequest;
};


export type QueryTechnologyFeedsArgs = {
  date?: InputMaybe<Scalars['String']>;
};


export type QueryTechnologyHistoryArgs = {
  technologyId: Scalars['ID'];
};


export type QueryUsersArgs = {
  request: UsersRequest;
};

export type ResetPasswordRequest = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type SignInRequest = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpRequest = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SortingRequest = {
  field: Scalars['String'];
  order: Order;
};

export type TargetItem = {
  __typename?: 'TargetItem';
  domain: Scalars['String'];
  technologyCount: Scalars['Int'];
};

export type TargetRequest = {
  filters: Array<InputMaybe<TargetingFilter>>;
  pagination: PaginationRequest;
  sorting: TargetingSortRequest;
};

export type TargetResponse = {
  __typename?: 'TargetResponse';
  items: Array<TargetItem>;
  totalCount: Scalars['Int'];
};

export enum TargetingField {
  ACTION = 'ACTION',
  ALEXA_RANK = 'ALEXA_RANK',
  CATEGORY = 'CATEGORY',
  DOMAIN = 'DOMAIN',
  FOUND_AT = 'FOUND_AT',
  TECHNOLOGY = 'TECHNOLOGY'
}

export type TargetingFilter = {
  conditions: Array<TargetingFilterCondition>;
};

export type TargetingFilterCondition = {
  filter: TargetingField;
  operator: TargetingOperator;
  value: Array<Scalars['String']>;
};

export enum TargetingOperator {
  ALL_OF = 'ALL_OF',
  EQUAL = 'EQUAL',
  EXCLUDE_ALL = 'EXCLUDE_ALL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  ONE_OF = 'ONE_OF'
}

export type TargetingSortRequest = {
  by: Order;
  field: TargetingSortType;
};

export enum TargetingSortType {
  COUNT_TECHS = 'COUNT_TECHS',
  DOMAIN = 'DOMAIN'
}

export type TechnologiesOfDashboardDomainsResponse = {
  __typename?: 'TechnologiesOfDashboardDomainsResponse';
  domain: Domain;
  technologies: Array<Technology>;
};

export type TechnologiesOfDomainRequest = {
  domain: Scalars['String'];
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
};

export type TechnologiesOfDomainResponse = PaginatedResponse & {
  __typename?: 'TechnologiesOfDomainResponse';
  domain: Domain;
  pagination: Pagination;
  technologies: Array<Technology>;
};

export type TechnologiesRequest = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  nameFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
};

export type TechnologiesResponse = PaginatedResponse & {
  __typename?: 'TechnologiesResponse';
  pagination: Pagination;
  technologies: Array<Technology>;
};

export type Technology = {
  __typename?: 'Technology';
  category?: Maybe<Category>;
  domainsCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TechnologyFeed = {
  __typename?: 'TechnologyFeed';
  countAddedDomains: Scalars['Int'];
  countDroppedDomains: Scalars['Int'];
  date: Scalars['String'];
  technologyName: Scalars['String'];
  technologyUuid: Scalars['String'];
};

export type TechnologyFeedsResponse = {
  __typename?: 'TechnologyFeedsResponse';
  feeds: Array<TechnologyFeed>;
};

export type TechnologyHistory = {
  __typename?: 'TechnologyHistory';
  action: HistoryAction;
  domain: Scalars['String'];
  domainUuid: Scalars['String'];
  foundAt: Scalars['Date'];
};

export type TechnologyHistoryResponse = {
  __typename?: 'TechnologyHistoryResponse';
  history: Array<TechnologyHistory>;
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  token: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  businessRole?: Maybe<Scalars['String']>;
  categoryIds: Array<Scalars['String']>;
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isAlertsEnabled: Scalars['Boolean'];
  isFeedsEnabled: Scalars['Boolean'];
  isTargetingCSVExportEnabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  status: Scalars['String'];
  userRole: UserRole;
};

export type UserMutations = {
  __typename?: 'UserMutations';
  createUser: Scalars['Boolean'];
};


export type UserMutationsCreateUserArgs = {
  request: CreateUserRequest;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  API = 'API',
  CLIENT = 'CLIENT',
  DASHBOARD = 'DASHBOARD',
  SALES = 'SALES'
}

export type UsersRequest = {
  pagination: PaginationRequest;
};

export type UsersResponse = InfinitePaginatedResponse & {
  __typename?: 'UsersResponse';
  items: Array<User>;
  pagination: InfinitePagination;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userRole: UserRole;
  businessRole?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  isAlertsEnabled: Scalars['Boolean'];
  isFeedsEnabled: Scalars['Boolean'];
  isTargetingCSVExportEnabled: Scalars['Boolean'];
  isAllCategoriesEnabled: Scalars['Boolean'];
  categoryIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', user: { __typename?: 'UserMutations', createUser: boolean } };

export type CreateApiTokenMutationVariables = Exact<{
  name: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['Date']>;
}>;


export type CreateApiTokenMutation = { __typename?: 'Mutation', createApiToken: string };

export type RemoveApiTokenMutationVariables = Exact<{
  tokenUuid: Scalars['ID'];
}>;


export type RemoveApiTokenMutation = { __typename?: 'Mutation', removeApiToken: boolean };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', changePassword: boolean } };

export type ConfirmEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', confirmEmail: boolean } };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', resetPassword: boolean } };

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordRequestMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', resetPasswordRequest: boolean } };

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', signIn: { __typename?: 'TokenResponse', token: string } } };

export type SignUpMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignUpMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthMutations', signUp: boolean } };

export type CreateCustomerMutationVariables = Exact<{
  contactInfo: CustomerContactInfoInput;
  employmentInfo: CustomerEmploymentInfoInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', customer: { __typename?: 'CustomerMutations', create: boolean } };

export type UpdateCustomerMutationVariables = Exact<{
  contactInfo: CustomerContactInfoInput;
  employmentInfo: CustomerEmploymentInfoInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', customer: { __typename?: 'CustomerMutations', update: boolean } };

export type SubscribeToDomainsMutationVariables = Exact<{
  domains: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubscribeToDomainsMutation = { __typename?: 'Mutation', subscribeToDomains: boolean };

export type ReplaceTechnologySubscriptionsMutationVariables = Exact<{
  technologyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReplaceTechnologySubscriptionsMutation = { __typename?: 'Mutation', replaceTechnologySubscriptions: boolean };

export type SubscribeToTechnologyMutationVariables = Exact<{
  technologyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubscribeToTechnologyMutation = { __typename?: 'Mutation', subscribeToTechnology: boolean };

export type UnsubscribeFromTechnologyMutationVariables = Exact<{
  technologyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnsubscribeFromTechnologyMutation = { __typename?: 'Mutation', unsubscribeFromTechnology: boolean };

export type UsersQueryVariables = Exact<{
  pagination: PaginationRequest;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', items: Array<{ __typename?: 'User', id: string, email: string, status: string, firstName: string, lastName: string, userRole: UserRole, businessRole?: string | null | undefined, companyName: string, companyPhone: string, isAlertsEnabled: boolean, isFeedsEnabled: boolean, isTargetingCSVExportEnabled: boolean, categoryIds: Array<string> }>, pagination: { __typename?: 'InfinitePagination', hasNextPage: boolean, page: number, perPage: number } } };

export type ApiTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiTokensQuery = { __typename?: 'Query', apiTokens: Array<{ __typename?: 'ApiToken', uuid: string, name: string, expiresAt?: any | null | undefined, createdAt: any }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: { __typename?: 'CategoriesResponse', categories: Array<{ __typename?: 'Category', id: string, name: string }> } };

export type CustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', contactInfo: { __typename?: 'CustomerContactInfo', name: { __typename?: 'PersonName', firstName: string, lastName: string }, country: { __typename?: 'Country', code: string, name: string } }, employmentInfo: { __typename?: 'CustomerEmploymentInfo', companyName: string, companyPhone: string, role: string } } };

export type DashboardDomainsHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDomainsHistoryQuery = { __typename?: 'Query', dashboardDomainsHistory: Array<{ __typename?: 'DashboardDomainHistory', domain: string, categories: Array<{ __typename?: 'DashboardDomainHistoryItem', category?: { __typename?: 'Category', id: string, name: string } | null | undefined, history: Array<{ __typename?: 'DomainHistory', technologyUuid: string, technologyName: string, action: HistoryAction, foundAt: any }> }> }> };

export type SubscribedDomainsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscribedDomainsQuery = { __typename?: 'Query', domainSubscriptions?: { __typename?: 'DomainSubscriptionsResponse', domains: Array<string>, available: number } | null | undefined };

export type TechnologiesOfDashboardDomainsQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnologiesOfDashboardDomainsQuery = { __typename?: 'Query', technologiesOfDashboardDomains: Array<{ __typename?: 'TechnologiesOfDashboardDomainsResponse', domain: { __typename?: 'Domain', domain: string }, technologies: Array<{ __typename?: 'Technology', id: string, name: string, category?: { __typename?: 'Category', id: string, name: string } | null | undefined }> }> };

export type DomainsQueryVariables = Exact<{
  nameFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
}>;


export type DomainsQuery = { __typename?: 'Query', domains: { __typename?: 'DomainsResponse', domains: Array<{ __typename?: 'Domain', domain: string, technologiesCount?: number | null | undefined, country: { __typename?: 'Country', code: string, name: string } }>, pagination: { __typename?: 'Pagination', count: number, page: number, pages: number, perPage: number } } };

export type DomainHistoryQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type DomainHistoryQuery = { __typename?: 'Query', domainHistory: { __typename?: 'DomainHistoryResponse', history: Array<{ __typename?: 'DomainHistory', technologyUuid: string, technologyName: string, action: HistoryAction, foundAt: any }> } };

export type TechnologyFeedQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnologyFeedQuery = { __typename?: 'Query', technologyFeeds: { __typename?: 'TechnologyFeedsResponse', feeds: Array<{ __typename?: 'TechnologyFeed', technologyUuid: string, technologyName: string, date: string, countAddedDomains: number, countDroppedDomains: number }> } };

export type TechnologyHistoryQueryVariables = Exact<{
  technologyId: Scalars['ID'];
}>;


export type TechnologyHistoryQuery = { __typename?: 'Query', technologyHistory: { __typename?: 'TechnologyHistoryResponse', history: Array<{ __typename?: 'TechnologyHistory', domainUuid: string, domain: string, action: HistoryAction, foundAt: any }> } };

export type TechnologySubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnologySubscriptionsQuery = { __typename?: 'Query', technologySubscriptions: Array<string> };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<UserRole> };

export type TargetingQueryVariables = Exact<{
  filters: Array<InputMaybe<TargetingFilter>> | InputMaybe<TargetingFilter>;
  sorting: TargetingSortRequest;
  pagination: PaginationRequest;
}>;


export type TargetingQuery = { __typename?: 'Query', targeting: { __typename?: 'TargetResponse', totalCount: number, items: Array<{ __typename?: 'TargetItem', domain: string, technologyCount: number }> } };

export type TechnologiesQueryVariables = Exact<{
  categoryIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
}>;


export type TechnologiesQuery = { __typename?: 'Query', technologies: { __typename?: 'TechnologiesResponse', technologies: Array<{ __typename?: 'Technology', id: string, name: string, domainsCount: number, category?: { __typename?: 'Category', id: string, name: string } | null | undefined }>, pagination: { __typename?: 'Pagination', count: number, page: number, pages: number, perPage: number } } };

export type TechnologiesListQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnologiesListQuery = { __typename?: 'Query', listTechnologies: Array<{ __typename?: 'ListTechnologiesItem', id: string, name: string, category?: { __typename?: 'Category', id: string, name: string } | null | undefined }> };

export type TechnologyQueryVariables = Exact<{
  technologyId: Scalars['ID'];
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
}>;


export type TechnologyQuery = { __typename?: 'Query', domainsOfTechnology: { __typename?: 'DomainsOfTechnologyResponse', technology: { __typename?: 'Technology', id: string, name: string }, domains: Array<{ __typename?: 'Domain', domain: string, technologiesCount?: number | null | undefined, country: { __typename?: 'Country', code: string, name: string } }>, pagination: { __typename?: 'Pagination', count: number, page: number, pages: number, perPage: number } } };

export type TechnologyDomainQueryVariables = Exact<{
  domain: Scalars['String'];
  pagination?: InputMaybe<PaginationRequest>;
  sorting?: InputMaybe<SortingRequest>;
}>;


export type TechnologyDomainQuery = { __typename?: 'Query', technologiesOfDomain: { __typename?: 'TechnologiesOfDomainResponse', technologies: Array<{ __typename?: 'Technology', id: string, name: string, domainsCount: number, category?: { __typename?: 'Category', id: string, name: string } | null | undefined }>, pagination: { __typename?: 'Pagination', count: number, page: number, pages: number, perPage: number } } };


export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $firstName: String!, $lastName: String!, $userRole: UserRole!, $businessRole: String, $companyName: String!, $companyPhone: String!, $isAlertsEnabled: Boolean!, $isFeedsEnabled: Boolean!, $isTargetingCSVExportEnabled: Boolean!, $isAllCategoriesEnabled: Boolean!, $categoryIds: [String!]!) {
  user {
    createUser(
      request: {email: $email, firstName: $firstName, lastName: $lastName, userRole: $userRole, businessRole: $businessRole, companyName: $companyName, companyPhone: $companyPhone, isAlertsEnabled: $isAlertsEnabled, isFeedsEnabled: $isFeedsEnabled, isTargetingCSVExportEnabled: $isTargetingCSVExportEnabled, isAllCategoriesEnabled: $isAllCategoriesEnabled, categoryIds: $categoryIds}
    )
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      userRole: // value for 'userRole'
 *      businessRole: // value for 'businessRole'
 *      companyName: // value for 'companyName'
 *      companyPhone: // value for 'companyPhone'
 *      isAlertsEnabled: // value for 'isAlertsEnabled'
 *      isFeedsEnabled: // value for 'isFeedsEnabled'
 *      isTargetingCSVExportEnabled: // value for 'isTargetingCSVExportEnabled'
 *      isAllCategoriesEnabled: // value for 'isAllCategoriesEnabled'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateApiTokenDocument = gql`
    mutation CreateApiToken($name: String!, $expiresAt: Date) {
  createApiToken(name: $name, expiresAt: $expiresAt)
}
    `;
export type CreateApiTokenMutationFn = ApolloReactCommon.MutationFunction<CreateApiTokenMutation, CreateApiTokenMutationVariables>;

/**
 * __useCreateApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiTokenMutation, { data, loading, error }] = useCreateApiTokenMutation({
 *   variables: {
 *      name: // value for 'name'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useCreateApiTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateApiTokenMutation, CreateApiTokenMutationVariables>(CreateApiTokenDocument, options);
      }
export type CreateApiTokenMutationHookResult = ReturnType<typeof useCreateApiTokenMutation>;
export type CreateApiTokenMutationResult = ApolloReactCommon.MutationResult<CreateApiTokenMutation>;
export type CreateApiTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>;
export const RemoveApiTokenDocument = gql`
    mutation RemoveApiToken($tokenUuid: ID!) {
  removeApiToken(tokenUuid: $tokenUuid)
}
    `;
export type RemoveApiTokenMutationFn = ApolloReactCommon.MutationFunction<RemoveApiTokenMutation, RemoveApiTokenMutationVariables>;

/**
 * __useRemoveApiTokenMutation__
 *
 * To run a mutation, you first call `useRemoveApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApiTokenMutation, { data, loading, error }] = useRemoveApiTokenMutation({
 *   variables: {
 *      tokenUuid: // value for 'tokenUuid'
 *   },
 * });
 */
export function useRemoveApiTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveApiTokenMutation, RemoveApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveApiTokenMutation, RemoveApiTokenMutationVariables>(RemoveApiTokenDocument, options);
      }
export type RemoveApiTokenMutationHookResult = ReturnType<typeof useRemoveApiTokenMutation>;
export type RemoveApiTokenMutationResult = ApolloReactCommon.MutationResult<RemoveApiTokenMutation>;
export type RemoveApiTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveApiTokenMutation, RemoveApiTokenMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  auth {
    changePassword(request: {oldPassword: $oldPassword, newPassword: $newPassword})
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($email: String!) {
  auth {
    confirmEmail(email: $email)
  }
}
    `;
export type ConfirmEmailMutationFn = ApolloReactCommon.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = ApolloReactCommon.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  auth {
    resetPassword(request: {token: $token, newPassword: $newPassword})
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordRequestDocument = gql`
    mutation ResetPasswordRequest($email: String!) {
  auth {
    resetPasswordRequest(email: $email)
  }
}
    `;
export type ResetPasswordRequestMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>(ResetPasswordRequestDocument, options);
      }
export type ResetPasswordRequestMutationHookResult = ReturnType<typeof useResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationResult = ApolloReactCommon.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  auth {
    signIn(request: {email: $email, password: $password}) {
      token
    }
  }
}
    `;
export type SignInMutationFn = ApolloReactCommon.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = ApolloReactCommon.MutationResult<SignInMutation>;
export type SignInMutationOptions = ApolloReactCommon.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($token: String!, $password: String!) {
  auth {
    signUp(request: {token: $token, password: $password})
  }
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($contactInfo: CustomerContactInfoInput!, $employmentInfo: CustomerEmploymentInfoInput!) {
  customer {
    create(input: {contactInfo: $contactInfo, employmentInfo: $employmentInfo})
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      contactInfo: // value for 'contactInfo'
 *      employmentInfo: // value for 'employmentInfo'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($contactInfo: CustomerContactInfoInput!, $employmentInfo: CustomerEmploymentInfoInput!) {
  customer {
    update(input: {contactInfo: $contactInfo, employmentInfo: $employmentInfo})
  }
}
    `;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      contactInfo: // value for 'contactInfo'
 *      employmentInfo: // value for 'employmentInfo'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const SubscribeToDomainsDocument = gql`
    mutation SubscribeToDomains($domains: [String!]!) {
  subscribeToDomains(domains: $domains)
}
    `;
export type SubscribeToDomainsMutationFn = ApolloReactCommon.MutationFunction<SubscribeToDomainsMutation, SubscribeToDomainsMutationVariables>;

/**
 * __useSubscribeToDomainsMutation__
 *
 * To run a mutation, you first call `useSubscribeToDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToDomainsMutation, { data, loading, error }] = useSubscribeToDomainsMutation({
 *   variables: {
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useSubscribeToDomainsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeToDomainsMutation, SubscribeToDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubscribeToDomainsMutation, SubscribeToDomainsMutationVariables>(SubscribeToDomainsDocument, options);
      }
export type SubscribeToDomainsMutationHookResult = ReturnType<typeof useSubscribeToDomainsMutation>;
export type SubscribeToDomainsMutationResult = ApolloReactCommon.MutationResult<SubscribeToDomainsMutation>;
export type SubscribeToDomainsMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeToDomainsMutation, SubscribeToDomainsMutationVariables>;
export const ReplaceTechnologySubscriptionsDocument = gql`
    mutation ReplaceTechnologySubscriptions($technologyIds: [String!]!) {
  replaceTechnologySubscriptions(technologyIds: $technologyIds)
}
    `;
export type ReplaceTechnologySubscriptionsMutationFn = ApolloReactCommon.MutationFunction<ReplaceTechnologySubscriptionsMutation, ReplaceTechnologySubscriptionsMutationVariables>;

/**
 * __useReplaceTechnologySubscriptionsMutation__
 *
 * To run a mutation, you first call `useReplaceTechnologySubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTechnologySubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTechnologySubscriptionsMutation, { data, loading, error }] = useReplaceTechnologySubscriptionsMutation({
 *   variables: {
 *      technologyIds: // value for 'technologyIds'
 *   },
 * });
 */
export function useReplaceTechnologySubscriptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplaceTechnologySubscriptionsMutation, ReplaceTechnologySubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ReplaceTechnologySubscriptionsMutation, ReplaceTechnologySubscriptionsMutationVariables>(ReplaceTechnologySubscriptionsDocument, options);
      }
export type ReplaceTechnologySubscriptionsMutationHookResult = ReturnType<typeof useReplaceTechnologySubscriptionsMutation>;
export type ReplaceTechnologySubscriptionsMutationResult = ApolloReactCommon.MutationResult<ReplaceTechnologySubscriptionsMutation>;
export type ReplaceTechnologySubscriptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReplaceTechnologySubscriptionsMutation, ReplaceTechnologySubscriptionsMutationVariables>;
export const SubscribeToTechnologyDocument = gql`
    mutation SubscribeToTechnology($technologyIds: [String!]!) {
  subscribeToTechnology(technologyIds: $technologyIds)
}
    `;
export type SubscribeToTechnologyMutationFn = ApolloReactCommon.MutationFunction<SubscribeToTechnologyMutation, SubscribeToTechnologyMutationVariables>;

/**
 * __useSubscribeToTechnologyMutation__
 *
 * To run a mutation, you first call `useSubscribeToTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToTechnologyMutation, { data, loading, error }] = useSubscribeToTechnologyMutation({
 *   variables: {
 *      technologyIds: // value for 'technologyIds'
 *   },
 * });
 */
export function useSubscribeToTechnologyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeToTechnologyMutation, SubscribeToTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubscribeToTechnologyMutation, SubscribeToTechnologyMutationVariables>(SubscribeToTechnologyDocument, options);
      }
export type SubscribeToTechnologyMutationHookResult = ReturnType<typeof useSubscribeToTechnologyMutation>;
export type SubscribeToTechnologyMutationResult = ApolloReactCommon.MutationResult<SubscribeToTechnologyMutation>;
export type SubscribeToTechnologyMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeToTechnologyMutation, SubscribeToTechnologyMutationVariables>;
export const UnsubscribeFromTechnologyDocument = gql`
    mutation UnsubscribeFromTechnology($technologyIds: [String!]!) {
  unsubscribeFromTechnology(technologyIds: $technologyIds)
}
    `;
export type UnsubscribeFromTechnologyMutationFn = ApolloReactCommon.MutationFunction<UnsubscribeFromTechnologyMutation, UnsubscribeFromTechnologyMutationVariables>;

/**
 * __useUnsubscribeFromTechnologyMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromTechnologyMutation, { data, loading, error }] = useUnsubscribeFromTechnologyMutation({
 *   variables: {
 *      technologyIds: // value for 'technologyIds'
 *   },
 * });
 */
export function useUnsubscribeFromTechnologyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeFromTechnologyMutation, UnsubscribeFromTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnsubscribeFromTechnologyMutation, UnsubscribeFromTechnologyMutationVariables>(UnsubscribeFromTechnologyDocument, options);
      }
export type UnsubscribeFromTechnologyMutationHookResult = ReturnType<typeof useUnsubscribeFromTechnologyMutation>;
export type UnsubscribeFromTechnologyMutationResult = ApolloReactCommon.MutationResult<UnsubscribeFromTechnologyMutation>;
export type UnsubscribeFromTechnologyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeFromTechnologyMutation, UnsubscribeFromTechnologyMutationVariables>;
export const UsersDocument = gql`
    query Users($pagination: PaginationRequest!) {
  users(request: {pagination: $pagination}) {
    items {
      id
      email
      status
      firstName
      lastName
      userRole
      businessRole
      companyName
      companyPhone
      isAlertsEnabled
      isFeedsEnabled
      isTargetingCSVExportEnabled
      categoryIds
    }
    pagination {
      hasNextPage
      page
      perPage
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const ApiTokensDocument = gql`
    query ApiTokens {
  apiTokens {
    uuid
    name
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useApiTokensQuery__
 *
 * To run a query within a React component, call `useApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiTokensQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiTokensQuery, ApiTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ApiTokensQuery, ApiTokensQueryVariables>(ApiTokensDocument, options);
      }
export function useApiTokensLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiTokensQuery, ApiTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ApiTokensQuery, ApiTokensQueryVariables>(ApiTokensDocument, options);
        }
export type ApiTokensQueryHookResult = ReturnType<typeof useApiTokensQuery>;
export type ApiTokensLazyQueryHookResult = ReturnType<typeof useApiTokensLazyQuery>;
export type ApiTokensQueryResult = ApolloReactCommon.QueryResult<ApiTokensQuery, ApiTokensQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = ApolloReactCommon.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CustomerDocument = gql`
    query Customer {
  customer {
    contactInfo {
      name {
        firstName
        lastName
      }
      country {
        code
        name
      }
    }
    employmentInfo {
      companyName
      companyPhone
      role
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const DashboardDomainsHistoryDocument = gql`
    query DashboardDomainsHistory {
  dashboardDomainsHistory {
    domain
    categories {
      category {
        id
        name
      }
      history {
        technologyUuid
        technologyName
        action
        foundAt
      }
    }
  }
}
    `;

/**
 * __useDashboardDomainsHistoryQuery__
 *
 * To run a query within a React component, call `useDashboardDomainsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDomainsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDomainsHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDomainsHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardDomainsHistoryQuery, DashboardDomainsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DashboardDomainsHistoryQuery, DashboardDomainsHistoryQueryVariables>(DashboardDomainsHistoryDocument, options);
      }
export function useDashboardDomainsHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardDomainsHistoryQuery, DashboardDomainsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DashboardDomainsHistoryQuery, DashboardDomainsHistoryQueryVariables>(DashboardDomainsHistoryDocument, options);
        }
export type DashboardDomainsHistoryQueryHookResult = ReturnType<typeof useDashboardDomainsHistoryQuery>;
export type DashboardDomainsHistoryLazyQueryHookResult = ReturnType<typeof useDashboardDomainsHistoryLazyQuery>;
export type DashboardDomainsHistoryQueryResult = ApolloReactCommon.QueryResult<DashboardDomainsHistoryQuery, DashboardDomainsHistoryQueryVariables>;
export const SubscribedDomainsDocument = gql`
    query SubscribedDomains {
  domainSubscriptions {
    domains
    available
  }
}
    `;

/**
 * __useSubscribedDomainsQuery__
 *
 * To run a query within a React component, call `useSubscribedDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribedDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribedDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscribedDomainsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscribedDomainsQuery, SubscribedDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SubscribedDomainsQuery, SubscribedDomainsQueryVariables>(SubscribedDomainsDocument, options);
      }
export function useSubscribedDomainsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscribedDomainsQuery, SubscribedDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SubscribedDomainsQuery, SubscribedDomainsQueryVariables>(SubscribedDomainsDocument, options);
        }
export type SubscribedDomainsQueryHookResult = ReturnType<typeof useSubscribedDomainsQuery>;
export type SubscribedDomainsLazyQueryHookResult = ReturnType<typeof useSubscribedDomainsLazyQuery>;
export type SubscribedDomainsQueryResult = ApolloReactCommon.QueryResult<SubscribedDomainsQuery, SubscribedDomainsQueryVariables>;
export const TechnologiesOfDashboardDomainsDocument = gql`
    query TechnologiesOfDashboardDomains {
  technologiesOfDashboardDomains {
    domain {
      domain
    }
    technologies {
      id
      name
      category {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTechnologiesOfDashboardDomainsQuery__
 *
 * To run a query within a React component, call `useTechnologiesOfDashboardDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologiesOfDashboardDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologiesOfDashboardDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnologiesOfDashboardDomainsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechnologiesOfDashboardDomainsQuery, TechnologiesOfDashboardDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologiesOfDashboardDomainsQuery, TechnologiesOfDashboardDomainsQueryVariables>(TechnologiesOfDashboardDomainsDocument, options);
      }
export function useTechnologiesOfDashboardDomainsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologiesOfDashboardDomainsQuery, TechnologiesOfDashboardDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologiesOfDashboardDomainsQuery, TechnologiesOfDashboardDomainsQueryVariables>(TechnologiesOfDashboardDomainsDocument, options);
        }
export type TechnologiesOfDashboardDomainsQueryHookResult = ReturnType<typeof useTechnologiesOfDashboardDomainsQuery>;
export type TechnologiesOfDashboardDomainsLazyQueryHookResult = ReturnType<typeof useTechnologiesOfDashboardDomainsLazyQuery>;
export type TechnologiesOfDashboardDomainsQueryResult = ApolloReactCommon.QueryResult<TechnologiesOfDashboardDomainsQuery, TechnologiesOfDashboardDomainsQueryVariables>;
export const DomainsDocument = gql`
    query Domains($nameFilter: String, $pagination: PaginationRequest, $sorting: SortingRequest) {
  domains(
    request: {nameFilter: $nameFilter, pagination: $pagination, sorting: $sorting}
  ) {
    domains {
      domain
      technologiesCount
      country {
        code
        name
      }
    }
    pagination {
      count
      page
      pages
      perPage
    }
  }
}
    `;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      nameFilter: // value for 'nameFilter'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useDomainsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
      }
export function useDomainsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
        }
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsQueryResult = ApolloReactCommon.QueryResult<DomainsQuery, DomainsQueryVariables>;
export const DomainHistoryDocument = gql`
    query DomainHistory($domain: String!) {
  domainHistory(domain: $domain) {
    history {
      technologyUuid
      technologyName
      action
      foundAt
    }
  }
}
    `;

/**
 * __useDomainHistoryQuery__
 *
 * To run a query within a React component, call `useDomainHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainHistoryQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useDomainHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DomainHistoryQuery, DomainHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DomainHistoryQuery, DomainHistoryQueryVariables>(DomainHistoryDocument, options);
      }
export function useDomainHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainHistoryQuery, DomainHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DomainHistoryQuery, DomainHistoryQueryVariables>(DomainHistoryDocument, options);
        }
export type DomainHistoryQueryHookResult = ReturnType<typeof useDomainHistoryQuery>;
export type DomainHistoryLazyQueryHookResult = ReturnType<typeof useDomainHistoryLazyQuery>;
export type DomainHistoryQueryResult = ApolloReactCommon.QueryResult<DomainHistoryQuery, DomainHistoryQueryVariables>;
export const TechnologyFeedDocument = gql`
    query TechnologyFeed {
  technologyFeeds {
    feeds {
      technologyUuid
      technologyName
      date
      countAddedDomains
      countDroppedDomains
    }
  }
}
    `;

/**
 * __useTechnologyFeedQuery__
 *
 * To run a query within a React component, call `useTechnologyFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologyFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologyFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnologyFeedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechnologyFeedQuery, TechnologyFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologyFeedQuery, TechnologyFeedQueryVariables>(TechnologyFeedDocument, options);
      }
export function useTechnologyFeedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologyFeedQuery, TechnologyFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologyFeedQuery, TechnologyFeedQueryVariables>(TechnologyFeedDocument, options);
        }
export type TechnologyFeedQueryHookResult = ReturnType<typeof useTechnologyFeedQuery>;
export type TechnologyFeedLazyQueryHookResult = ReturnType<typeof useTechnologyFeedLazyQuery>;
export type TechnologyFeedQueryResult = ApolloReactCommon.QueryResult<TechnologyFeedQuery, TechnologyFeedQueryVariables>;
export const TechnologyHistoryDocument = gql`
    query TechnologyHistory($technologyId: ID!) {
  technologyHistory(technologyId: $technologyId) {
    history {
      domainUuid
      domain
      action
      foundAt
    }
  }
}
    `;

/**
 * __useTechnologyHistoryQuery__
 *
 * To run a query within a React component, call `useTechnologyHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologyHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologyHistoryQuery({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *   },
 * });
 */
export function useTechnologyHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TechnologyHistoryQuery, TechnologyHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologyHistoryQuery, TechnologyHistoryQueryVariables>(TechnologyHistoryDocument, options);
      }
export function useTechnologyHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologyHistoryQuery, TechnologyHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologyHistoryQuery, TechnologyHistoryQueryVariables>(TechnologyHistoryDocument, options);
        }
export type TechnologyHistoryQueryHookResult = ReturnType<typeof useTechnologyHistoryQuery>;
export type TechnologyHistoryLazyQueryHookResult = ReturnType<typeof useTechnologyHistoryLazyQuery>;
export type TechnologyHistoryQueryResult = ApolloReactCommon.QueryResult<TechnologyHistoryQuery, TechnologyHistoryQueryVariables>;
export const TechnologySubscriptionsDocument = gql`
    query TechnologySubscriptions {
  technologySubscriptions
}
    `;

/**
 * __useTechnologySubscriptionsQuery__
 *
 * To run a query within a React component, call `useTechnologySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologySubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnologySubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechnologySubscriptionsQuery, TechnologySubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologySubscriptionsQuery, TechnologySubscriptionsQueryVariables>(TechnologySubscriptionsDocument, options);
      }
export function useTechnologySubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologySubscriptionsQuery, TechnologySubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologySubscriptionsQuery, TechnologySubscriptionsQueryVariables>(TechnologySubscriptionsDocument, options);
        }
export type TechnologySubscriptionsQueryHookResult = ReturnType<typeof useTechnologySubscriptionsQuery>;
export type TechnologySubscriptionsLazyQueryHookResult = ReturnType<typeof useTechnologySubscriptionsLazyQuery>;
export type TechnologySubscriptionsQueryResult = ApolloReactCommon.QueryResult<TechnologySubscriptionsQuery, TechnologySubscriptionsQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const TargetingDocument = gql`
    query Targeting($filters: [TargetingFilter]!, $sorting: TargetingSortRequest!, $pagination: PaginationRequest!) {
  targeting(
    request: {filters: $filters, sorting: $sorting, pagination: $pagination}
  ) {
    items {
      domain
      technologyCount
    }
    totalCount
  }
}
    `;

/**
 * __useTargetingQuery__
 *
 * To run a query within a React component, call `useTargetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetingQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTargetingQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TargetingQuery, TargetingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TargetingQuery, TargetingQueryVariables>(TargetingDocument, options);
      }
export function useTargetingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TargetingQuery, TargetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TargetingQuery, TargetingQueryVariables>(TargetingDocument, options);
        }
export type TargetingQueryHookResult = ReturnType<typeof useTargetingQuery>;
export type TargetingLazyQueryHookResult = ReturnType<typeof useTargetingLazyQuery>;
export type TargetingQueryResult = ApolloReactCommon.QueryResult<TargetingQuery, TargetingQueryVariables>;
export const TechnologiesDocument = gql`
    query Technologies($categoryIds: [ID!], $nameFilter: String, $pagination: PaginationRequest, $sorting: SortingRequest) {
  technologies(
    request: {categoryIds: $categoryIds, nameFilter: $nameFilter, pagination: $pagination, sorting: $sorting}
  ) {
    technologies {
      id
      name
      domainsCount
      category {
        id
        name
      }
    }
    pagination {
      count
      page
      pages
      perPage
    }
  }
}
    `;

/**
 * __useTechnologiesQuery__
 *
 * To run a query within a React component, call `useTechnologiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologiesQuery({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *      nameFilter: // value for 'nameFilter'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTechnologiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechnologiesQuery, TechnologiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologiesQuery, TechnologiesQueryVariables>(TechnologiesDocument, options);
      }
export function useTechnologiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologiesQuery, TechnologiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologiesQuery, TechnologiesQueryVariables>(TechnologiesDocument, options);
        }
export type TechnologiesQueryHookResult = ReturnType<typeof useTechnologiesQuery>;
export type TechnologiesLazyQueryHookResult = ReturnType<typeof useTechnologiesLazyQuery>;
export type TechnologiesQueryResult = ApolloReactCommon.QueryResult<TechnologiesQuery, TechnologiesQueryVariables>;
export const TechnologiesListDocument = gql`
    query TechnologiesList {
  listTechnologies {
    id
    name
    category {
      id
      name
    }
  }
}
    `;

/**
 * __useTechnologiesListQuery__
 *
 * To run a query within a React component, call `useTechnologiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologiesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnologiesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechnologiesListQuery, TechnologiesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologiesListQuery, TechnologiesListQueryVariables>(TechnologiesListDocument, options);
      }
export function useTechnologiesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologiesListQuery, TechnologiesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologiesListQuery, TechnologiesListQueryVariables>(TechnologiesListDocument, options);
        }
export type TechnologiesListQueryHookResult = ReturnType<typeof useTechnologiesListQuery>;
export type TechnologiesListLazyQueryHookResult = ReturnType<typeof useTechnologiesListLazyQuery>;
export type TechnologiesListQueryResult = ApolloReactCommon.QueryResult<TechnologiesListQuery, TechnologiesListQueryVariables>;
export const TechnologyDocument = gql`
    query Technology($technologyId: ID!, $pagination: PaginationRequest, $sorting: SortingRequest) {
  domainsOfTechnology(
    request: {technologyId: $technologyId, pagination: $pagination, sorting: $sorting}
  ) {
    technology {
      id
      name
    }
    domains {
      domain
      technologiesCount
      country {
        code
        name
      }
    }
    pagination {
      count
      page
      pages
      perPage
    }
  }
}
    `;

/**
 * __useTechnologyQuery__
 *
 * To run a query within a React component, call `useTechnologyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologyQuery({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTechnologyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TechnologyQuery, TechnologyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologyQuery, TechnologyQueryVariables>(TechnologyDocument, options);
      }
export function useTechnologyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologyQuery, TechnologyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologyQuery, TechnologyQueryVariables>(TechnologyDocument, options);
        }
export type TechnologyQueryHookResult = ReturnType<typeof useTechnologyQuery>;
export type TechnologyLazyQueryHookResult = ReturnType<typeof useTechnologyLazyQuery>;
export type TechnologyQueryResult = ApolloReactCommon.QueryResult<TechnologyQuery, TechnologyQueryVariables>;
export const TechnologyDomainDocument = gql`
    query TechnologyDomain($domain: String!, $pagination: PaginationRequest, $sorting: SortingRequest) {
  technologiesOfDomain(
    request: {domain: $domain, pagination: $pagination, sorting: $sorting}
  ) {
    technologies {
      id
      name
      domainsCount
      category {
        id
        name
      }
    }
    pagination {
      count
      page
      pages
      perPage
    }
  }
}
    `;

/**
 * __useTechnologyDomainQuery__
 *
 * To run a query within a React component, call `useTechnologyDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnologyDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnologyDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTechnologyDomainQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TechnologyDomainQuery, TechnologyDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TechnologyDomainQuery, TechnologyDomainQueryVariables>(TechnologyDomainDocument, options);
      }
export function useTechnologyDomainLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechnologyDomainQuery, TechnologyDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TechnologyDomainQuery, TechnologyDomainQueryVariables>(TechnologyDomainDocument, options);
        }
export type TechnologyDomainQueryHookResult = ReturnType<typeof useTechnologyDomainQuery>;
export type TechnologyDomainLazyQueryHookResult = ReturnType<typeof useTechnologyDomainLazyQuery>;
export type TechnologyDomainQueryResult = ApolloReactCommon.QueryResult<TechnologyDomainQuery, TechnologyDomainQueryVariables>;