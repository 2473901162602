import React from 'react';
import {
    makeStyles,
    styled,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import {TechnologyFeed} from '../../../graphql/graphql.types';
import {Link} from '../../navigation/Link';
import {Paths} from '../../../router/paths';
import {formatWeek} from './formatters';
import TriangleDownIcon from '../../../assets/icons/triangle-down.svg';
import TriangleUpIcon from '../../../assets/icons/triangle-up.svg';

type Props = {
    feeds: TechnologyFeed[];
    week: number;
};

const Cell = styled(TableCell)(({theme}) => ({
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    whiteSpace: 'nowrap',
}));

const useStyles = makeStyles((theme) => ({
    addDropColumn: {
        minWidth: 50,
    },
    addDropIcon: {
        width: 10,
        height: 10,
        marginRight: theme.spacing(1),
    },
    addIcon: {
        fill: theme.palette.success.dark,
    },
    emptyCell: {
        borderBottom: 'none',
        padding: 5,
    },
    firstTextCell: {
        paddingLeft: 0,
        width: '100%',
    },
    noBorderBottomCell: {
        borderBottom: 'none',
    },
    weeksAgo: {
        opacity: 0.5,
    },
}));

export const TechnologyFeedList = ({feeds, week}: Props) => {
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.emptyCell} />
                    <TableCell className={clsx(classes.firstTextCell, classes.noBorderBottomCell)}>
                        <Typography
                            className={classes.weeksAgo}
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            {formatWeek(week)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {feeds.map((feed) => (
                    <TableRow key={feed.technologyUuid} hover>
                        <TableCell className={classes.emptyCell} />
                        <Cell className={classes.firstTextCell}>
                            <Link to={[Paths.TECHNOLOGY_HISTORY, {technologyId: feed.technologyUuid}]}>
                                {feed.technologyName}
                            </Link>
                        </Cell>
                        <Cell className={classes.addDropColumn}>
                            <SvgIcon
                                className={clsx(classes.addDropIcon, classes.addIcon)}
                                viewBox="0 0 11 9"
                            >
                                <TriangleUpIcon />
                            </SvgIcon>
                            {feed.countAddedDomains || '--'}
                        </Cell>
                        <Cell className={classes.addDropColumn}>
                            <SvgIcon
                                className={classes.addDropIcon}
                                color="error"
                                viewBox="0 0 11 9"
                            >
                                <TriangleDownIcon />
                            </SvgIcon>
                            {feed.countDroppedDomains || '--'}
                        </Cell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
