import {createMuiTheme, ThemeOptions} from '@material-ui/core/styles';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';

const BACKGROUND_DEFAULT_COLOR = '#F4F5F7';
const BACKGROUND_PAPER_COLOR = '#FAFAFA';
const MAIN_COLOR = '#08083D';
const MAIN_COLOR_100 = '#525277';
const MAIN_LIGHT_COLOR = '#C5C7D9';
const SECONDARY_COLOR = '#363DE1';
const SECONDARY_COLOR_DARKEN_100 = '#1E25C6';
const SECONDARY_DISABLED_COLOR = '#8F93EE';
const SUCCESS_COLOR = '#3FD8A1';
const SUCCESS_DARK_COLOR = '#33AF83';
const SUCCESS_LIGHT_COLOR = '#E9FAF4';
const ERROR_COLOR = '#D60000';
const ERROR_LIGHT_COLOR = '#FFCECE';
const WHITE_COLOR = '#FFF';
const TEXT_COLOR = '#231815';

const GRAY_100 = MAIN_LIGHT_COLOR;
const GRAY_200 = '#DDDEE7';
const GRAY_300 = '#EBEBEF';

const themeOptions: ThemeOptions = {
    palette: {
        background: {
            default: BACKGROUND_DEFAULT_COLOR,
            paper: BACKGROUND_PAPER_COLOR,
        },
        primary: {
            light: MAIN_LIGHT_COLOR,
            main: MAIN_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
            contrastText: WHITE_COLOR,
            dark: SECONDARY_COLOR_DARKEN_100,
        },
        error: {
            main: ERROR_COLOR,
            light: ERROR_LIGHT_COLOR,
        },
        success: {
            main: SUCCESS_COLOR,
            dark: SUCCESS_DARK_COLOR,
            light: SUCCESS_LIGHT_COLOR,
        },
        grey: {
            100: GRAY_100,
            200: GRAY_200,
            300: GRAY_300,
        },
        text: {
            primary: TEXT_COLOR,
        },
    },
    typography: {
        fontFamily: [
            'OpenSans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 14,
        fontWeightLight: 'normal',
        fontWeightMedium: 600,
        fontWeightBold: 'bold',
        body2: {
            fontSize: '1rem',
        },
        h1: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 28,
            fontWeight: 'bold',
        },
        h4: {
            fontSize: 24,
            fontWeight: 'bold',
        },
        h5: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        h6: {
            fontSize: 16,
            fontWeight: 'bold',
        },
        subtitle1: {
            fontSize: 14,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                '& svg': {
                    fontSize: '1rem',
                    marginRight: 8,
                },
            },
            contained: {
                '&$disabled': {
                    color: '#fff',
                },
            },
            containedSecondary: {
                '&$disabled': {
                    backgroundColor: SECONDARY_DISABLED_COLOR,
                },
            },
            outlinedSecondary: {
                '&$disabled': {
                    borderColor: SECONDARY_DISABLED_COLOR,
                    color: SECONDARY_DISABLED_COLOR,
                },
            },
            textSecondary: {
                '&$disabled': {
                    color: SECONDARY_DISABLED_COLOR,
                },
            },
        },
        MuiChip: {
            root: {
                '&:hover': {
                    backgroundColor: GRAY_200,
                    color: MAIN_COLOR_100,
                },
            },
            deleteIcon: {
               color: MAIN_COLOR_100,
                '&:hover': {
                    color: MAIN_COLOR_100,
                },
            },
        },
        MuiDialogContent: {
            root: {
                backgroundColor: BACKGROUND_DEFAULT_COLOR,
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: BACKGROUND_PAPER_COLOR,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: GRAY_200,
            },
        },
        MuiLink: {
            root: {
                color: TEXT_COLOR,
                '&:hover': {
                    color: SECONDARY_COLOR,
                },
            },
        },
        MuiListItemIcon: {
            root: {
                color: TEXT_COLOR,
            },
        },
        MuiTab: {
            root: {
                fontSize: '1.15rem',
                marginRight: 32,
                minHeight: 32,
                minWidth: '0 !important',
                paddingLeft: 0,
                paddingRight: 0,
                textTransform: 'none',
                '&:last-of-type': {
                    marginRight: 0,
                },
            },
        },
        MuiTabs: {
            root: {
                minHeight: 32,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: `1px solid ${GRAY_200}`,
                fontSize: '1rem',
            },
        },
    },
    props: {
        MuiLink: {
            underline: 'none',
        },
        MuiSelect: {
            IconComponent: ArrowDownIcon,
        },
    },
};

export const theme = createMuiTheme(themeOptions);

export const darkTheme = createMuiTheme({
    ...themeOptions,
    palette: {
        ...themeOptions.palette,
        type: 'dark',
    },
});
