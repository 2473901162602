import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {PasswordForm} from '../components/PasswordForm';
import {useQueryParams} from '../../../router/useQueryParams';
import {jwtTokenPayload} from '../../../auth/jwtTokenPayload';
import {useResetPasswordMutation} from '../../../graphql/graphql.types';
import {PasswordFormValues} from '../components/types';
import {Paths} from '../../../router/paths';
import {AuthMessage} from '../../../auth/AuthMessage';
import {useSnackbar} from '../../../components/snackbar/useSnackbar';
import {lang} from '../../../lang/lang';

export const ResetPasswordPage = () => {
    const {errorNotification, successNotification} = useSnackbar();
    const history = useHistory();
    const token = useQueryParams().get('token');
    const email = useMemo(() => jwtTokenPayload<{email: string}>(token || '')?.email, [token]);
    const [resetPassword] = useResetPasswordMutation();
    const handleSubmit = useCallback(async (values: PasswordFormValues) => {
        if (!token) {
            return;
        }

        try {
            await resetPassword({
                variables: {token, newPassword: values.password},
            });

            successNotification(lang.auth.password.notification.reset);
            history.push(Paths.SIGN_IN);
        } catch (e) {
            errorNotification(lang.auth.password.notification.resetFailed);
        }
    }, [errorNotification, history, resetPassword, successNotification, token]);

    if (!token || !email) {
        return (
            <AuthMessage title={lang.auth.password.tokenFailure.resetTitle}>
                {lang.auth.password.tokenFailure.description}
            </AuthMessage>
        );
    }

    return <PasswordForm email={email} onSubmit={handleSubmit} title={lang.auth.password.resetTitle} />;
};
