import {IconButton, makeStyles, SvgIcon} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import FeedIcon from '../../../assets/icons/feed.svg';
import {TechnologyFeedDrawer} from './TechnologyFeedDrawer';

const useStyles = makeStyles({
    icon: {
       fill: '#fff',
    },
});

export const TechnologyFeedButton = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    return (
        <>
            <IconButton onClick={handleOpen}>
                <SvgIcon className={classes.icon}>
                    <FeedIcon />
                </SvgIcon>
            </IconButton>
            <TechnologyFeedDrawer onClose={handleClose} open={open} />
        </>
    );
};
