import {useMemo, useState} from 'react';
import {Order} from '../graphql/graphql.types';

export const useSorting = (initialField: string, initialOrder: Order) => {
    const [field, setField] = useState(initialField);
    const [order, setOrder] = useState(initialOrder);

    const sorting = useMemo(() => ({field, order}), [field, order]);

    return {sorting, setField, setOrder};
};
