import {RouteConfig} from '../../router/RouteConfig';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {lang} from '../../lang/lang';

export const targetingRoutes = (): RouteConfig => ({
    path: 'targeting',
    component: lazy(() => import('./TargetingPage').then(defaultExport('TargetingPage'))),
    title: lang.targeting.title,
});
