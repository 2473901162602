import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {useTechnologyQuery} from '../../../graphql/graphql.types';
import {usePagination} from '../../../components/usePagination';
import {DomainsTable} from '../../domain/list/DomainsTable';
import {TitleContext} from './TitleContext';
import {plural} from '../../../lang/plural';
import {lang} from '../../../lang/lang';

export const DomainsOfTechnology = () => {
    const {technologyId} = useParams<{technologyId: string}>();
    const pagination = usePagination();
    const {data, loading} = useTechnologyQuery({
        variables: {
            technologyId,
            pagination: {
                page: pagination.page,
                perPage: pagination.perPage,
            },
        },
    });
    const {setTitle} = useContext(TitleContext);
    useEffect(() => {
        const name = data?.domainsOfTechnology?.technology?.name;

        if (name) {
            setTitle(plural(lang.technology.details.technology, {name}));
        }

        pagination.setPaginatedResponse(data?.domainsOfTechnology);
    }, [data?.domainsOfTechnology]);

    return (
        <DomainsTable
            domains={data?.domainsOfTechnology?.domains || []}
            loading={loading}
            pagination={pagination}
        />
    );
};
