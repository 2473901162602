import {Box, Button, Grid, Typography} from '@material-ui/core';
import React, {useCallback, useMemo, useState} from 'react';
import {useField} from 'formik';
import {lang} from '../../../../lang/lang';
import {TableDialog} from '../../../../components/tableDialog/TableDialog';
import {Category, useCategoriesQuery} from '../../../../graphql/graphql.types';
import {Table} from '../../../../components/table/Table';
import {TableConfig} from '../../../../components/table/types';
import {plural} from '../../../../lang/plural';

const calculateCategoryKey = (category: Category) => category.id;

export const CategoriesTableField = () => {
    const {data, loading} = useCategoriesQuery();
    const categories = data?.categories?.categories || [];
    const [,, {setValue}] = useField('categoryIds');
    const [addedCategories, setAddedCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const handleAdd = useCallback((categories: Category[]) => {
        setAddedCategories(categories);
        setValue(categories.map((category) => category.id));
    }, [setValue]);
    const handleRemove = useCallback(() => {
        setAddedCategories((prev) => {
            const next = prev.filter((category) => {
                return !selectedCategories.includes(category);
            });

            setValue(next.map((category) => category.id));

            return next;
        });

        setSelectedCategories([]);
    }, [selectedCategories, setValue]);
    const tableConfig: TableConfig<Category>[] = useMemo(() => [
        {
            name: 'name',
            title: lang.admin.users.create.access.categories.tableTitle,
        },
    ], []);

    const tableDialog = (
        <TableDialog<Category>
            addButtonText={lang.admin.users.create.access.categories.add}
            chooseText={lang.admin.users.create.access.categories.select}
            dialogTitleText={lang.admin.users.create.access.categories.dialogTitle}
            onSelect={handleAdd}
            openButtonText={lang.admin.users.create.access.categories.open}
            items={categories}
            tableTitleText={lang.admin.users.create.access.categories.tableTitle}
        />
    );

    return (
        <Box mt={5}>
            {addedCategories.length > 0 ? (
                <>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            {plural(
                                lang.admin.users.create.access.categories.categoriesCount,
                                {count: categories.length},
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item spacing={8}>
                                    <Button
                                        color="secondary"
                                        disabled={selectedCategories.length === 0}
                                        onClick={handleRemove}
                                        variant="text"
                                    >
                                        {lang.admin.users.create.access.categories.remove}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {tableDialog}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Table<Category>
                        calculateKey={calculateCategoryKey}
                        configs={tableConfig}
                        items={addedCategories}
                        loading={loading}
                        onSelect={setSelectedCategories}
                        selectedItems={selectedCategories}
                    />
                </>
            ) : (
                <>
                    <Typography variant="subtitle1">
                        {lang.admin.users.create.access.categories.noCategories}
                    </Typography>
                    {tableDialog}
                </>
            )}
        </Box>
    );
};
