import {createContext} from 'react';

type LocaleContextType = {
    changeLocale: (locale: string) => Promise<void>;
    locale: string;
};

export const LocaleContext = createContext<LocaleContextType>({
    changeLocale: async () => {},
    locale: 'en-US',
});
