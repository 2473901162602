import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import {Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Domain} from "../../../graphql/graphql.types";
import {plural} from "../../../lang/plural";
import {lang} from "../../../lang/lang";

type Props = {
    inputValue: string;
    option: Domain;
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
    },
    name: {
        flex: 1,
    },
    highlight: {
        fontWeight: 700,
    },
});

export const SetupDomainOption = ({inputValue, option}: Props) => {
    const classes = useStyles();
    const matches = match(option.domain, inputValue);
    const parts = parse(option.domain, matches);
    const technologiesText = plural(
        lang.dashboard.setup.technologiesCount,
        {technologies: option.technologiesCount?.toString() ?? '0'},
    );

    return (
        <div className={classes.container}>
            <span className={classes.name}>
                {parts.map((part, index) => (
                    <span
                        key={index}
                        className={clsx({[classes.highlight]: part.highlight})}
                    >
                    {part.text}
                </span>
                ))}
            </span>
            <Chip label={technologiesText} size="small"/>
        </div>
    );
};
