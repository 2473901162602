import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {TechnologySubscriptionContext} from '../../../components/history/subscription/TechnologySubscriptionContext';
import {TechnologySubscriptionsTable} from './TechnologySubscriptionsTable';
import {Technology, useTechnologiesQuery} from '../../../graphql/graphql.types';
import {TechnologySubscriptionsHeader} from './TechnologySubscriptionsHeader';
import {TechnologySubscriptionsFooter} from './TechnologySubscriptionsFooter';

export const TechnologySubscriptionsPage = () => {
    const {loading, onReplace, technologyUuids} = useContext(TechnologySubscriptionContext);
    const {data, loading: technologiesLoading} = useTechnologiesQuery({
        variables: {
            pagination: {page: 1, perPage: 1000},
        },
    });
    const technologies = data?.technologies?.technologies || [];

    const [selectedTechnologies, setSelectedTechnologies] = useState<Technology[]>([]);
    const [nextTechnologyUuids, setNextTechnologyUuids] = useState(() => [...technologyUuids]);
    const unsubscribedTechnologies = useMemo(() => {
        return technologies.filter((technology) => !nextTechnologyUuids.includes(technology.id));
    }, [nextTechnologyUuids, technologies]);

    const hasChanges = useMemo(() => {
        return nextTechnologyUuids.some((technologyUuid, index) => technologyUuids[index] !== technologyUuid);
    }, [nextTechnologyUuids, technologyUuids]);
    useEffect(() => {
        setNextTechnologyUuids([...technologyUuids]);
    }, [technologyUuids]);
    useEffect(() => {
        setSelectedTechnologies([]);
    }, [nextTechnologyUuids, setSelectedTechnologies]);

    const [editMode, setEditMode] = useState(false);
    const handleManage = useCallback(() => setEditMode(true), [setEditMode]);
    const handleCancel = useCallback(() => {
        setEditMode(false);
        setNextTechnologyUuids([...technologyUuids]);
    }, [setEditMode, setNextTechnologyUuids, technologyUuids]);

    const handleAdd = useCallback((technologies: Technology[]) => {
        setSelectedTechnologies((prev) => [...prev, ...technologies]);
        setNextTechnologyUuids((prev) => [...prev, ...technologies.map((technology) => technology.id)]);
    }, [selectedTechnologies]);
    const handleRemove = useCallback(() => {
        setNextTechnologyUuids((prev) => {
            return prev.filter((technologyUuid) => {
                return selectedTechnologies.every((technology) => technology.id !== technologyUuid);
            });
        });
    }, [selectedTechnologies, technologyUuids]);

    const handleSave = useCallback(() => {
        onReplace(nextTechnologyUuids);
        setEditMode(false);
    }, [nextTechnologyUuids, onReplace, setEditMode]);

    return (
        <>
            <TechnologySubscriptionsHeader
                onAdd={handleAdd}
                editMode={editMode}
                hasSelection={selectedTechnologies.length > 0}
                onManage={handleManage}
                onRemove={handleRemove}
                technologiesCount={technologyUuids.length}
                unsubscribedTechnologies={unsubscribedTechnologies}
            />
            <TechnologySubscriptionsTable
                editMode={editMode}
                loading={loading || technologiesLoading}
                onSelect={setSelectedTechnologies}
                selectedTechnologies={selectedTechnologies}
                technologyUuids={nextTechnologyUuids}
                technologies={technologies}
            />
            <TechnologySubscriptionsFooter
                editMode={editMode}
                hasChanges={hasChanges}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        </>
    );
};
