import React, {useCallback, useMemo} from 'react';
import {MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingSelect} from './inputs/TargetingSelect';
import {TargetingFilterOperator} from './types';
import {stripes} from './styles';
import {lang} from '../../lang/lang';

type Props = {
    operators: readonly TargetingFilterOperator[];
    onChange: (operator: TargetingFilterOperator) => void;
    value: TargetingFilterOperator;
};

const useStyles = makeStyles((theme) => ({
    operatorDropdownContainer: {
        position: 'relative',
        '&::after': {
            ...stripes(theme),
            top: 20,
            right: 0,
        },
    },
    operatorRoot: {
        backgroundColor: `${theme.palette.primary.light} !important`,
    },
}));

export const FilterOperatorDropdown = ({onChange, operators, value}: Props) => {
    const OPERATORS_MAP: {[key in TargetingFilterOperator]: string} = useMemo(() => ({
        '=': lang.targeting.filter.operatorDropdown.equals,
        '!=': lang.targeting.filter.operatorDropdown.notEquals,
        '>': lang.targeting.filter.operatorDropdown.greaterThen,
        '>=': lang.targeting.filter.operatorDropdown.greaterThenOrEquals,
        '<': lang.targeting.filter.operatorDropdown.lesserThen,
        '<=': lang.targeting.filter.operatorDropdown.lesserThenOrEquals,
        'ONE_OF': lang.targeting.filter.operatorDropdown.oneOf,
        'ALL_OF': lang.targeting.filter.operatorDropdown.allOf,
        'EXCLUDE_ALL': lang.targeting.filter.operatorDropdown.excludeAll,
        'BETWEEN': lang.targeting.filter.operatorDropdown.between,
    }), []);

    const classes = useStyles();
    const handleChange = useCallback((value: string) => {
        onChange(value as TargetingFilterOperator);
    }, [onChange]);

    return (
        <div className={classes.operatorDropdownContainer}>
            <TargetingSelect classes={{root: classes.operatorRoot}} onChange={handleChange} value={value}>
                {operators.map((operator) => (
                    <MenuItem key={operator} value={operator}>{OPERATORS_MAP[operator]}</MenuItem>
                ))}
            </TargetingSelect>
        </div>
    );
};
