import {Customer} from '../../graphql/graphql.types';
import {createContext} from 'react';

type CustomerContextType = {
    customer: Customer | null;
    loading: boolean;
    reload: () => void;
};

export const CustomerContext = createContext<CustomerContextType>({
    customer: null,
    loading: false,
    reload: () => {},
});
