import React, {useContext, useEffect} from 'react';
import {RouteProps, useHistory} from 'react-router-dom';
import {UserRole} from '../graphql/graphql.types';
import {RolesContext} from '../auth/RolesContext';
import {Paths} from './paths';

type Props = RouteProps & {
    accessibleTo: UserRole[];
};

export const RolesRoute = ({accessibleTo, ...props}: Props) => {
    const {loaded, roles} = useContext(RolesContext);
    const history = useHistory();

    useEffect(() => {
        if (!loaded || !roles) {
            return;
        }

        if (roles.every((role) => !accessibleTo.includes(role))) {
            history.push(Paths.ROOT);}
    }, [loaded, roles]);

    return <>{props.children}</>;
};
