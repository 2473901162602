import {DateTargetingFilterValue, TargetingFilterState} from '../../components/targetingFilter/types';

export const hasTechnologies = (filterValues: TargetingFilterState[]): boolean => {
    if (filterValues.length === 0) {
        return false;
    }

    return filterValues.every((filterValue) => {
        if ('state' in filterValue) {
            return hasTechnologies(filterValue.state);
        }

        if (filterValue.filter.name === 'FOUND_AT') {
            return (filterValue.value as DateTargetingFilterValue).value.every(Boolean);
        }

        if (filterValue.filter.name !== 'TECHNOLOGY') {
            return true;
        }

        return 'value' in filterValue &&
            Array.isArray(filterValue.value) &&
            filterValue.value.length > 0;
    });
};
