import React, {useCallback, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ChangePasswordContainer} from './ChangePasswordContainer';
import {ForgotPassword} from './ForgotPassword';
import {lang} from '../../../../lang/lang';

type Props = {
    email: string;
};

const useStyles = makeStyles({
    accordion: {
        '&::before': {
            content: 'initial',
        },
        '&$accordionExpanded': {
            margin: 0,
        },
    },
    accordionDetails: {
        padding: 0,
    },
    accordionDetailsContainer: {
        width: '100%',
    },
    accordionExpanded: {},
    accordionSummary: {
        padding: 0,
    },
    buttonExpanded: {
        margin: '0 !important',
        minHeight: '0 !important',
        transform: 'initial !important',
    },
});

export const ChangePasswordAccordion = ({email}: Props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const handleExpand = useCallback((event, expanded) => setExpanded(expanded), [setExpanded]);

    return (
        <>
            <Box mt={3} mb={1}>
                <Divider />
            </Box>
            <Accordion
                classes={{expanded: classes.accordionExpanded, root: classes.accordion}}
                elevation={0}
                expanded={expanded}
                onChange={handleExpand}
            >
                <AccordionSummary
                    aria-controls="change-password-summary"
                    classes={{expanded: classes.buttonExpanded, root: classes.accordionSummary}}
                    id="change-password-header"
                    expandIcon={(
                        <Button color="secondary">
                            {expanded ? lang.auth.password.accordion.hide : lang.auth.password.accordion.change}
                        </Button>
                    )}
                >
                    <Typography variant="h6">{lang.auth.password.label}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.accordionDetails}} id="change-password-summary">
                    <div className={classes.accordionDetailsContainer}>
                        <ChangePasswordContainer />
                        <ForgotPassword email={email} />
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
