import {useHistory} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {LocationListener} from 'history';
import {Paths} from '../../../router/paths';
import {createDrawerItems} from "./createDrawerItems";

export const useDrawerLocation = () => {
    const history = useHistory();

    const [isDrawerLocation, setIsDrawerLocation] = useState(true);

    const [lastDrawerLocation, setLastDrawerLocation] = useState<string>(Paths.TARGETING);
    const [drawerConfiguration, setDrawerConfiguration] = useState(() => createDrawerItems(history.location));

    const handleLocation: LocationListener = useCallback((location) => {
        const nextDrawerConfiguration = createDrawerItems(location);
        setDrawerConfiguration(nextDrawerConfiguration);
        const drawerLocation = nextDrawerConfiguration.items.find(({to}) => location.pathname.startsWith(to));
        setIsDrawerLocation(drawerLocation !== undefined);

        if (drawerLocation && !nextDrawerConfiguration.showBackButton) {
            setLastDrawerLocation(location.pathname);
        }
    }, [setIsDrawerLocation, setLastDrawerLocation]);

    useEffect(() => {
        const unregister = history.listen(handleLocation);

        handleLocation(history.location, 'PUSH');

        return unregister;
    }, [handleLocation, history]);

    return {drawerConfiguration, isDrawerLocation, lastDrawerLocation};
};
