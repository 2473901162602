import React, {useCallback, useContext, useMemo} from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {TechnologySubscriptionContext} from '../../../components/history/subscription/TechnologySubscriptionContext';
import {lang} from '../../../lang/lang';

type Props = {
    technologyUuid: string;
};

const useStyles = makeStyles((theme) => ({
    starIcon: {
        fill: theme.palette.warning.main,
    },
}));

export const TechnologySubscription = ({technologyUuid}: Props) => {
    const classes = useStyles();

    const {loading, onSubscribe, onUnsubscribe, technologyUuids} = useContext(TechnologySubscriptionContext);
    const isSubscribed = useMemo(() => technologyUuids.includes(technologyUuid), [technologyUuids, technologyUuid]);

    const handleClick = useCallback(() => {
        return isSubscribed ? onUnsubscribe(technologyUuid) : onSubscribe(technologyUuid);
    }, [isSubscribed, onSubscribe, onUnsubscribe, technologyUuid]);

    return (
        <Tooltip
            arrow
            placement="bottom-end"
            title={isSubscribed ? lang.technology.table.unsubscribe : lang.technology.table.subscribe}
        >
            <IconButton disabled={loading} onClick={handleClick} size="small">
                {isSubscribed ? <StarIcon className={classes.starIcon} /> : <StarBorderIcon />}
            </IconButton>
        </Tooltip>
    );
};
