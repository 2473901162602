import React from 'react';
import {Box, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {lang} from '../../lang/lang';

type Props = {
    disabled: boolean;
    fetchedAll: boolean;
    onFetchMore: () => void;
};

const useStyles = makeStyles((theme) => ({
    fetchMoreContainer: {
        backgroundColor: '#fff',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    fetchMoreLink: {
        cursor: 'pointer',
    },
}));

export const FetchMorePaginator = ({disabled, fetchedAll, onFetchMore}: Props) => {
    const classes = useStyles();

    return fetchedAll ? null : (
        <Box className={classes.fetchMoreContainer}>
            <Button
                className={classes.fetchMoreLink}
                disabled={disabled}
                onClick={onFetchMore}
            >
                {lang.table.pagination.loadMore}
            </Button>
        </Box>
    );
};
