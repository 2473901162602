export default {
    "details": {
        "technology": "テクノロジー{name}",
        "title": "テクノロジー",
        "domainsTab": "ドメイン",
        "historyTab": "履歴",
        "domains": {
            "title": "テクノロジードメイン"
        },
        "history": {
            "title": "テクノロジー履歴"
        }
    },
    "list": {
        "title": "テクノロジー",
        "categories": "カテゴリー",
        "name": "名前"
    },
    "table": {
        "technology": "テクノロジー",
        "category": "カテゴリー",
        "domainsCount": "ドメイン数",
        "history": "履歴",
        "subscribe": "登録",
        "unsubscribe": "解約"
    }
};
