import React, {useCallback, useState} from 'react';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ExportIcon from '../../assets/icons/export.svg';
import {useSnackbar} from '../../components/snackbar/useSnackbar';
import {lang} from '../../lang/lang';

type Props = {
    disabled: boolean;
    onExport: () => Promise<void>;
};

const useStyles = makeStyles((theme) => ({
    exportIcon: {
        fill: 'currentColor',
    },
}));

export const TargetingExportButton = ({disabled, onExport}: Props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const {errorNotification} = useSnackbar();
    const handleExport = useCallback(async () => {
        setLoading(true);

        try {
            await onExport();
        } catch (e) {
            errorNotification(lang.targeting.notification.exportFailed);
        } finally {
            setLoading(false);
        }
    }, [errorNotification, onExport, setLoading]);

    return (
        <Button color="secondary" disabled={disabled || loading} onClick={handleExport}>
            <ExportIcon className={classes.exportIcon} />
            {lang.targeting.export.button}
        </Button>
    );
};
