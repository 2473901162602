import {Box, Button, Divider, Grid, Paper} from '@material-ui/core';
import {Form, Formik} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {PageTitle} from '../../../../components/layout/PageTitle';
import {Paths} from '../../../../router/paths';
import {lang} from '../../../../lang/lang';
import {NavigationTab} from '../../../../components/navigation/types';
import {NavigationTabs} from '../../../../components/navigation/NavigationTabs';
import {CreateUserProfileFormFields} from './CreateUserProfileFormFields';
import {CreateUserAccessFormFields} from './CreateUserAccessFormFields';
import {CreateUserRequest, useCreateUserMutation, UserRole} from '../../../../graphql/graphql.types';
import {useSnackbar} from '../../../../components/snackbar/useSnackbar';
import {createUserValidationSchema} from './createUserValidationSchema';

const initialValues: CreateUserRequest = {
    email : '',
    firstName: '',
    lastName: '',
    userRole: UserRole.CLIENT,
    businessRole: '',
    companyName: '',
    companyPhone: '',
    isAlertsEnabled: false,
    isFeedsEnabled: false,
    isTargetingCSVExportEnabled: false,
    isAllCategoriesEnabled: false,
    categoryIds: [],
};

export const CreateUserLayout = () => {
    const [tab, setTab] = useState('profile');
    const tabs: NavigationTab[] = useMemo(() => [
        {
            path: 'profile',
            label: lang.admin.users.create.profile.title,
        },
        {
            path: 'access',
            label: lang.admin.users.create.access.title,
        },
    ], []);
    const {errorNotification, successNotification} = useSnackbar();
    const [createUser, {loading}] = useCreateUserMutation();
    const handleSubmit = useCallback(async (values: CreateUserRequest) => {
        try {
            await createUser({variables: values});

            successNotification(lang.admin.users.create.success);
        } catch {
            errorNotification(lang.admin.users.create.error);
        }
    }, [createUser, errorNotification, successNotification]);
    const history = useHistory();
    const handleCancel = useCallback(() => history.push(Paths.USERS), [history]);
    
    return (
        <Formik<CreateUserRequest>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={createUserValidationSchema}
        >
            {({isValid}) => (
                <Form>
                    <PageTitle backTo={Paths.USERS}>{lang.admin.users.create.title}</PageTitle>
                    <Paper>
                        <Box p={5}>
                            <NavigationTabs onChange={setTab} path={tab} tabs={tabs} />
                            {tab === 'profile' ? <CreateUserProfileFormFields /> : <CreateUserAccessFormFields />}
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Grid container justify="flex-end" spacing={2}>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={loading}
                                        onClick={handleCancel}
                                        variant="text"
                                    >
                                        {lang.admin.users.create.cancel}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={loading || !isValid}
                                        variant="contained"
                                        type="submit"
                                    >
                                        {lang.admin.users.create.createUser}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Form>
            )}
        </Formik>
    );
};
