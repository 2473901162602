import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Typography} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link} from '../components/navigation/Link';
import {Paths} from '../router/paths';
import {lang} from '../lang/lang';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

type Props = {
    children: ReactNode;
    showSignInLink?: boolean;
    showSignUpLink?: boolean;
    title: ReactNode | string;
};

export const AuthMessage = ({children, showSignInLink = false, showSignUpLink = false, title}: Props) => {
    const classes = useStyles();

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <AccountCircleIcon color="primary" fontSize="large" />
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{children}</Typography>
            {showSignInLink && <Link to={Paths.SIGN_IN}>{lang.auth.signIn.link}</Link>}
            {showSignUpLink && <Link to={Paths.SIGN_UP}>{lang.auth.signUp.link}</Link>}
        </Container>
    );
};
