import React, {useEffect, useState} from 'react';

import {useDomainsQuery} from '../../../graphql/graphql.types';
import {usePagination} from '../../../components/usePagination';
import {DomainsTable} from './DomainsTable';
import {PageTitle} from '../../../components/layout/PageTitle';
import {lang} from '../../../lang/lang';

export const Domains = () => {
    const [nameFilter, setNameFilter] = useState('');
    const pagination = usePagination();
    const {data, loading} = useDomainsQuery({
        variables: {
            nameFilter,
            pagination: {
                page: pagination.page,
                perPage: pagination.perPage,
            },
        },
    });
    useEffect(() => pagination.setPaginatedResponse(data?.domains), [data?.domains]);

    return (
        <>
            <PageTitle>{lang.domain.title}</PageTitle>
            <DomainsTable
                domains={data?.domains?.domains || []}
                loading={loading}
                onFilter={setNameFilter}
                pagination={pagination}
            />
        </>
    );
};
