import {
    Technology,
    useDashboardDomainsHistoryQuery,
    useTechnologiesOfDashboardDomainsQuery
} from "../../graphql/graphql.types";
import React, {useCallback, useMemo, useState} from "react";
import {DashboardItem} from "./main/DashboardItem";
import {DashboardLoader} from "./DashboardLoader";

export const Dashboard = () => {
    const {data: historyData, loading: historyLoading} = useDashboardDomainsHistoryQuery();
    const {data: technologyData, loading: technologyLoading} = useTechnologiesOfDashboardDomainsQuery();
    const technologiesByDomain = useMemo(() => {
        const map = new Map<string, Map<string, Technology[]>>();

        return technologyData?.technologiesOfDashboardDomains?.reduce((carry, current) => {
            if (!carry.has(current.domain.domain)) {
                carry.set(current.domain.domain, new Map());
            }

            const categoryMap = carry.get(current.domain.domain)!;

            for (const technology of current.technologies) {
                if (!categoryMap.has(technology.category!.id)) {
                    categoryMap.set(technology.category!.id, []);
                }

                categoryMap.get(technology.category!.id)!.push(technology as Technology);
            }

            return carry;
        }, map) ?? map;
    }, [technologyData]);

    const [expanded, setExpanded] = useState(new Map<string, boolean>());
    const handleExpand = useCallback((domain: string, expanded: boolean) => {
        setExpanded((current) => {
            return new Map(current).set(domain, expanded);
        });
    }, []);

    return historyLoading || technologyLoading ? (
        <DashboardLoader />
    ) : (
        <>
            {historyData?.dashboardDomainsHistory.map((history) => (
                <DashboardItem
                    key={history.domain}
                    domain={history.domain}
                    expanded={expanded.get(history.domain) === true}
                    histories={history.categories}
                    onExpand={handleExpand}
                    technologies={technologiesByDomain.get(history.domain) ?? new Map()}
                />
            ))}
        </>
    );
};
