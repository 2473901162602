import React, {ReactNode} from 'react';
import {Form, Formik} from 'formik';
import {Avatar, Button, Container, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {TextInputField} from '../../../components/formik/TextInputField';
import {Link} from '../../../components/navigation/Link';
import {confirmEmailValidationSchema} from '../confirmEmail/confirmEmailValidationSchema';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';

type Props = {
    buttonText: ReactNode;
    onSubmit: (values: {email: string}) => Promise<void>;
    title: ReactNode;
};

const initialValues = {email: ''};

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signInButton: {
        marginTop: theme.spacing(1),
    },
}));

export const EmailForm = ({buttonText, onSubmit, title}: Props) => {
    const classes = useStyles();

    return (
        <Formik<{email: string}>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={confirmEmailValidationSchema}
        >
            {({isSubmitting}) => (
                <Form>
                    <Container className={classes.container} component="main" maxWidth="xs">
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h4">
                            {title}
                        </Typography>
                        <TextInputField
                            autoComplete="email"
                            autoFocus
                            fullWidth
                            label={lang.auth.email}
                            margin="normal"
                            name="email"
                            type="email"
                        />
                        <Button
                            className={classes.signInButton}
                            color="secondary"
                            disabled={isSubmitting}
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            {buttonText}
                        </Button>
                        <Typography color="textSecondary">{lang.auth.signUp.or}</Typography>
                        <Link to={Paths.SIGN_IN}>{lang.auth.signUp.signInLink}</Link>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};
