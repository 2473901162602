import React, {useCallback} from 'react';
import {useHistory, generatePath} from 'react-router-dom';
import {IconButton, Tooltip} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

import {Domain} from '../../../graphql/graphql.types';
import {Paths} from '../../../router/paths';

export const DomainActionsRenderer = ({item: domain}: {item: Domain}) => {
    const history = useHistory();
    const handleHistoryClick = useCallback(() => {
        history.push(generatePath(Paths.DOMAIN_HISTORY, {domain: domain.domain}));
    }, [domain, history]);

    return (
        <Tooltip arrow title='History' placement='bottom-end'>
            <IconButton onClick={handleHistoryClick} size="small">
                <HistoryIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
};
