import React, {PropsWithChildren, useEffect, useMemo} from "react";
import {NavigationTab} from "../../../components/navigation/types";
import {lang} from "../../../lang/lang";
import {Paths} from "../../../router/paths";
import {useHistory, useLocation} from "react-router-dom";
import {RouterNavigationTabs} from "../../../components/navigation/RouterNavigationTabs";

export const ApiPage = ({children}: PropsWithChildren<{}>) => {
    const apiTabs: NavigationTab[] = useMemo(() => [
        {
            label: lang.settings.api.tokensTitle,
            path: Paths.SETTINGS_API_TOKENS,
        },
        {
            label: lang.settings.api.playgroundTitle,
            path: Paths.SETTINGS_API_PLAYGROUND,
        },
    ], []);

    const history = useHistory();
    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname === Paths.SETTINGS_API) {
            history.push(apiTabs[0].path);
        }
    }, [apiTabs, history, pathname]);

    return (
        <>
            <RouterNavigationTabs tabs={apiTabs} />
            {children}
        </>
    );
};
