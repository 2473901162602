import React, {useCallback} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {TableConfig} from './types';
import {TableCheckbox} from './TableCheckbox';

type Props<T> = {
    checked: boolean;
    configs: TableConfig<T>[];
    item: T;
    onSelect: ((item: T) => void) | undefined;
};

export function TableItem<T>({checked, configs, item, onSelect}: Props<T>) {
    const handleSelect = useCallback(() => {
        onSelect?.(item);
    }, [item, onSelect]);

    return (
        <TableRow hover>
            {onSelect ? (
                <TableCheckbox checked={checked} onSelect={handleSelect} />
            ) : null}
            {configs.map((config) => {
                if (!config.render) {
                    return <TableCell key={config.name}>{item[config.name as keyof typeof item]}</TableCell>
                }

                const Component = config.render;

                return (
                    <TableCell key={config.name} align={config.align || 'left'}>
                        <Component item={item} />
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
