import React, {ChangeEvent, useCallback} from 'react';
import {Select, SelectProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

type Props = Omit<SelectProps, 'onChange' | 'variant'> & {
    onChange: (value: string) => void;
};

const useStyles = makeStyles((theme) => ({
    select: {
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
        minWidth: 150,

        '&::before': {
            content: 'initial',
        },
        '&::after': {
            content: 'initial',
        },
    },
    root: {
        borderRadius: `${theme.shape.borderRadius}px !important`,
        paddingTop: 10,
    },
}));

export const TargetingSelect = ({children, onChange, ...props}: Props) => {
    const classes = useStyles();
    const handleChange = useCallback(({target: {value}}: ChangeEvent<{value: unknown}>) => {
        onChange(value as string);
    }, [onChange]);

    return (
        <Select
            {...props}
            className={clsx(classes.select, props.className)}
            classes={{
                ...props.classes,
                root: clsx(classes.root, props.classes?.root),
            }}
            onChange={handleChange}
            variant="filled"
        >
            {children}
        </Select>
    );
}
