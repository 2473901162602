import React, {useEffect, useState} from 'react';
import {usePagination} from '../../../components/usePagination';
import {useCategoriesQuery, useTechnologiesQuery} from '../../../graphql/graphql.types';
import {TechnologiesFilterType} from './TechnologiesFilter';
import {TechnologiesTable} from './TechnologiesTable';
import {PageTitle} from '../../../components/layout/PageTitle';
import {lang} from '../../../lang/lang';

export const Technologies = () => {
    const pagination = usePagination();
    const [technologiesFilter, setTechnologiesFilter] = useState<TechnologiesFilterType>();
    const {data: categoriesResponse} = useCategoriesQuery();
    const {data, loading} = useTechnologiesQuery({
        variables: {
            ...technologiesFilter,
            pagination: {
                page: pagination.page,
                perPage: pagination.perPage,
            },
        },
    });
    useEffect(() => pagination.setPaginatedResponse(data?.technologies), [data?.technologies]);

    return (
        <>
            <PageTitle>{lang.technology.list.title}</PageTitle>
            <TechnologiesTable
                categories={categoriesResponse?.categories?.categories || []}
                loading={loading}
                onTechnologiesFilter={setTechnologiesFilter}
                pagination={pagination}
                technologies={data?.technologies?.technologies || []}
            />
        </>
    );
};
