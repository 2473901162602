import React, {useMemo} from "react";
import {GraphiQL} from 'graphiql';
import 'graphiql/graphiql.css';
import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {makeStyles} from "@material-ui/core/styles";
import {useLocation} from "react-router-dom";
import {ApiConfig} from "../../../../config/api.config";
import DomainsQuery from '../../../../graphql/query/domains.graphql';
import DomainHistoryQuery from '../../../../graphql/query/history/domainHistory.graphql';
import DomainTechnologiesQuery from '../../../../graphql/query/technologyDomain.graphql';
import TargetingQuery from '../../../../graphql/query/targeting.graphql';
import TechnologiesQuery from '../../../../graphql/query/technologies.graphql';
import TechnologyHistoryQuery from '../../../../graphql/query/history/technologyHistory.graphql';
import TechnologyDomainsQuery from '../../../../graphql/query/technology.graphql';
import {
    DomainHistoryQueryVariables,
    DomainsQueryVariables,
    TargetingQueryVariables, TechnologiesQueryVariables, TechnologyDomainQueryVariables, TechnologyHistoryQueryVariables
} from "../../../../graphql/graphql.types";

const useStyles = makeStyles({
    container: {
        height: 600,
    },
});

const TABS = [
    {
        query: TargetingQuery,
        variables: {
            filters: [
                {
                    conditions: [
                        {
                            filter: 'ACTION',
                            operator: 'EQUAL',
                            value: ['ADDED']
                        },
                    ],
                },
                {
                    conditions: [
                        {
                            filter: 'TECHNOLOGY',
                            operator: 'ONE_OF',
                            value: ['3e7efcb5-9518-46ab-b9eb-89103052d1f4'],
                        },
                    ],
                }
            ],
        } as TargetingQueryVariables,
    },
    {
        query: DomainsQuery,
        variables: {
            nameFilter: '',
            pagination: {
                page: 1,
                perPage: 100,
            },
            sorting: {
                field: 'name',
                order: 'ASC',
            },
        } as DomainsQueryVariables,
    },
    {
        query: DomainHistoryQuery,
        variables: {
            domain: 'honda.co.jp',
        } as DomainHistoryQueryVariables,
    },
    {
        query: DomainTechnologiesQuery,
        variables: {
            domain: 'honda.co.jp',
            pagination: {
                page: 1,
                perPage: 100,
            },
            sorting: {
                field: 'technology',
                order: 'ASC',
            },
        } as TechnologyDomainQueryVariables,
    },
    {
        query: TechnologiesQuery,
        variables: {
            categoryIds: null,
            nameFilter: null,
            pagination: {
                page: 1,
                perPage: 100,
            },
            sorting: {
                field: 'technology',
                order: 'ASC',
            },
        } as TechnologiesQueryVariables,
    },
    {
        query: TechnologyHistoryQuery,
        variables: {
            technologyId: '66801c4f-764d-4906-abb8-6ddba45841b6',
        } as TechnologyHistoryQueryVariables,
    },
    {
        query: TechnologyDomainsQuery,
        variables: {
            technologyId: '66801c4f-764d-4906-abb8-6ddba45841b6',
            pagination: {
                page: 1,
                perPage: 100,
            },
            sorting: {
                field: 'technology',
                order: 'ASC',
            },
        } as TechnologiesQueryVariables,
    },
].map((tab) => ({...tab, variables: JSON.stringify(tab.variables, null, 4)}));

export const ApiPlaygroundPage = () => {
    const location = useLocation<{token: string}>();
    const authHeader = location?.state?.token
        ? `{"Authorization": "Bearer ${location.state.token}"}`
        : undefined;
    const classes = useStyles();
    const fetcher = useMemo(() => {
        return createGraphiQLFetcher({
            url: `${ApiConfig.clientApiEndpoint}/graphql`,
        });
    }, []);

    return (
        <div className={classes.container}>
            <GraphiQL
                defaultTabs={TABS}
                fetcher={fetcher}
                headers={authHeader}
            />
        </div>
    );
};
