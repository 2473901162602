import {ListItem, ListItemIcon, ListItemText, SvgIcon, Tooltip} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

type Props = {
    children: ReactNode;
    opened: boolean;
    icon: ReactNode;
    to: string;
};

const useStyles = makeStyles((theme) => ({
    drawerItem: {
        borderRadius: theme.shape.borderRadius,
        height: 45,
        '&:hover': {
            background: theme.palette.grey['300'],
        },
    },
    drawerItemIcon: {
        minWidth: 36,
        transition: theme.transitions.create('transform'),
        '&$drawerItemIconClosed': {
            transform: `translateX(${theme.spacing(-1)}px)`,
        },
    },
    drawerItemIconClosed: {},
    drawerItemText: {
        opacity: 1,
        transition: theme.transitions.create('opacity'),
    },
    drawerItemTextClosed: {
        opacity: 0,
    },
    link: {
        color: 'inherit',
        display: 'block',
        textDecoration: 'inherit',
    },
    linkActive: {
        '& $drawerItem': {
            background: theme.palette.grey['300'],
            color: theme.palette.secondary.main,
            '& $drawerItemText': {
                fontWeight: theme.typography.fontWeightBold,
            },
            '& $drawerItemIcon': {
                color: theme.palette.secondary.main,
            },
            '& $svgIconRoot': {
                fill: theme.palette.secondary.main,
            },
        },
    },
    svgIconRoot: {},
}));

export const DrawerItem = ({children, opened, to, icon}: Props) => {
    const classes = useStyles();

    const listItem = (
        <ListItem
            button
            className={classes.drawerItem}
            dense
        >
            <ListItemIcon className={clsx(classes.drawerItemIcon, {[classes.drawerItemIconClosed]: !opened})}>
                <SvgIcon classes={{root: classes.svgIconRoot}}>{icon}</SvgIcon>
            </ListItemIcon>
            <ListItemText
                className={clsx({[classes.drawerItemTextClosed]: !opened})}
                classes={{primary: classes.drawerItemText}}
            >
                {children}
            </ListItemText>
        </ListItem>
    );

    return (
        <NavLink activeClassName={classes.linkActive} className={classes.link} to={to}>
            {opened ? listItem : (
                <Tooltip arrow title={children as string} placement='right'>
                    {listItem}
                </Tooltip>
            )}
        </NavLink>
    );
};
