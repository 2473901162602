import React, {ComponentType} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CountryFlag from 'react-country-flag';
import {Country} from '../../../graphql/graphql.types';

const useStyles = makeStyles((theme) => ({
    countryFlag: {
        boxShadow: '0 0 3px #aaa',
        marginRight: theme.spacing(1),
        height: '1.5em',
        width: '1.4em !important',
    },
}));

export function CountryRenderer<T>(getCountry: (item: T) => Country): ComponentType<{item: T}> {
    return function({item}) {
        const classes = useStyles();
        const country = getCountry(item);

        return (
            <>
                <CountryFlag
                    className={classes.countryFlag}
                    countryCode={country.code}
                    aria-label={country.name}
                    svg
                />
                {country.name}
            </>
        );
    }
}
