export default {
    filter: {
        label: 'Filter',
    },
    pagination: {
        loadMore: 'Load more...',
        firstPage: 'First Page',
        previousPage: 'Previous Page',
        nextPage: 'Next Page',
        lastPage: 'Last Page',
        resultsPerPage: 'Results per page: ',
        results: '{first} - {last} of {total} results',
    },
};
