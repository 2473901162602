import React, {useCallback, useMemo} from 'react';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterType, TargetingFilterState} from './types';
import {useFilterValues} from './useFilterValues';
import {FilterExpression} from './expression/FilterExpression';
import {stripes} from './styles';
import {AddFilterButton} from './AddFilterButton';
import {lang} from '../../lang/lang';

type Props = {
    filters: TargetingFilterType[];
    canApply?: (filterValues: TargetingFilterState[]) => boolean;
    initialValue: TargetingFilterState[];
    onChange: (filterValues: TargetingFilterState[]) => void;
};

const useStyles = makeStyles((theme) => ({
    applyButton: {
        marginRight: theme.spacing(2),
        minWidth: 150,
    },
    buttonsContainer: {
        marginTop: theme.spacing(1),
    },
    filtersContainer: {
        position: 'relative',
        '&::after': {
            ...stripes(theme),
            top: 20,
            left: theme.spacing(-1),
            height: `calc(100% - 40px)`,
            width: 2,
        },
    },
    targetingFilterRoot: {
        marginBottom: theme.spacing(3),
    },
}));

export const TargetingFilter = ({canApply, filters, initialValue, onChange}: Props) => {
    const {
        filterValues,
        onAddFilter,
        onChangeFilter,
        onClear,
        onRemoveFilter,
    } = useFilterValues(initialValue, filters);
    const handleApplyFilters = useCallback(() => {
        onChange(filterValues);
    }, [filterValues, onChange]);
    const isApplyEnabled = useMemo(() => canApply ? canApply(filterValues) : true, [canApply, filterValues]);

    const classes = useStyles();

    const handleClearFilter = useCallback(() => {
        onClear();
        onChange([]);
    }, [initialValue, onChange, onClear]);

    return (
        <div className={classes.targetingFilterRoot}>
            <div className={classes.filtersContainer}>
                {filterValues.map((filterValue, index) => (
                    <FilterExpression
                        key={index}
                        availableFilters={filters}
                        canRemove={filterValues.length > 1}
                        filterState={filterValue}
                        index={index}
                        onChange={onChangeFilter}
                        onRemove={onRemoveFilter}
                    />
                ))}
                <AddFilterButton filters={filters} onAdd={onAddFilter} />
            </div>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.applyButton}
                    color="secondary"
                    disabled={!isApplyEnabled}
                    onClick={handleApplyFilters}
                    variant="contained"
                >
                    {lang.targeting.filter.apply}
                </Button>
                {filterValues !== initialValue ? (
                    <Button color="secondary" onClick={handleClearFilter}>
                        {lang.targeting.filter.clear}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
