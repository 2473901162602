import React, {useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    SvgIcon
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {lang} from "../../../../lang/lang";
import {ApiToken} from "../../../../graphql/graphql.types";
import {plural} from "../../../../lang/plural";

type Props = {
    disabled: boolean;
    onRemove: (tokenUuid: string) => Promise<void>;
    token: ApiToken;
};

export const ApiTokenRemoveButton = ({disabled, onRemove, token}: Props) => {
    const [showDialog, setShowDialog] = useState(false);
    const handleOpenDialog = useCallback(() => setShowDialog(true), []);
    const handleCloseDialog = useCallback(() => setShowDialog(false), []);
    const handleRemove = useCallback(async () => {
        handleCloseDialog();
        await onRemove(token.uuid);
    }, [onRemove, token]);

    return (
        <>
            <IconButton
                aria-label={lang.api.settings.table.remove}
                disabled={disabled}
                onClick={handleOpenDialog}
            >
                <SvgIcon color="error" fontSize="inherit">
                    <DeleteIcon />
                </SvgIcon>
            </IconButton>
            {showDialog && (
                <Dialog open onClose={handleCloseDialog}>
                    <DialogTitle>{lang.api.settings.table.removeDialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {plural(lang.api.settings.table.removeDialog.text, {name: token.name})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleCloseDialog}
                        >
                            {lang.api.settings.table.removeDialog.cancelButton}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleRemove}
                        >
                            {lang.api.settings.table.removeDialog.removeButton}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
};
