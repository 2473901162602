export default {
    "title": "認証",
    "signIn": {
        "link": "ログイン",
        "title": "ログイン",
        "forgotPasswordLink": "パスワードをお忘れの方",
        "signInButton": "ログイン",
        "or": "または",
        "signUpLink": "ログイン"
    },
    "signUp": {
        "link": "ログイン",
        "or": "または",
        "signInLink": "サインアップ",
        "title": "サインアップ"
    },
    "email": "eメール",
    "password": {
        "label": "パスワード",
        "repeat": "パスワード再確認",
        "current": "現在のパスワード",
        "new": "新しいパスワード",
        "save": "保存",
        "notification": {
            "created": "パスワードを作成しました",
            "creationFailed": "パスワードの作成に失敗しました",
            "changed": "パスワードは変更されました",
            "changeFailed": "パスワードの変更に失敗しました",
            "resetRequested": "パスワードをリセットするリンクをお送りしました。Eメールをご確認ください",
            "resetRequestFailed": "パスワードリセットのためのリンクを作成出来ませんでした",
            "reset": "パスワードは変更されました",
            "resetFailed": "パスワードがリセット出来ませんでした"
        },
        "tokenFailure": {
            "createTitle": "トークン不足でパスワードが作成出来ません",
            "resetTitle": "トークン不足でパスワードがリセット出来ません",
            "description": "トークンが不明・不正です"
        },
        "validation": {
            "equal": "パスワードは一致する必要があります"
        },
        "resetTitle": "パスワードのリセット",
        "formTitle": "サインアップ",
        "resetRequest": {
            "checkYourInbox": "メール受信箱をご確認ください",
            "checkYourEmail": "パスワードをリセットする為にEメールをご確認ください",
            "resetButton": "パスワードをリセット",
            "formTitle": "パスワードをリセット"
        },
        "createTitle": "パスワードの作成",
        "accordion": {
            "change": "変更",
            "hide": "非表示"
        },
        "forgot": {
            "cantRemember": "パスワードをお忘れでないですか",
            "resetPassword": "パスワードのリセット"
        }
    },
    "confirmEmail": {
        "checkYourInbox": "メール受信箱の確認",
        "checkYourEmail": "アカウント作成のためにメールの受信箱をご確認ください",
        "confirmButton": "メールの確認",
        "formTitle": "サインアップ"
    },
    "notActivated": {
        "pageTitle": "アカウントは実効化されていません",
        "title": "無効",
        "description": "アカウントの実効化をお待ちいただくか、またはご連絡ください",
        "support": "サポート"
    }
};
