import {useContext, useMemo} from 'react';
import ky from 'ky';
import {AuthContext} from '../../auth/AuthContext';

export const useRequest = () => {
    const {token} = useContext(AuthContext);
    const api = useMemo(() => {
        return ky.extend({
            hooks: {
                beforeRequest: [
                    (request) => request.headers.set('Authorization', `Bearer ${token}`),
                ],
            },
        });
    }, [token]);

    return api;
};
