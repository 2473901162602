import React, {useCallback} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import {IconButton, Tooltip} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

import {Technology} from '../../../graphql/graphql.types';
import {Paths} from '../../../router/paths';
import {TechnologySubscription} from './TechnologySubscription';
import {lang} from '../../../lang/lang';

export const TechnologyActionsRenderer = ({item: technology}: {item: Technology}) => {
    const history = useHistory();
    const handleHistoryClick = useCallback(() => {
        history.push(generatePath(Paths.TECHNOLOGY_HISTORY, {technologyId: technology.id}));
    }, [history, technology]);

    return (
        <>
            <TechnologySubscription technologyUuid={technology.id} />
            <Tooltip arrow title={lang.technology.table.history} placement="bottom-end">
                <IconButton onClick={handleHistoryClick} size="small">
                    <HistoryIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </>
    );
};
