import {lazy} from '../../../shared/lazy';
import {RouteConfig} from '../../../router/RouteConfig';
import {defaultExport} from '../../../shared/defaultExport';
import {lang} from '../../../lang/lang';
import {RouteChildren} from '../../../router/RouteChildren';

export const userRoutes = (): RouteConfig => ({
    path: 'users',
    component: RouteChildren,
    title: lang.admin.users.title,
    routes: [
        {
            path: 'create',
            component: lazy(() => import('./create/CreateUserLayout').then(defaultExport('CreateUserLayout'))),
            title: lang.admin.users.create.title,
        },
        {
            path: '.',
            component: lazy(() => import('./UsersPage').then(defaultExport('UsersPage'))),
            title: lang.admin.users.title,
        },
    ],
});
