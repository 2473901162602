export function jwtTokenPayload<T extends {[key: string]: unknown}>(token: string): T | null {
    const tokenLastPart = token.split('.')[1];

    if (!tokenLastPart) {
        return null;
    }

    try {
        return JSON.parse(atob(tokenLastPart));
    } catch {
        return null;
    }
}
