import {ApolloClient, ApolloLink} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {ErrorHandler, onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {HttpLink} from '@apollo/client/link/http';
import Cookies from 'js-cookie';
import {ApiConfig} from '../config/api.config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createApolloClient = (errorHandler: ErrorHandler): ApolloClient<any> => {
    return new ApolloClient({
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
        link: ApolloLink.from([
            setContext((_, {headers}) => {
                const token = Cookies.get('token');

                return {
                    headers: {
                        ...headers,
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                };
            }),
            onError(errorHandler),
            new HttpLink({
                uri: `${ApiConfig.apiEndpoint}/graphql`,
                credentials: 'same-origin',
                fetchOptions: {
                    mode: 'cors',
                    credentials: 'same-origin',
                },
            }),
        ]),
        cache: new InMemoryCache(),
    });
}
