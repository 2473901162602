import React, {ChangeEvent, ReactNode, useCallback} from 'react';
import {FormGroup, Grid, styled, Switch, Typography} from '@material-ui/core';
import {useField} from 'formik';

type Props = {
    activeLabel?: ReactNode;
    id?: string;
    inactiveLabel?: ReactNode;
    label: string;
    name: string;
};

const TecgenceSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 22,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 18,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(13px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 18,
        height: 18,
        borderRadius: 9,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 11,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const SwitchInputField = ({activeLabel = '', id, inactiveLabel = '', label, name}: Props) => {
    const [{value},, {setTouched, setValue}] = useField(name);
    const handleChange = useCallback(({target: {checked}}: ChangeEvent<HTMLInputElement>) => {
        setTouched(true);
        setValue(checked);
    }, [setTouched, setValue]);

    return (
        <FormGroup>
            <Grid container spacing={1}>
                <Grid item>
                    <TecgenceSwitch checked={value} id={id} inputProps={{'aria-label': label}} onChange={handleChange} />
                </Grid>
                {activeLabel || inactiveLabel ? (
                    <Grid item>
                        <label htmlFor={id}>
                            <Typography color={value ? 'secondary' : 'textSecondary'} variant="button">
                                {value ? activeLabel : inactiveLabel}
                            </Typography>
                        </label>
                    </Grid>
                ) : null}
            </Grid>
        </FormGroup>
    );
};
