import {useMemo} from 'react';
import {
    ConstantFilterValue,
    GroupTargetingFilterState,
    SingleTargetingFilterState,
    TargetingFilterOperator,
    TargetingFilterState, TitledValue
} from '../../components/targetingFilter/types';
import {
    TargetingField,
    TargetingFilter,
    TargetingFilterCondition,
    TargetingOperator
} from '../../graphql/graphql.types';

const OPERATORS_MAP: {[key in TargetingFilterOperator]: TargetingOperator} = {
    '=': TargetingOperator.EQUAL,
    '!=': TargetingOperator.NOT_EQUAL,
    '<': TargetingOperator.LESS_THAN,
    '<=': TargetingOperator.LESS_THAN_OR_EQUAL,
    '>': TargetingOperator.GREATER_THAN,
    '>=': TargetingOperator.GREATER_THAN_OR_EQUAL,
    'ALL_OF': TargetingOperator.ALL_OF,
    'ONE_OF': TargetingOperator.ONE_OF,
    'BETWEEN': TargetingOperator.EQUAL,
    'EXCLUDE_ALL': TargetingOperator.EXCLUDE_ALL,
};

const unfoldOperators = (state: SingleTargetingFilterState): SingleTargetingFilterState[] => {
    if (state.operator === 'BETWEEN') {
        return [
            {
                ...state,
                operator: '>=',
                value: {
                    ...state.value,
                    value: (state.value as TitledValue<[string, string]>).value[0],
                },
            } as SingleTargetingFilterState,
            {
                ...state,
                operator: '<=',
                value: {
                    ...state.value,
                    value: (state.value as TitledValue<[string, string]>).value[1],
                },
            } as SingleTargetingFilterState,
        ];
    }

    return [state];
};

const mapFilter = (state: SingleTargetingFilterState | ConstantFilterValue): TargetingFilterCondition => {
    return {
        filter: ('filter' in state ? state.filter.name : state.name) as TargetingField,
        operator: OPERATORS_MAP[state.operator],
        value: Array.isArray(state.value)
            ? state.value.map(({value}) => value)
            : [state.value.value as string],
    };
};

export const useFiltersHydrator = (filterValues: TargetingFilterState[]): TargetingFilter[] => useMemo(() => {
    return filterValues.map((filterValue) => {
        const filterState = (filterValue as GroupTargetingFilterState).state || [filterValue];
        const unfoldedFilterState = filterState.reduce((carry, current) => {
            carry.push(...unfoldOperators(current));

            return carry;
        }, [] as SingleTargetingFilterState[]);
        const conditions: TargetingFilterCondition[] = [];

        filterValue.filter.constantValues?.map(mapFilter)?.forEach((constantValue) => conditions.push(constantValue));
        unfoldedFilterState.forEach((state) => conditions.push(mapFilter(state)));

        return {conditions};
    }, [] as TargetingFilter[]);
}, [filterValues]);
