import {RouteConfig} from '../../router/RouteConfig';
import {RouteChildren} from '../../router/RouteChildren';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {lang} from '../../lang/lang';

export const technologyRoutes = (): RouteConfig => ({
    path: 'technologies',
    component: RouteChildren,
    title: lang.technology.list.title,
    routes: [
        {
            path: ':technologyId',
            component: lazy(() => import('./details/TechnologyLayout').then(defaultExport('TechnologyLayout'))),
            title: lang.technology.details.title,
            routes: [
                {
                    path: 'domains',
                    component: lazy(() => import('./details/DomainsOfTechnology').then(defaultExport('DomainsOfTechnology'))),
                    title: lang.technology.details.domains.title,
                },
                {
                    path: 'history',
                    component: lazy(() => import('./details/TechnologyHistory').then(defaultExport('TechnologyHistory'))),
                    title: lang.technology.details.history.title,
                },
            ],
        },
        {
            path: '.',
            component: lazy(() => import('./list/Technologies').then(defaultExport('Technologies'))),
            title: lang.technology.list.title,
        },
    ],
});
