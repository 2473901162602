import React from 'react';
import {
    GroupTargetingFilterState,
    SingleTargetingFilterState,
    TargetingFilterState,
    TargetingFilterVariant
} from '../types';
import {SingleFilterEquation} from './SingleFilterEquation';
import {MultipleFilterEquation} from './MultipleFilterEquation';

type Props = {
    filterState: TargetingFilterState;
    index: number;
    onChange: (filterIndex: number, value: TargetingFilterState) => void;
};

export const FilterEquation = ({filterState, index, onChange}: Props) => {
    if (filterState.filter.type !== TargetingFilterVariant.GROUP) {
        return (
            <SingleFilterEquation
                filterState={filterState as SingleTargetingFilterState}
                index={index}
                onChange={onChange}
            />
        );
    }

    return (
        <MultipleFilterEquation
            filterValue={filterState as GroupTargetingFilterState}
            index={index}
            onChange={onChange}
        />
    );
};
