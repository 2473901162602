import {createContext} from 'react';
import {UserRole} from '../graphql/graphql.types';

type RolesContextType = {
    loaded: boolean;
    roles: UserRole[];
};

export const RolesContext = createContext<RolesContextType>({
    loaded: false,
    roles: [],
});
