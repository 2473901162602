import React, {useMemo} from 'react';
import {TechnologiesFilter, TechnologiesFilterType} from './TechnologiesFilter';
import {TechnologyActionsRenderer} from './TechnologyActionsRenderer';
import {PaginatedTable} from '../../../components/table/PaginatedTable';
import {Category, Technology} from '../../../graphql/graphql.types';
import {Pagination} from '../../../components/usePagination';
import {TableConfig} from '../../../components/table/types';
import {Paths} from '../../../router/paths';
import {Link} from '../../../components/navigation/Link';
import {lang} from '../../../lang/lang';

type Props = {
    categories: Category[];
    loading: boolean;
    onTechnologiesFilter?: (filter: TechnologiesFilterType) => void;
    pagination: Pagination;
    technologies: Technology[];
};

const CALCULATE_TECHNOLOGY_KEY = (technology: Technology) => technology.id;

export const TechnologiesTable = ({categories, loading, onTechnologiesFilter, pagination, technologies}: Props) => {
    const headerConfig: TableConfig<Technology>[] = useMemo(() => [
        {
            name: 'technology',
            title: lang.technology.table.technology,
            render: ({item}) => <Link to={[Paths.TECHNOLOGY_DOMAINS, {technologyId: item.id}]}>{item.name}</Link>
        },
        {
            name: 'category',
            title: lang.technology.table.category,
            render: ({item}) => <>{item?.category?.name}</>,
        },
        {
            name: 'domainsCount',
            title: lang.technology.table.domainsCount,
        },
        {
            align: 'right',
            name: 'actions',
            title: '',
            render: TechnologyActionsRenderer,
        },
    ], []);

    return (
        <PaginatedTable<Technology>
            calculateKey={CALCULATE_TECHNOLOGY_KEY}
            configs={headerConfig}
            items={technologies}
            headerFilter={onTechnologiesFilter && (
                <TechnologiesFilter
                    categories={categories}
                    onFilter={onTechnologiesFilter}
                />
            )}
            loading={loading}
            pagination={pagination}
        />
    );
};
