import React from 'react';
import {
    DateTargetingFilter, SingleTargetingFilter,
    TargetingFilterSuggestion,
    TargetingFilterType,
    TargetingFilterVariant
} from '../../components/targetingFilter/types';
import {
    CategoriesDocument,
    CategoriesQuery,
    TechnologiesListDocument,
    TechnologiesListQuery
} from '../../graphql/graphql.types';
import CategoryIcon from '../../assets/icons/category.svg';
import TechnologyIcon from '../../assets/icons/technology.svg';
import TechnologyAddedIcon from '../../assets/icons/technology-added.svg';
import TechnologyDroppedIcon from '../../assets/icons/technology-dropped.svg';
import {lang} from '../../lang/lang';

const createDateRangeFilter = (name: string, title: string): DateTargetingFilter => {
    return {
        name,
        title,
        type: TargetingFilterVariant.DATE_RANGE,
        operators: ['BETWEEN'],
        defaultValue: {title: '', value: [null, null]},
    };
};

const technologyFilter = (): TargetingFilterType => ({
    name: 'TECHNOLOGY',
    title: lang.targeting.availableFilters.technology,
    type: TargetingFilterVariant.SUGGESTINON,
    operators: ['ONE_OF', 'ALL_OF', 'EXCLUDE_ALL'],
    icon: <TechnologyIcon />,
    placeholder: lang.targeting.availableFilters.technologyPlaceholder,
    defaultValue: [],
    constantValues: [
        {
            name: 'ACTION',
            operator: '=',
            value: {title: lang.targeting.availableFilters.add, value: 'ADDED'},
        },
    ],
    suggestions: {
        document: TechnologiesListDocument,
        responseToSuggestions: (data: TechnologiesListQuery): TargetingFilterSuggestion[] => {
            return data.listTechnologies.map((technology): TargetingFilterSuggestion => ({
                title: technology.name,
                subtitle: technology.category?.name || '',
                value: technology.id,
            }));
        },
    },
});

export const targetingFilters = (): TargetingFilterType[] => [
    technologyFilter(),
    {
        name: 'technologyAdded',
        title: lang.targeting.availableFilters.technologyAdded,
        icon: <TechnologyAddedIcon />,
        type: TargetingFilterVariant.GROUP,
        filters: [
            technologyFilter() as SingleTargetingFilter,
            createDateRangeFilter('FOUND_AT', lang.targeting.availableFilters.foundAt),
        ],
        constantValues: [
            {
                name: 'ACTION',
                operator: '=',
                value: {title: lang.targeting.availableFilters.add, value: 'ADDED'},
            },
        ],
    },
    {
        name: 'technologyDropped',
        title: lang.targeting.availableFilters.technologyDropped,
        icon: <TechnologyDroppedIcon />,
        type: TargetingFilterVariant.GROUP,
        filters: [
            technologyFilter() as SingleTargetingFilter,
            createDateRangeFilter('FOUND_AT', lang.targeting.availableFilters.foundAt),
        ],
        constantValues: [
            {
                name: 'ACTION',
                operator: '=',
                value: {title: lang.targeting.availableFilters.drop, value: 'DROPPED'},
            },
        ],
    },
    {
        name: 'CATEGORY',
        title: lang.targeting.availableFilters.category,
        type: TargetingFilterVariant.SUGGESTINON,
        icon: <CategoryIcon />,
        placeholder: lang.targeting.availableFilters.categoryPlaceholder,
        defaultValue: [],
        operators: ['ONE_OF', 'ALL_OF', 'EXCLUDE_ALL'],
        constantValues: [
            {
                name: 'ACTION',
                operator: '=',
                value: {title: lang.targeting.availableFilters.add, value: 'ADDED'},
            },
        ],
        suggestions: {
            document: CategoriesDocument,
            responseToSuggestions: (data: CategoriesQuery): TargetingFilterSuggestion[] => {
                return data.categories.categories.map((category): TargetingFilterSuggestion => ({
                    title: category.name,
                    value: category.id,
                }));
            },
        },
    },
];
