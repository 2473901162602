import {useCallback, useMemo, useState} from 'react';
import {PaginatedResponse} from '../graphql/graphql.types';

export const usePagination = (initialPage = 1, initialPerPage = 10) => {
    const [page, setPage] = useState(initialPage);
    const [perPage, setPerPage] = useState(initialPerPage);
    const [total, setTotal] = useState<number>();
    const pages = useMemo(() => Math.ceil((total || 0) / perPage), [perPage, total]);
    const setPagination = useCallback((page: number, perPage: number, total: number) => {
        setPage(page);
        setPerPage(perPage);
        setTotal(total);
    }, [setPage, setPerPage, setTotal]);
    const setPaginatedResponse = useCallback((response: PaginatedResponse | undefined) => {
        if (response && response.pagination) {
            setPagination(response.pagination.page, response.pagination.perPage, response.pagination.count);
        }
    }, [setPagination]);
    const onNextPage = useCallback(() => {
        setPage((page) => page + 1);
    }, [setPage]);
    const onPreviousPage = useCallback(() => {
        setPage((page) => page - 1);
    }, [setPage]);

    return {
        onChangePage: setPage,
        onChangePerPage: setPerPage,
        onNextPage,
        onPreviousPage,
        page,
        pages,
        perPage,
        total,
        setPagination,
        setPaginatedResponse,
    };
};

export type Pagination = ReturnType<typeof usePagination>;
