export default {
    "menu": {
        "settings": "設定",
        "logOut": "ログアウト"
    },
    "profile": {
        "generalInformation": "プロフィール",
        "firstName": "名",
        "lastName": "姓",
        "companyName": "会社名",
        "companyPhone": "電話番号",
        "role": "役職",
        "save": "保存",
        "create": {
            "title": "プロフィールの作成"
        },
        "notification": {
            "failedToCreate": "お客様プロフィールが作成できませんでした",
            "profileUpdated": "プロフィールが更新されました",
            "profileUpdateFailed": "プロフィールの更新が出来ませんでした"
        }
    },
    "email": {
        "emailAddressTitle": "メールアドレス",
        "yourEmailIs": "あなたのメールアドレスは{email}です"
    },
    "language": "言語"
};
