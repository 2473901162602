import React, {useCallback} from 'react';
import {useField} from 'formik';
import {TextField, TextFieldProps} from '@material-ui/core';

type Props = Omit<TextFieldProps, 'error' | 'helperText' | 'label' | 'name' | 'onChange'> & {
    label: string;
    name: string;
    onChange?: (value: string) => void;
    required?: boolean;
};

export const TextInputField = ({
    label,
    name,
    onChange,
    required = false,
    variant = 'outlined',
    ...textFieldProps
}: Props) => {
    const [{onBlur, value}, {error, touched}, {setValue, setTouched}] = useField(name);

    const handleChange = useCallback(({target}) => {
        onChange?.(target.value);
        setTouched(true);
        setValue(target.value);
    }, [onChange, setTouched, setValue]);

    return (
        <TextField
            {...textFieldProps}
            error={touched && Boolean(error)}
            helperText={touched ? error : undefined}
            label={required ? `${label}*` : label}
            onBlur={onBlur}
            onChange={handleChange}
            value={value}
            variant={variant}
        />
    );
};
