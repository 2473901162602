import React, {ReactNode} from 'react';
import {Form, Formik} from 'formik';
import {Avatar, Button, Container, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {createPasswordValidationSchema} from '../createPassword/createPasswordValidationSchema';
import {TextInputField} from '../../../components/formik/TextInputField';
import {PasswordFormValues} from './types';
import {lang} from '../../../lang/lang';

type Props = {
    email: string;
    onSubmit: (values: PasswordFormValues) => Promise<void>;
    title: ReactNode;
};

const initialValues: PasswordFormValues = {password: '', repeatPassword: ''};

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signInButton: {
        marginTop: theme.spacing(1),
    },
}));

export const PasswordForm = ({email, onSubmit, title}: Props) => {
    const classes = useStyles();

    return (
        <Formik<PasswordFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={createPasswordValidationSchema}
        >
            {({isSubmitting}) => (
                <Form>
                    <Container className={classes.container} component="main" maxWidth="xs">
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h4">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1">{email}</Typography>
                        <TextInputField
                            autoFocus
                            fullWidth
                            label={lang.auth.password.label}
                            margin="normal"
                            name="password"
                            type="password"
                        />
                        <TextInputField
                            fullWidth
                            label={lang.auth.password.repeat}
                            margin="normal"
                            name="repeatPassword"
                            type="password"
                        />
                        <Button
                            className={classes.signInButton}
                            color="secondary"
                            disabled={isSubmitting}
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            {title}
                        </Button>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};
