import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {useCreateCustomerMutation} from '../../graphql/graphql.types';
import {CustomerProfileForm, CustomerProfileFormValues} from '../../components/customer/CustomerProfileForm';
import {CustomerContext} from '../../components/customer/CustomerContext';
import {Paths} from '../../router/paths';
import {useSnackbar} from '../../components/snackbar/useSnackbar';
import {lang} from '../../lang/lang';

export const CreateCustomerProfilePage = () => {
    const {errorNotification} = useSnackbar();
    const history = useHistory();
    const {reload} = useContext(CustomerContext);
    const [createCustomerProfile, {loading}] = useCreateCustomerMutation();
    const handleSubmit = useCallback(async (values: CustomerProfileFormValues) => {
        try {
            await createCustomerProfile({variables: values});
            history.push(Paths.TARGETING);
            reload();
        } catch {
            errorNotification(lang.customer.profile.notification.failedToCreate);
        }
    }, [createCustomerProfile, errorNotification, reload]);

    return (
        <CustomerProfileForm disabled={loading} fullWidth onSubmit={handleSubmit} />
    );
};
