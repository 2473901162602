import React from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress, Paper, Step, StepLabel, Stepper} from '@material-ui/core';

import {HistoryAction, useTechnologyHistoryQuery} from '../../../graphql/graphql.types';
import {AddIcon} from '../../../components/history/AddIcon';
import {DropIcon} from '../../../components/history/DropIcon';
import {FoundAt} from '../../../components/history/FoundAt';

export const TechnologyHistory = () => {
    const {technologyId = ''} = useParams();
    const {data, loading} = useTechnologyHistoryQuery({
        variables: {technologyId},
    });

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Paper>
            <Stepper orientation='vertical'>
                {data?.technologyHistory.history.map(({domain, action, foundAt}) => (
                    <Step key={foundAt}>
                        <StepLabel StepIconComponent={action === HistoryAction.ADDED ? AddIcon : DropIcon}>
                            {domain}
                        </StepLabel>
                        <FoundAt foundAt={foundAt} />
                    </Step>
                ))}
            </Stepper>
        </Paper>
    );
};
