import React, {useCallback, useMemo, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {useApolloClient} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from '@material-ui/lab';
import {
    SuggestingTargetingFilter,
    TargetingFilterSuggestion,
    SuggestingTargetingFilterValue,
} from '../types';
import {SuggestionFilterOption} from './SuggestionFilterOption';
import {TargetingTextField} from '../inputs/TargetingTextField';
import {Chip} from '@material-ui/core';
import {lang} from '../../../lang/lang';
import {plural} from '../../../lang/plural';

type Props = {
    filter: SuggestingTargetingFilter;
    onChange: (value: SuggestingTargetingFilterValue) => void;
    values: SuggestingTargetingFilterValue;
};

const useStyles = makeStyles({
    inputRoot: {
        backgroundColor: '#fff',
        paddingTop: '2px !important',
    },
});

export const SuggestionFilterValue = ({filter, onChange, values}: Props) => {
    const classes = useStyles();

    const apollo = useApolloClient();

    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<TargetingFilterSuggestion[]>([]);
    const filteredSuggestions = useMemo(() => {
        return suggestions.filter((suggestion) => {
            return values.every((value) => value.value !== suggestion.value);
        });
    }, [suggestions, values]);

    const fetchSuggestions = useCallback(async (name: string) => {
        setLoading(true);

        const {data} = await apollo.query({
            fetchPolicy: 'cache-first',
            query: filter.suggestions.document,
            variables: {
                ...filter.suggestions.getFilterVariables?.(name),
                ...filter.suggestions.getPageVariables?.(1),
            },
        });

        setLoading(false);
        setSuggestions(filter.suggestions.responseToSuggestions(data));
    }, [apollo, filter, setSuggestions]);
    const [debouncedFetchSuggestions] = useDebouncedCallback(fetchSuggestions, 1000, {leading: true});

    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const handleOpen = useCallback(() => {
        setOpen(true);
        debouncedFetchSuggestions('');
    }, [debouncedFetchSuggestions, setOpen]);

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = useCallback((e, nextInputValue) => {
        setInputValue(nextInputValue);
        debouncedFetchSuggestions(nextInputValue);
    }, [debouncedFetchSuggestions, setInputValue]);
    const handleChange = useCallback((event, values: TargetingFilterSuggestion[]) => {
        onChange(values.map(({title, value}) => ({title, value})));
    }, [onChange]);

    const handleOptionLabel = useCallback((option: TargetingFilterSuggestion) => option.title, []);

    const noOptionsText = useMemo(() => {
        if (loading) {
            return lang.targeting.filter.dropdown.loading;
        }

        return inputValue ? lang.targeting.filter.dropdown.nothingFound : lang.targeting.filter.dropdown.startTyping;
    }, [inputValue, loading]);

    return (
        <Autocomplete<TargetingFilterSuggestion, true, true, false>
            autoHighlight
            disableClearable
            disableCloseOnSelect
            disablePortal
            getOptionLabel={handleOptionLabel}
            inputValue={inputValue}
            multiple
            noOptionsText={noOptionsText}
            onClose={handleClose}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            open={open}
            openOnFocus={false}
            options={filteredSuggestions}
            popupIcon={null}
            renderInput={(params) => (
                <TargetingTextField
                    {...params}
                    autoFocus
                    InputProps={{
                        ...params.InputProps,
                        classes: {root: classes.inputRoot},
                    }}
                    loading={loading}
                    placeholder={
                        filter.placeholder || plural(lang.targeting.filter.placeholder, {field: filter.title})
                    }
                />
            )}
            renderOption={(option, {inputValue}) => (
                <SuggestionFilterOption inputValue={inputValue} option={option} />
            )}
            renderTags={(values, getTagProps) => values.map((value, index) => (
                <Chip key={index} {...getTagProps({index})} label={value.title} size="small" />
            ))}
            value={values}
        />
    );
};
