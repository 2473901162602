import {SingleTargetingFilterState, TargetingFilterOperator, TargetingFilterValue} from '../types';
import {makeStyles} from '@material-ui/core/styles';
import React, {useCallback} from 'react';
import {FilterOperatorDropdown} from '../FilterOperatorDropdown';
import {FilterValue} from '../values/FilterValue';
import {stripes} from '../styles';

type Props = {
    filterState: SingleTargetingFilterState;
    index: number;
    multiple?: boolean;
    onChange: (filterIndex: number, value: SingleTargetingFilterState) => void;
};

type StyleProps = Pick<Props, 'index' | 'multiple'>;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        position: 'relative',
        '&::before': {
            ...stripes(theme),
            top: 20,
            width: ({index, multiple}: StyleProps) => index === 0 || !multiple ? theme.spacing(1) : theme.spacing(0.5) + 1,
            left: ({index, multiple}: StyleProps) => index === 0 || !multiple ? theme.spacing(-1) : theme.spacing(-0.5) - 1,
        },
    },
    filterValue: {
        flex: 1,
    },
}));

export const SingleFilterEquation = ({filterState, index, multiple = false, onChange}: Props) => {
    const classes = useStyles({index, multiple} as StyleProps);

    const handleOperator = useCallback((operator: TargetingFilterOperator) => {
        onChange(index, {...filterState, operator});
    }, [filterState, index, onChange]);

    const handleValue = useCallback((value: TargetingFilterValue) => {
        onChange(index, {...filterState, value} as SingleTargetingFilterState);
    }, [filterState, index, onChange]);

    return (
        <div className={classes.container}>
            <FilterOperatorDropdown
                operators={filterState.filter.operators}
                onChange={handleOperator}
                value={filterState.operator}
            />
            <div className={classes.filterValue}>
                <FilterValue
                    filterValue={filterState}
                    onChange={handleValue}
                />
            </div>
        </div>
    );
};
