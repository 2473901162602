import React from 'react';
import {Box, Drawer, Grid, IconButton, makeStyles, SvgIcon, Typography} from '@material-ui/core';
import {useTechnologyFeedQuery} from '../../../graphql/graphql.types';
import CloseIcon from '../../../assets/icons/close.svg';
import {TechnologyFeedContent} from './TechnologyFeedContent';
import {lang} from '../../../lang/lang';

type Props = {
    onClose: () => void;
    open: boolean;
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    drawer: {
        width: 660,
    },
    header: {
        background: theme.palette.background.paper,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
}));

export const TechnologyFeedDrawer = ({onClose, open}: Props) => {
    const classes = useStyles();
    const {data, loading} = useTechnologyFeedQuery({
        skip: !open,
    });
    const feeds = data?.technologyFeeds?.feeds || [];

    return (
        <Drawer
            anchor="right"
            className={classes.drawer}
            classes={{paper: classes.drawer}}
            open={open}
            onClose={onClose}
        >
            <Box className={classes.container} height={loading ? '100%' : undefined} mb={2} pl={3} pr={3}>
                <Box className={classes.header} pt={2}>
                    <Grid alignItems="center" container justify="space-between">
                        <Box ml={1}>
                            <Typography variant="h4">
                                {lang.feed.drawer.title}
                            </Typography>
                        </Box>
                        <IconButton color="primary" onClick={onClose}>
                            <SvgIcon>
                                <CloseIcon />
                            </SvgIcon>
                        </IconButton>
                    </Grid>
                </Box>
                <TechnologyFeedContent feeds={feeds} loading={loading} />
            </Box>
        </Drawer>
    );
};
