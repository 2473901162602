import React, {useCallback, useContext, useMemo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {AppBar, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TecplusLogo from '../../../assets/img/tecplus-white.png';
import {AuthContext} from '../../../auth/AuthContext';
import {CustomerMenuButton} from '../../customer/customerMenu/CustomerMenuButton';
import {Paths} from '../../../router/paths';
import {CustomerContext} from '../../customer/CustomerContext';
import {jwtTokenPayload} from '../../../auth/jwtTokenPayload';
import {TechnologyFeedButton} from '../../history/technologyFeed/TechnologyFeedButton';

type Props = {
    onLogout: () => void;
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarRegular: {
        minHeight: 70,
    },
    toolbarLeft: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
}));

export const Header = ({onLogout}: Props) => {
    const classes = useStyles();
    const {token} = useContext(AuthContext);
    const {customer} = useContext(CustomerContext);
    const email = useMemo(() => jwtTokenPayload(token)?.email as string, [token]);
    const history = useHistory();
    const handleSettings = useCallback(() => history.push(Paths.SETTINGS), [history]);

    return (
        <AppBar
            className={classes.appBar}
            elevation={0}
            position='fixed'
        >
            <Toolbar classes={{regular: classes.appBarRegular}}>
                <div className={classes.toolbarLeft}>
                    <Link to={Paths.ROOT}>
                        <img src={TecplusLogo} alt="Tecplus" />
                    </Link>
                </div>
                {customer && token ? (
                    <>
                        <TechnologyFeedButton />
                        <CustomerMenuButton
                            customer={customer}
                            email={email}
                            onLogout={onLogout}
                            onSettings={handleSettings}
                        />
                    </>
                ) : null}
            </Toolbar>
        </AppBar>
    );
};
