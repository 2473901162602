import React from 'react';
import {Box, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {SwitchInputField} from '../../../../components/formik/SwitchInputField';
import {lang} from '../../../../lang/lang';
import {CategoriesTableField} from './CategoriesTableField';

const useStyles = makeStyles((theme) => ({
    switchTable: {
        '& tr > td': {
            paddingBottom: theme.spacing(2),
            '& > label': {
                marginRight: theme.spacing(8),
            },
        },
    },
}));

export const CreateUserAccessFormFields = () => {
    const classes = useStyles();

    return (
        <>
            <Box mb={3}>
                <Typography variant="h6">
                    {lang.admin.users.create.access.permissions}
                </Typography>
            </Box>
            <table className={classes.switchTable}>
                <tbody>
                <tr>
                    <td>
                        <label htmlFor="isAlertsEnabled">{lang.admin.users.create.access.alertsSubscription}</label>
                    </td>
                    <td>
                        <SwitchInputField
                            activeLabel={lang.admin.users.create.access.active}
                            id="isAlertsEnabled"
                            inactiveLabel={lang.admin.users.create.access.inactive}
                            label={lang.admin.users.create.access.alertsSubscription}
                            name="isAlertsEnabled"
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label htmlFor="isFeedsEnabled">{lang.admin.users.create.access.feedFeature}</label>
                    </td>
                    <td>
                        <SwitchInputField
                            activeLabel={lang.admin.users.create.access.active}
                            id="isFeedsEnabled"
                            inactiveLabel={lang.admin.users.create.access.inactive}
                            label={lang.admin.users.create.access.feedFeature}
                            name="isFeedsEnabled"
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label htmlFor="isTargetingCSVExportEnabled">{lang.admin.users.create.access.csvExport}</label>
                    </td>
                    <td>
                        <SwitchInputField
                            activeLabel={lang.admin.users.create.access.active}
                            id="isTargetingCSVExportEnabled"
                            inactiveLabel={lang.admin.users.create.access.inactive}
                            label={lang.admin.users.create.access.csvExport}
                            name="isTargetingCSVExportEnabled"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <Box mt={3} mb={3}>
                <Divider />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">
                    {lang.admin.users.create.access.categories.title}
                </Typography>
                <CategoriesTableField />
            </Box>
        </>
    );
};
