import React, {useCallback} from 'react';
import {Box, Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {SearchField} from '../../../components/form/SearchField';
import {lang} from '../../../lang/lang';
import {Paths} from '../../../router/paths';

type Props = {
    onSearch: (search: string) => void;
};

const useStyles = makeStyles(() => ({
    searchField: {
        minWidth: 390,
    },
    searchFieldInput: {
        background: '#fff',
    },
}));

export const UsersListHeader = ({onSearch}: Props) => {
    const classes = useStyles();

    const history = useHistory();
    const handleCreateUser = useCallback(() => history.push(Paths.USERS_CREATE), [history]);

    return (
        <Box mb={2}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <SearchField
                        className={classes.searchField}
                        debounceTime={500}
                        InputProps={{
                            classes: {root: classes.searchFieldInput},
                        }}
                        placeholder={lang.admin.users.list.searchPlaceholder}
                        onSearch={onSearch}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        onClick={handleCreateUser}
                        variant="contained"
                    >
                        {lang.admin.users.list.createUser}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
