import React from 'react';
import {
    TargetingFilterVariant,
    DateTargetingFilterValue,
    RegularTargetingFilterValue,
    SuggestingTargetingFilterValue,
    SingleTargetingFilterState,
    SingleTargetingFilterValue,
} from '../types';
import {NumericFilterValue} from './NumericFilterValue';
import {DateFilterValue} from './DateFilterValue';
import {SuggestionFilterValue} from './SuggestionFilterValue';

type Props = {
    filterValue: SingleTargetingFilterState;
    onChange: (value: SingleTargetingFilterValue) => void;
};

export const FilterValue = ({filterValue: {filter, value}, onChange}: Props) => {
    switch (filter.type) {
        case TargetingFilterVariant.DATE_RANGE:
            return (
                <DateFilterValue
                    filter={filter}
                    onChange={onChange}
                    value={value as DateTargetingFilterValue}
                />
            );
        case TargetingFilterVariant.NUMBER:
            return (
                <NumericFilterValue
                    filter={filter}
                    onChange={onChange}
                    value={value ? (value as RegularTargetingFilterValue).value : ''}
                />
            );
        case TargetingFilterVariant.SUGGESTINON:
            return (
                <SuggestionFilterValue
                    filter={filter}
                    onChange={onChange}
                    values={value as SuggestingTargetingFilterValue}
                />
            );
        default:
            return null;
    }
};
