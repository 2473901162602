import React from 'react';
import {Box, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {lang} from '../../../lang/lang';

const useStyles = makeStyles({
    footerContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
});

type Props = {
    editMode: boolean;
    hasChanges: boolean;
    onCancel: () => void;
    onSave: () => void;
};

export const TechnologySubscriptionsFooter = ({editMode, hasChanges, onCancel, onSave}: Props) => {
    const classes = useStyles();

    if (!editMode) {
        return null;
    }

    return (
        <Box className={classes.footerContainer} p={2} pb={0}>
            <Button
                color="secondary"
                disabled={!hasChanges}
                onClick={onSave}
                variant="contained"
            >
                {lang.technologySubscriptions.footer.save}
            </Button>
            <Box mr={1}>
                <Button color="secondary" onClick={onCancel}>
                    {lang.technologySubscriptions.footer.cancel}
                </Button>
            </Box>
        </Box>
    );
};
