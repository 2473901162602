import React, {Fragment, useCallback, useState, useMemo} from 'react';
import ReactMarkdown from 'react-markdown';
import {Box, Button, Collapse, Divider, IconButton, Modal, Paper, styled, Typography} from "@material-ui/core";
import {DashboardDomainHistoryItem, Technology} from "../../../graphql/graphql.types";
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg';
import {lang} from "../../../lang/lang";
import {DashboardItemHistory} from "./DashboardItemHistory";
import {useRequest} from "../../../shared/api/useRequest";
import {ApiConfig} from "../../../config/api.config";
import { makeStyles } from "@material-ui/core/styles";
import AiImage from '../../../assets/img/ai-button.png';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: '8px',
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    reportText: {
        marginTop: theme.spacing(2),
        lineHeight: 1.6, // Improves readability
        textAlign: 'justify',
    },
    closeButton: {
        marginTop: theme.spacing(2),
    },
}));


const ArrowUpIcon = styled(ArrowDownIcon)(() => ({
    transform: 'rotate(180deg)',
}));

const Container = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.grey['300'],
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2.5),
    paddingBottom: 0,
}));

const HeaderContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: theme.spacing(2.5),
}));
const Title = styled(Typography)(() => ({
    '&:first-child': {
        flex: 1,
    },
}));
const TechnologiesCount = styled('span')(({theme}) => ({
    color: theme.palette.success.dark,
}));

type Props = {
    domain: string;
    expanded: boolean;
    histories: DashboardDomainHistoryItem[];
    onExpand: (domain: string, expanded: boolean) => void;
    technologies: Map<string, Technology[]>;
};

export type ApiResponse = {
    response_to_query: string;
}
export const DashboardItem = ({domain, expanded, histories, onExpand, technologies}: Props) => {
    type ModalState = boolean;
    const [open, setOpen] = useState<ModalState>(false);
    const classes = useStyles()
    const [report, setReport] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    // Function to close the modal
    const handleClose = () => setOpen(false);
    const handleExpand = useCallback(() => {
        onExpand(domain, !expanded);
    }, [domain, expanded, onExpand]);
    const technologiesCount = useMemo(() => {
        return Array.from(technologies.values()).reduce((carry, current) => {
            return carry + current.length;
        }, 0);
    }, [technologies]);

    const api = useRequest();
    const handleOpen = useCallback(async () => {
        setOpen(true);
        setLoading(true);
        try {
            const response = await api.get(`${ApiConfig.apiEndpoint}/report/${domain}`);
            console.log(response)
            const data: ApiResponse = await response.json()
            setReport(data?.response_to_query || "No report available.");
        } catch (error) {
            setReport("Failed to fetch report.");
        } finally {
            setLoading(false);
        }
    }, [api, domain]);

    return (

        <Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            ><Box className={classes.modalContent}>
                <Typography variant="h6" gutterBottom>
                    Report for {domain}
                </Typography>

                {loading ? (
                    <Typography variant="body1">Loading...</Typography>
                ) : (
                    <ReactMarkdown className={classes.reportText}>
                        {report?? ''}
                    </ReactMarkdown>
                )}

                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    className={classes.closeButton}
                >
                    Close
                </Button>
            </Box>
            </Modal>
            <HeaderContainer onClick={handleExpand}>
                <Title variant="h6">
                    {domain}
                </Title>
                <Title variant="h6">
                    {lang.dashboard.main.technologiesInUse}{' '}
                    <TechnologiesCount>{technologiesCount}</TechnologiesCount>
                </Title>
                <div>
                    <IconButton color="primary" size="small">
                        {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </IconButton>
                </div>
            </HeaderContainer>

            <Collapse in={expanded} mountOnEnter unmountOnExit>
                <Paper elevation={0}>
                    <Box p={2.5} mb={2.5}>
                        <Button startIcon={<img  src={AiImage} alt="icon" style={{ width: 34, height: 34 }} />} onClick={handleOpen}>
                            AI Report
                        </Button>
                        {histories.map((history, index) => (
                            <Fragment key={history.category?.id ?? '-'}>
                                <DashboardItemHistory
                                    history={history}
                                    technologies={technologies}
                                />
                                {index !== histories.length - 1 ? <Divider /> : null}
                            </Fragment>
                        ))}
                    </Box>
                </Paper>
            </Collapse>
        </Container>
    );
};
