import React, {ChangeEvent, useCallback} from 'react';
import {RegularTargetingFilter, RegularTargetingFilterValue} from '../types';
import {TargetingTextField} from '../inputs/TargetingTextField';
import {plural} from '../../../lang/plural';
import {lang} from '../../../lang/lang';

type Props = {
    filter: RegularTargetingFilter;
    onChange: (value: RegularTargetingFilterValue) => void;
    value: string;
};

export const NumericFilterValue = ({filter, onChange, value}: Props) => {
    const handleChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        const filterValue = value.replace(/\D+/g, '');

        onChange({title: filterValue, value: filterValue});
    }, [onChange]);

    return (
        <TargetingTextField
            autoFocus
            fullWidth
            onChange={handleChange}
            placeholder={
                filter.placeholder || plural(lang.targeting.filter.placeholder, {field: filter.title})
            }
            value={value}
        />
    );
};
