import React from 'react';
import {Box} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

export const DashboardLoader = () => {
    return (
        <>
            {new Array(5).fill(null).map((_, index) => (
                <Box mt={3}>
                    <Skeleton height={70} variant="rect" />
                </Box>
            ))}
        </>
    );
};
