export default {
    drawer: {
        goBack: 'Go Back',
        dashboard: 'DX Report',
        targeting: 'Targeting',
        domains: 'Domains',
        technologies: 'Technologies',
        users: 'Users',
        settings: {
            profile: 'Profile',
            technologySubscriptions: 'My Technologies',
            api: 'API',
        },
    },
    errorPageTitle: 'Error',
    homePageTitle: 'Home',
    loading: 'Loading...',
    tableDialog: {
        addMore: 'Add more',
        cancel: 'Cancel',
    },
};
