import {RouteConfig} from './RouteConfig';
import {RouteChildren} from './RouteChildren';
import {Layout} from '../components/layout/Layout';
import {authRoutes} from '../pages/auth/auth.routes';
import {domainRoutes} from '../pages/domain/domain.routes';
import {technologyRoutes} from '../pages/technology/technology.routes';
import {targetingRoutes} from '../pages/targeting/targeting.routes';
import {settingsRoutes} from '../pages/settings/settings.routes';
import {errorRoutes} from '../pages/error/error.routes';
import {customerProfileRoutes} from '../pages/customerProfile/customerProfile.routes';
import {lang} from '../lang/lang';
import {adminRoutes} from '../pages/admin/admin.routes';
import {dashboardRoutes} from "../pages/dashboard/dashboard.routes";

const calculatePath = (prevPath: string, path: string): string => {
    if (path === '.') {
        return prevPath;
    }

    return `${prevPath}/${path}`.replace('//', '/');
};

const updateRouteConfig = (routeConfig: RouteConfig, prevPath = '') => {
    routeConfig.path = calculatePath(prevPath, routeConfig.path);

    routeConfig.routes?.forEach((route) => updateRouteConfig(route, routeConfig.path));
};

export const createRoutes = (): RouteConfig[] => {
    const routes: RouteConfig[] = [
        {
            path: '/',
            component: RouteChildren,
            title: lang.layout.homePageTitle,
            public: true,
            routes: [
                authRoutes(),
                customerProfileRoutes(),
                {
                    path: '.',
                    component: Layout,
                    title: '',
                    routes: [
                        adminRoutes(),
                        errorRoutes(),
                        dashboardRoutes(),
                        targetingRoutes(),
                        domainRoutes(),
                        technologyRoutes(),
                        settingsRoutes(),
                    ],
                },
            ],
        },
    ];

    routes.forEach((routeConfig) => updateRouteConfig(routeConfig));

    return routes;
};


