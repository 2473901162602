export default {
    "filter": {
        "label": "フィルター"
    },
    "pagination": {
        "loadMore": "更にロードする",
        "firstPage": "ファーストページ",
        "previousPage": "前のページ",
        "nextPage": "次のページ",
        "lastPage": "最終ページ",
        "resultsPerPage": "ページ毎の結果",
        "results": "{total} 結果中{first} - {last}"
    }
};
