import React, {ChangeEvent, useCallback, useState} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useDebouncedCallback} from 'use-debounce';

import {Category} from '../../../graphql/graphql.types';
import {MultipleSelect} from '../../../components/form/MultipleSelect';
import {lang} from '../../../lang/lang';

export type TechnologiesFilterType = {
    nameFilter: string;
    categoryIds: string[];
};

type Props = {
    categories: Category[];
    onFilter: (filter: TechnologiesFilterType) => void;
};

export const TechnologiesFilter = ({categories, onFilter}: Props) => {
    const [nameFilter, setNameFilter] = useState('');
    const [categoryIdsFilter, setCategoryIdsFilter] = useState<string[]>([]);
    const [handleDebouncedFilter] = useDebouncedCallback(onFilter, 300);
    const handleNameFilterChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setNameFilter(value);
        handleDebouncedFilter({nameFilter: value, categoryIds: categoryIdsFilter});
    }, [categoryIdsFilter, setNameFilter, handleDebouncedFilter]);
    const handleCategoryIdsFilterChange = useCallback((value: string[]) => {
        setCategoryIdsFilter(value);
        handleDebouncedFilter({nameFilter, categoryIds: value});
    }, [nameFilter, setCategoryIdsFilter]);

    return (
        <Grid item>
            {categories && (
                <MultipleSelect
                    label={lang.technology.list.categories}
                    onChange={handleCategoryIdsFilterChange}
                    options={categories}
                    value={categoryIdsFilter}
                />
            )}
            <TextField
                onChange={handleNameFilterChange}
                value={nameFilter}
                label={lang.technology.list.name}
                size="small"
                variant="outlined"
            />
        </Grid>
    )
};
