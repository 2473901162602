import {useParams, useHistory, useLocation, generatePath} from 'react-router-dom';
import React, {PropsWithChildren, useCallback, useState} from 'react';
import {Tabs, Tab} from '@material-ui/core';
import {PageTitle} from '../../../components/layout/PageTitle';
import {TitleContext} from './TitleContext';
import {Paths} from '../../../router/paths';
import {lang} from '../../../lang/lang';

export const TechnologyLayout = ({children}: PropsWithChildren<{}>) => {
    const history = useHistory();
    const {pathname} = useLocation();
    const {technologyId} = useParams<{technologyId: string}>();
    const [value, setValue] = useState(pathname);
    const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
        history.push(newValue);
        setValue(newValue);
    }, [setValue]);
    const [title, setTitle] = useState(lang.technology.details.title);

    return (
        <>
            <PageTitle backTo={Paths.TECHNOLOGIES}>{title}</PageTitle>
            <Tabs
                onChange={handleChange}
                value={value}
                variant='fullWidth'
            >
                <Tab
                    label={lang.technology.details.domainsTab}
                    value={generatePath(Paths.TECHNOLOGY_DOMAINS, {technologyId})}
                />
                <Tab
                    label={lang.technology.details.historyTab}
                    value={generatePath(Paths.TECHNOLOGY_HISTORY, {technologyId})}
                />
            </Tabs>
            <TitleContext.Provider value={{setTitle}}>
                {children}
            </TitleContext.Provider>
        </>
    )
};
