import {useCallback, useState} from 'react';
import {
    GroupTargetingFilterState,
    SingleTargetingFilterState,
    TargetingFilterState,
    TargetingFilterType,
    TargetingFilterVariant
} from './types';

const isFilterValue = (filterOrValue: TargetingFilterType | TargetingFilterState): filterOrValue is TargetingFilterState => {
    return 'state' in filterOrValue || 'value' in filterOrValue;
};

const initializeFilter = (filter: TargetingFilterType): TargetingFilterState => {
    if (filter.type === TargetingFilterVariant.GROUP) {
        return {
            filter,
            state: filter.filters.map((singleFilter) => initializeFilter(singleFilter)),
        } as GroupTargetingFilterState;
    }

    return {
        filter,
        operator: filter.operators[0],
        value: filter.defaultValue,
    } as SingleTargetingFilterState;
};

export const useFilterValues = (initialValue: TargetingFilterState[], filters: TargetingFilterType[]) => {
    const [filterValues, setFilterValues] = useState<TargetingFilterState[]>(initialValue);

    const onAddFilter = useCallback((filter: TargetingFilterType) => {
        setFilterValues((currentFilterValues) => {
            return [...currentFilterValues, initializeFilter(filter)];
        });
    }, [setFilterValues]);

    const onChangeFilter = useCallback((index: number, filterOrValue: TargetingFilterType | TargetingFilterState) => {
        setFilterValues((currentFilterValues) => {
            const nextFilterValues = [...currentFilterValues];
            nextFilterValues[index] = isFilterValue(filterOrValue) ? filterOrValue : initializeFilter(filterOrValue);

            return nextFilterValues;
        });
    }, [setFilterValues]);

    const onRemoveFilter = useCallback((index: number) => {
        setFilterValues((currentFilterValues) => {
            return currentFilterValues.filter((filter, filterIndex) => filterIndex !== index);
        });
    }, [setFilterValues]);

    const onClear = useCallback(() => {
        setFilterValues(initialValue);
    }, [initialValue, setFilterValues]);

    return {
        filterValues,
        onAddFilter,
        onChangeFilter,
        onClear,
        onRemoveFilter,
    };
};
