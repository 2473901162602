import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    makeStyles,
    TextField
} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {lang} from "../../../../lang/lang";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, {useCallback, useState} from "react";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import format from "date-fns/format";
import add from 'date-fns/add';
import DateFnsUtils from "@date-io/date-fns";
import {useHistory} from "react-router-dom";
import {Paths} from "../../../../router/paths";

type Props = {
    loading: boolean;
    onClose: () => void;
    onCreate: (name: string, expiresAt: Date | null) => Promise<string | undefined>;
};

const DATE_FORMAT = 'dd.MM.yyyy';
const MIN_DATE = new Date();
const MAX_DATE = add(new Date(), {years: 1});

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'relative',
    },
    copyButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(2),
    },
    pre: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all',
    },
}));

export const ApiTokensCreateDialog = ({loading, onClose, onCreate}: Props) => {
    const classes = useStyles();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [name, setName] = useState('');
    const handleNameChange = useCallback((e) => setName(e.target.value), []);
    const [expiresAt, setExpiresAt] = useState<Date | null>(null);
    const handleExpiresAt = useCallback((date) => setExpiresAt(isNaN(date) ? undefined : date), []);
    const renderDateLabel = useCallback((date: MaterialUiPickersDate) => {
        return date ? format(date, DATE_FORMAT) : '';
    }, []);
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const createdToken = await onCreate(name, expiresAt);
        setToken(createdToken);
    }, [name, expiresAt]);
    const handleCopyToken = useCallback(() => {
        return navigator.clipboard.writeText(token as string);
    }, [token]);
    const history = useHistory();
    const onGoToPlayground = useCallback(() => {
        history.push(Paths.SETTINGS_API_PLAYGROUND, {token});
    }, [history, token]);

    return (
        <Dialog open onClose={onClose}>
            {token ? (
                <>
                    <DialogTitle>{lang.api.settings.table.addDialog.titleCreated}</DialogTitle>
                    <DialogContent className={classes.content}>
                        <IconButton className={classes.copyButton} onClick={handleCopyToken}>
                            <FileCopyIcon />
                        </IconButton>
                        {lang.api.settings.table.addDialog.textCreated}
                        <br />
                        <pre className={classes.pre}>
                            {token}
                        </pre>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={onClose}>
                            {lang.api.settings.table.addDialog.closeButton}
                        </Button>
                        <Button color="primary" onClick={onGoToPlayground}>
                            {lang.api.settings.table.addDialog.goToPlayground}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{lang.api.settings.table.addDialog.title}</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label={lang.api.settings.table.addDialog.name}
                                    name="name"
                                    onChange={handleNameChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        format={DATE_FORMAT}
                                        fullWidth
                                        helperText={lang.api.settings.table.addDialog.expiresAtHint}
                                        inputVariant="outlined"
                                        labelFunc={renderDateLabel}
                                        minDate={MIN_DATE}
                                        maxDate={MAX_DATE}
                                        onChange={handleExpiresAt}
                                        placeholder="dd.mm.yyyy"
                                        variant="inline"
                                        value={expiresAt}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={onClose}
                        >
                            {lang.api.settings.table.addDialog.cancelButton}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={!name.trim() || loading}
                            type="submit"
                        >
                            {lang.api.settings.table.addDialog.createButton}
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};
