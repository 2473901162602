import React from 'react';
import {Pagination} from '../usePagination';
import {Paginator} from './Paginator';
import {FetchMorePaginator} from './FetchMorePaginator';
import {Table, TableProps} from './Table';

type Props<T> = TableProps<T> & {
    fetchedAll?: boolean;
    fetchMoreMode?: boolean;
    pagination: Pagination;
};

const ROWS_PER_PAGE_VARIANTS = [10, 25, 50, 100];

export function PaginatedTable<T>({fetchedAll = false, fetchMoreMode, pagination, ...tableProps}: Props<T>) {
    return (
        <>
            <Table {...tableProps} />
            {pagination && tableProps.items.length > 0 ? (
                <>
                    {fetchMoreMode ? (
                        <FetchMorePaginator
                            disabled={tableProps.loading}
                            fetchedAll={fetchedAll}
                            onFetchMore={pagination.onNextPage}
                        />
                    ) : (
                        <Paginator
                            onChangePage={pagination.onChangePage}
                            onChangePerPage={pagination.onChangePerPage}
                            page={pagination.page}
                            pages={pagination.pages}
                            perPage={pagination.perPage}
                            rowsPerPageVariants={ROWS_PER_PAGE_VARIANTS}
                            total={pagination.total || 0}
                        />
                    )}
                </>
            ) : null}
        </>
    );
};
