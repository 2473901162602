import {CircularProgress, TextField, TextFieldProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';

export type Props = Omit<TextFieldProps, 'variant'> & {
    loading?: boolean;
};

const useStyles = makeStyles((theme) => ({
    input: {
        paddingTop: 10,
    },
    notchedOutline: {
        borderColor: theme.palette.primary.light,
    },
    root: {
        borderRadius: theme.shape.borderRadius,
        paddingBottom: '0 !important',
        '&::before': {
            content: 'initial',
        },
        '&::after': {
            content: 'initial',
        },
    },
}));

export const TargetingTextField = ({loading = false, onChange, InputProps, ...props}: Props) => {
    const classes = useStyles();

    return (
        <TextField
            {...props}
            InputProps={{
                ...InputProps,
                classes: {
                    ...InputProps?.classes,
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                    root: clsx(classes.root, InputProps?.classes?.root),
                },
                endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {InputProps?.endAdornment}
                    </React.Fragment>
                ),
            }}
            onChange={onChange}
            variant="outlined"
        />
    );
};
