import React from 'react';
import {ListItemIcon, ListItemText, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterType} from './types';

type Props = {
    filter: TargetingFilterType;
};

const useStyles = makeStyles((theme) => ({
    listItemIcon: {
        minWidth: theme.spacing(3.5),
    },
    listItemText: {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

export const FilterDropdownMenuItem = ({filter}: Props) => {
    const classes = useStyles();

    return (
        <>
            {filter.icon ? (
                <ListItemIcon className={classes.listItemIcon}>
                    <SvgIcon fontSize="small">{filter.icon}</SvgIcon>
                </ListItemIcon>
            ) : null}
            <ListItemText classes={{primary: classes.listItemText}}>{filter.title}</ListItemText>
        </>
    );
};
