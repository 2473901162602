import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {lang} from '../../../../lang/lang';
import React from 'react';
import {LocaleDropdown} from '../../../../lang/components/LocaleDropdown';

export const CustomerLocale = () => {
    return (
        <>
            <Box my={3}>
                <Divider />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">{lang.customer.language}</Typography>
            </Box>
            <Grid container direction="column" spacing={4} md={6} xs={12}>
                <Grid item>
                    <LocaleDropdown
                        fullWidth
                        label={lang.customer.language}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </>
    )
};
