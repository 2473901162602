import React from 'react';
import {Box, CircularProgress, makeStyles, Typography} from '@material-ui/core';
import {TechnologyFeed} from '../../../graphql/graphql.types';
import {TechnologyFeedItems} from './TechnologyFeedItems';
import {lang} from '../../../lang/lang';

type Props = {
    feeds: TechnologyFeed[];
    loading: boolean;
};

const useStyles = makeStyles(() => ({
    loaderContainer: {
        position: 'absolute',
        top: '50%',
        bottom: 0,
        left: '50%',
        right: 0,
        transform: 'translate(-50%, -50%)',
        width: 40,
        height: 40,
    },
}));

export const TechnologyFeedContent = ({feeds, loading}: Props) => {
    const classes = useStyles();

    if (loading) {
        return (
            <div className={classes.loaderContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (feeds.length === 0) {
        return (
            <Box mt={9}>
                <Box mb={3}>
                    <Typography align="center" variant="h5">
                        {lang.feed.empty.title}
                    </Typography>
                </Box>
                <Typography align="center" variant="subtitle1">
                    {lang.feed.empty.description}
                </Typography>
            </Box>
        );
    }

    return <TechnologyFeedItems feeds={feeds} />;
};
