import {createContext} from 'react';

type AuthContextType = {
    loaded: boolean;
    onResetToken: () => void;
    onSetToken: (token: string) => void;
    token: string;
};

export const AuthContext = createContext<AuthContextType>({
    loaded: false,
    onResetToken: () => {},
    onSetToken: () => {},
    token: '',
});
