import {DocumentNode} from 'graphql';
import {ReactNode} from 'react';

export type TargetingFilterOperator = '=' | '!=' | '>' | '>=' | '<' | '<=' | 'ONE_OF' | 'ALL_OF' | 'BETWEEN' | 'EXCLUDE_ALL';

export enum TargetingFilterVariant {
    DATE_RANGE = 'DATE_RANGE',
    NUMBER = 'NUMBER',
    SUGGESTINON = 'SUGGESTION',
    GROUP = 'GROUP',
}

type CommonTargetingFilterFields = {
    name: string;
    placeholder?: string;
    icon?: ReactNode;
    title: string;
    operators: TargetingFilterOperator[];
    constantValues?: ConstantFilterValue[];
};

export type TitledValue<T> = {title: string, value: T};

export type RegularTargetingFilterValue = TitledValue<string>;
export type DateTargetingFilterValue = TitledValue<[string | null, string | null]>;
export type SuggestingTargetingFilterValue = TitledValue<string>[];
export type SingleTargetingFilterValue = RegularTargetingFilterValue | DateTargetingFilterValue | SuggestingTargetingFilterValue;
export type ConstantFilterValue = {
    name: string;
    operator: TargetingFilterOperator;
    value: RegularTargetingFilterValue;
};

export type GroupTargetingFilterValue = SingleTargetingFilterValue[];

export type TargetingFilterValue = SingleTargetingFilterValue | GroupTargetingFilterValue;

export type RegularTargetingFilter = CommonTargetingFilterFields & {
    defaultValue: RegularTargetingFilterValue;
    type: TargetingFilterVariant.NUMBER;
};

export type DateTargetingFilter = CommonTargetingFilterFields & {
    defaultValue: DateTargetingFilterValue;
    type: TargetingFilterVariant.DATE_RANGE;
};

export type SuggestingTargetingFilter = Omit<CommonTargetingFilterFields, 'defaultValue'> & {
    type: TargetingFilterVariant.SUGGESTINON;
    defaultValue: SuggestingTargetingFilterValue;
    suggestions: {
        document: DocumentNode;
        responseToSuggestions: (result: any) => TargetingFilterSuggestion[];
        getFilterVariables?: (name: string) => {[key: string]: any};
        getPageVariables?: (page: number, perPage?: number) => {[key: string]: any};
    };
};

export type SingleTargetingFilter = RegularTargetingFilter | DateTargetingFilter | SuggestingTargetingFilter;

export type TargetingFilterGroup = Omit<CommonTargetingFilterFields, 'operators'> & {
    type: TargetingFilterVariant.GROUP;
    filters: SingleTargetingFilter[];
};

export type TargetingFilterType = SingleTargetingFilter | TargetingFilterGroup;

export type TargetingFilterSuggestion = {
    image?: string;
    title: string;
    subtitle?: string;
    value: string;
    [key: string]: unknown;
};

export type SingleTargetingFilterState = {
    operator: TargetingFilterOperator;
} & (
    {
        filter: RegularTargetingFilter;
        value: RegularTargetingFilterValue;
    } | {
        filter: DateTargetingFilter;
        value: DateTargetingFilterValue;
    } | {
        filter: SuggestingTargetingFilter;
        value: SuggestingTargetingFilterValue;
    }
);

export type GroupTargetingFilterState = {
    filter: TargetingFilterGroup;
    state: SingleTargetingFilterState[];
};

export type TargetingFilterState = SingleTargetingFilterState | GroupTargetingFilterState;
