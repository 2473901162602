import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, styled} from '@material-ui/core';
import React, {ReactNode, useCallback} from 'react';

const WideFormControl = styled(FormControl)(({theme}) => ({
    marginRight: theme.spacing(1),
    minWidth: 200,
}));

type Props = {
    label: ReactNode;
    onChange: (values: string[]) => void;
    options: {id: string, name: string}[];
    value: string[];
};

export const MultipleSelect = ({label, onChange, options, value}: Props) => {
    const handleCategoryIdsFilterChange = useCallback(({target: {value}}: React.ChangeEvent<{ value: unknown }>) => {
        onChange(value as string[]);
    }, [onChange]);

    const handleRenderValue = useCallback((selected: unknown) => {
        return (selected as string[])
            .map((value) => options?.find(({id}) => id === value)?.name)
            .join(', ');
    }, [options]);

    return (
        <WideFormControl size='small' variant='outlined'>
            <InputLabel id='technologies-filter-label'>{label}</InputLabel>
            <Select
                labelId='technologies-filter-label'
                label={label}
                multiple
                onChange={handleCategoryIdsFilterChange}
                renderValue={handleRenderValue}
                value={value}
            >
                {options.map(({id, name}) => (
                    <MenuItem key={String(id)} value={String(id)}>
                        <Checkbox checked={value.includes(id)} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </WideFormControl>
    );
};
