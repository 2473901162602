export enum Paths {
    DASHBOARD = '/dashboard',
    DASHBOARD_SETUP = '/dashboard/setup',
    DOMAINS = '/domains',
    DOMAIN_HISTORY = '/domains/:domain/history',
    DOMAIN_TECHNOLOGIES = '/domains/:domain/technologies',
    ERROR_NOT_ACTIVATED = '/error/notActivated',
    ROOT = '/targeting',
    RESET_PASSWORD_REQUEST = '/auth/resetPasswordRequest',
    PROFILE_CREATE = '/profile/create',
    SETTINGS = '/settings',
    SETTINGS_API = '/settings/api',
    SETTINGS_API_TOKENS = '/settings/api/tokens',
    SETTINGS_API_PLAYGROUND = '/settings/api/playground',
    SETTINGS_PROFILE = '/settings/profile',
    SETTINGS_TECHNOLOGY_SUBSCRIPTIONS = '/settings/technologySubscriptions',
    SIGN_IN = '/auth/signIn',
    SIGN_UP = '/auth/signUp',
    TARGETING = '/targeting',
    TECHNOLOGIES = '/technologies',
    TECHNOLOGY_DOMAINS = '/technologies/:technologyId/domains',
    TECHNOLOGY_HISTORY = '/technologies/:technologyId/history',
    USERS = '/admin/users',
    USERS_CREATE = '/admin/users/create',
};
