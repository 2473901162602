import {Box, Divider, Grid, Typography} from '@material-ui/core';
import React, {useMemo} from 'react';
import {lang} from '../../../../lang/lang';
import {TextInputField} from '../../../../components/formik/TextInputField';
import {Option, SelectInputField} from '../../../../components/formik/SelectInputField';
import {PhoneInputField} from '../../../../components/formik/PhoneInputField';
import {UserRole} from '../../../../graphql/graphql.types';

export const CreateUserProfileFormFields = () => {
    const userRoles: Option[] = useMemo(() => [
        {label: lang.admin.users.create.profile.roleClient, value: UserRole.CLIENT},
        {label: lang.admin.users.create.profile.roleSales, value: UserRole.SALES},
        {label: lang.admin.users.create.profile.roleAdmin, value: UserRole.ADMIN},
    ], []);

    return (
        <>
            <Box mb={3}>
                <Grid container xs={12} justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            {lang.admin.users.create.profile.generalInformation}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {lang.admin.users.create.profile.mandatoryFields}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={8} md={10} lg={8}>
                <Grid item md={6}>
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            <TextInputField
                                autoFocus
                                fullWidth
                                label={lang.admin.users.create.profile.firstName}
                                name="firstName"
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextInputField
                                fullWidth
                                label={lang.admin.users.create.profile.lastName}
                                name="lastName"
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextInputField
                                fullWidth
                                label={lang.admin.users.create.profile.businessRole}
                                name="businessRole"
                            />
                        </Grid>
                        <Grid item>
                            <TextInputField
                                fullWidth
                                label={lang.admin.users.create.profile.companyName}
                                name="companyName"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <SelectInputField
                        fullWidth
                        label={lang.admin.users.create.profile.userRole}
                        name="userRole"
                        options={userRoles}
                        required
                    />
                </Grid>
            </Grid>
            <Box mt={3} mb={3}>
                <Divider />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">
                    {lang.admin.users.create.profile.contactInformation}
                </Typography>
            </Box>
            <Grid container md={10} lg={8} spacing={8}>
                <Grid item md={6}>
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            <TextInputField
                                fullWidth
                                label={lang.admin.users.create.profile.email}
                                name="email"
                                required
                            />
                        </Grid>
                        <Grid item>
                            <PhoneInputField
                                fullWidth
                                label={lang.admin.users.create.profile.companyPhone}
                                name="companyPhone"
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
