import {RouteConfig} from '../../router/RouteConfig';
import {lazy} from '../../shared/lazy';
import {defaultExport} from '../../shared/defaultExport';
import {lang} from '../../lang/lang';
import {UserRole} from "../../graphql/graphql.types";

export const settingsRoutes = (): RouteConfig => ({
    path: 'settings',
    component: lazy(() => import('./SettingsPage').then(defaultExport('SettingsPage'))),
    title: lang.settings.title,
    routes: [
        {
            path: 'api',
            component: lazy(() => import('./api/ApiPage').then(defaultExport('ApiPage'))),
            title: lang.settings.api.title,
            roles: [UserRole.API],
            routes: [
                {
                    path: 'tokens',
                    component: lazy(() => import('./api/apiTokens/ApiTokensPage').then(defaultExport('ApiTokensPage'))),
                    title: lang.settings.api.tokensTitle,
                },
                {
                    path: 'playground',
                    component: lazy(() => import('./api/playground/ApiPlaygroundPage').then(defaultExport('ApiPlaygroundPage'))),
                    title: lang.settings.api.playgroundTitle,
                },
            ],
        },
        {
            path: 'profile',
            component: lazy(() => import('./profile/CustomerProfilePage').then(defaultExport('CustomerProfilePage'))),
            title: lang.settings.profileDetails.title,
        },
        {
            path: 'technologySubscriptions',
            component: lazy(() => import('./technologySubscriptions/TechnologySubscriptionsPage').then(defaultExport('TechnologySubscriptionsPage'))),
            title: lang.settings.myTechnologies.title,
        },
    ],
});
