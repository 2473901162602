import React, {useCallback, useState} from 'react';
import {EmailForm} from '../components/EmailForm';
import {useConfirmEmailMutation} from '../../../graphql/graphql.types';
import {AuthMessage} from '../../../auth/AuthMessage';
import {lang} from '../../../lang/lang';

export const ConfirmEmailPage = () => {
    const [confirmEmailCompleted, setConfirmEmailCompleted] = useState(false);
    const [confirmEmail] = useConfirmEmailMutation();
    const handleSubmit = useCallback(async ({email}: {email: string}) => {
        await confirmEmail({variables: {email}});

        setConfirmEmailCompleted(true);
    }, [confirmEmail, setConfirmEmailCompleted]);

    if (confirmEmailCompleted) {
        return (
            <AuthMessage title={lang.auth.confirmEmail.checkYourInbox}>
                {lang.auth.confirmEmail.checkYourEmail}
            </AuthMessage>
        );
    }

    return (
        <EmailForm
            buttonText={lang.auth.confirmEmail.confirmButton}
            onSubmit={handleSubmit}
            title={lang.auth.confirmEmail.formTitle}
        />
    );
};
