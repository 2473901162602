export default {
    "drawer": {
        "title": "フィード"
    },
    "empty": {
        "title": "お知らせ事項はありません",
        "description": "登録したテクノロジーを追加、または、削除した企業がある時、こちらのお知らせに表示されます"
    },
    "list": {
        "thisWeek": "今週",
        "oneWeekAgo": "一週間前",
        "severalWeeksAgo": "{count} 週間前"
    }
};
