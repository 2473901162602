import React, {ReactNode} from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import {Link} from '../navigation/Link';
import {Paths} from '../../router/paths';

type Props = {
    backTo?: Paths;
    children: ReactNode;
};

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
}));

export const PageTitle = ({backTo, children}: Props) => {
    const classes = useStyles();

    return (
        <Typography className={classes.title} variant="h2">
            {backTo && (
                <Link to={backTo}>
                    <ArrowLeftIcon fontSize="large" />
                </Link>
            )}
            {children}
        </Typography>
    )
};
