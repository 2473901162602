import React, {useMemo} from 'react';
import format from 'date-fns/format';
import {Table} from '../../../../components/table/Table';
import {ApiToken} from '../../../../graphql/graphql.types';
import {TableConfig} from "../../../../components/table/types";
import {lang} from "../../../../lang/lang";
import {ApiTokenRemoveButton} from "./ApiTokenRemoveButton";
import {ApiTokensCreateButton} from "./ApiTokensCreateButton";

const calculateKey = (apiToken: ApiToken) => apiToken.uuid;

type Props = {
    apiTokens: ApiToken[];
    loading: boolean;
    onCreate: (name: string, expiresAt: Date | null) => Promise<string | undefined>;
    onRemove: (tokenUuid: string) => Promise<void>;
};

export const ApiTokensTable = ({apiTokens, loading, onCreate, onRemove}: Props) => {
    const headerConfig: TableConfig<ApiToken>[] = useMemo(() => [
        {
            name: 'name',
            title: lang.api.settings.table.name,
        },
        {
            name: 'createdAt',
            title: lang.api.settings.table.createdAt,
            render: ({item}) => (
                <>{format(item.createdAt, 'do MMMM yyyy')}</>
            ),
        },
        {
            name: 'expiresAt',
            title: lang.api.settings.table.expiresAt,
            render: ({item}) => (
                <>
                    {item.expiresAt ? format(item.expiresAt, 'do MMMM yyyy') : lang.api.settings.table.never}
                </>
            ),
        },
        {
            name: 'actions',
            title: lang.api.settings.table.actions,
            render: ({item}) => (
                <ApiTokenRemoveButton
                    disabled={loading}
                    onRemove={onRemove}
                    token={item}
                />
            )
        }
    ], [loading, onRemove]);

    return (
        <>
            <ApiTokensCreateButton
                loading={loading}
                onCreate={onCreate}
            />
            <Table<ApiToken>
                calculateKey={calculateKey}
                configs={headerConfig}
                items={apiTokens}
                loading={loading}
            />
        </>
    );
};
