export default {
    title: 'Targeting',
    filter: {
        add: 'Add Targeting filter',
        apply: 'Apply filters',
        clear: 'Clear filters',
        dropdown: {
            loading: 'Loading...',
            nothingFound: 'Nothing found',
            startTyping: 'Start typing...',
        },
        placeholder: 'Enter {field}...',
        searchingFor: 'Searching for: ',
        remove: 'Remove',
        operator: {
            betweenTwoValues: '{first} and {second}',
            equals: 'equals to',
            notEquals: 'not equals to',
            greaterThen: 'greater then',
            greaterThenOrEquals: 'greater then or equals to',
            lesserThen: 'lesser then',
            lesserThenOrEquals: 'lesser then or equals to',
            oneOf: 'one of',
            allOf: 'all of',
            between: 'between',
            excludeAll: 'exclude all',
        },
        operatorDropdown: {
            equals: 'equals',
            notEquals: 'not equals',
            greaterThen: 'greater than',
            greaterThenOrEquals: 'greater or equals',
            lesserThen: 'lesser than',
            lesserThenOrEquals: 'lesser or equals',
            oneOf: 'includes any',
            allOf: 'includes all',
            excludeAll: 'exclude all',
            between: 'between',
        },
    },
    notification: {
        exportFailed: 'Targeting export failed',
    },
    export: {
        button: 'Export CSV',
    },
    table: {
        domain: 'Domain',
        technologyCount: 'Technology Count',
        resultsFound: ' {count} results'
    },
    availableFilters: {
        technology: 'Technology',
        technologyPlaceholder: 'E.g. Google Analytics',
        technologyAdded: 'Technology added',
        technologyDropped: 'Technology dropped',
        foundAt: 'Found',
        add: 'Add',
        drop: 'Drop',
        category: 'Category',
        categoryPlaceholder: 'E.g. Marketing Automation',
    },
};
