export default {
    "drawer": {
        "targeting": "ターゲティング",
        "domains": "ドメイン",
        "technologies": "テクノロジー"
    },
    "errorPageTitle": "エラー",
    "homePageTitle": "ホーム",
    "loading": "ロード中です"
};
