import React, {PropsWithChildren, useContext, useMemo} from 'react';
import {useCustomerQuery} from '../../graphql/graphql.types';
import {CustomerContext} from './CustomerContext';
import {AuthContext} from '../../auth/AuthContext';

export const CustomerProvider = ({children}: PropsWithChildren<{}>) => {
    const {token} = useContext(AuthContext);
    const {data, loading, refetch} = useCustomerQuery({
        skip: !token,
    });
    const contextValue = useMemo(() => ({
        customer: data?.customer || null,
        loading,
        reload: refetch,
    }), [data, loading, refetch]);

    return (
        <CustomerContext.Provider value={contextValue}>
            {children}
        </CustomerContext.Provider>
    );
};
