import {lang} from '../../../lang/lang';

// TODO switch to enum
export const formatStatus = (status: any) => {
    switch (status) {
        case 'ACTIVE':
            return lang.admin.users.list.table.statusActive;
        case 'PENDING':
            return lang.admin.users.list.table.statusPending;
        case 'BLOCKED':
            return lang.admin.users.list.table.statusBlocked;
        default:
            return `${status[0].toUpperCase}${status.substrig(1)}`;
    }
};
