import * as yup from 'yup';
import {lang} from '../../../lang/lang';

export const createPasswordValidationSchema = () => yup.object().shape({
    password: yup.string()
        .label(lang.auth.password.label)
        .min(6)
        .max(128)
        .required(),
    repeatPassword: yup.string()
        .label(lang.auth.password.repeat)
        .required()
        .test('equalPassword', lang.auth.password.validation.equal, function (value) {
            return value === this.parent.password;
        }),
});
