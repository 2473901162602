export default {
    "title": "ターゲティング",
    "filter": {
        "add": "ターゲティングフィルターの追加",
        "apply": "フィルターの適用",
        "clear": "フィルターの解除",
        "dropdown": {
            "loading": "ロード中です",
            "nothingFound": "該当がありませんでした",
            "startTyping": "入力の開始"
        },
        "placeholder": "{field}を入力",
        "searchingFor": "検索…",
        "remove": "解除",
        "operator": {
            "betweenTwoValues": "{first} 及び {second}",
            "equals": "一致",
            "notEquals": "不一致",
            "greaterThen": "以上",
            "greaterThenOrEquals": "以上または一致",
            "lesserThen": "以下",
            "lesserThenOrEquals": "以下または一致",
            "oneOf": "いずれか",
            "allOf": "全て",
            "between": "この間",
            "excludeAll": "全てを除外"
        },
        "operatorDropdown": {
            "equals": "一致",
            "notEquals": "不一致",
            "greaterThen": "以上",
            "greaterThenOrEquals": "一致または以上",
            "lesserThen": "以下",
            "lesserThenOrEquals": "一致または以下",
            "oneOf": "いずれかを含む",
            "allOf": "全てを含む",
            "excludeAll": "全てを除外する",
            "between": "合間"
        }
    },
    "notification": {
        "exportFailed": "エクスポートを失敗しました"
    },
    "export": {
        "button": "csvでエクスポート"
    },
    "table": {
        "domain": "ドメイン",
        "technologyCount": "テクノロジー数",
        "resultsFound": "結果数 {count}"
    },
    "availableFilters": {
        "technology": "テクノロジー",
        "technologyPlaceholder": "例：Google Analytics",
        "technologyAdded": "追加されたテクノロジー",
        "technologyDropped": "削除されたテクノロジー",
        "foundAt": "見つかりました",
        "add": "追加",
        "drop": "削除",
        "category": "カテゴリー",
        "categoryPlaceholder": "例　マーケティングオートメーション"
    }
};
