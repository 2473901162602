import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import {Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {TargetingFilterSuggestion} from '../types';

type Props = {
    inputValue: string;
    option: TargetingFilterSuggestion;
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
    },
    name: {
        flex: 1,
    },
    highlight: {
        fontWeight: 700,
    },
});

export const SuggestionFilterOption = ({inputValue, option: {subtitle, title}}: Props) => {
    const classes = useStyles();
    const matches = match(title, inputValue);
    const parts = parse(title, matches);

    return (
        <div className={classes.container}>
            <span className={classes.name}>
                {parts.map((part, index) => (
                    <span
                        key={index}
                        className={clsx({[classes.highlight]: part.highlight})}
                    >
                    {part.text}
                </span>
                ))}
            </span>
            {subtitle ? <Chip label={<>{subtitle}</>} size="small" /> : null}
        </div>
    );
};
